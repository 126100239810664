import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 武器装备数据: 装备属性信息模型[] = [
  {
    id: 40321,
    uid: 40321,
    装备名称: '伏龙阳焰',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 22000,
    武器伤害_最小值: 4739,
    武器伤害_最大值: 7898,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 20130 },
      { 属性: 属性类型.元气, 值: 1642 },
      { 属性: 属性类型.内功基础攻击, 值: 9133 },
      { 属性: 属性类型.内功会心等级, 值: 10065 },
      { 属性: 属性类型.内功破防等级, 值: 10065 },
      { 属性: 属性类型.加速等级, 值: 4575 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40317,
    uid: 40317,
    装备名称: '烬灭',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 22000,
    武器伤害_最小值: 4739,
    武器伤害_最大值: 7898,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 20130 },
      { 属性: 属性类型.根骨, 值: 1642 },
      { 属性: 属性类型.内功基础攻击, 值: 8995 },
      { 属性: 属性类型.内功会心等级, 值: 9608 },
      { 属性: 属性类型.内功破防等级, 值: 10980 },
      { 属性: 属性类型.加速等级, 值: 4575 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40316,
    uid: 40316,
    装备名称: '子不语',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 22000,
    武器伤害_最小值: 4739,
    武器伤害_最大值: 7898,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 20130 },
      { 属性: 属性类型.元气, 值: 1642 },
      { 属性: 属性类型.内功基础攻击, 值: 9133 },
      { 属性: 属性类型.内功会心等级, 值: 9150 },
      { 属性: 属性类型.内功破防等级, 值: 10065 },
      { 属性: 属性类型.加速等级, 值: 5490 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40311,
    uid: 40311,
    装备名称: '栖贤韵',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 22000,
    武器伤害_最小值: 2369,
    武器伤害_最大值: 3949,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 20130 },
      { 属性: 属性类型.根骨, 值: 1642 },
      { 属性: 属性类型.内功基础攻击, 值: 8857 },
      { 属性: 属性类型.内功会心等级, 值: 10065 },
      { 属性: 属性类型.内功破防等级, 值: 10523 },
      { 属性: 属性类型.加速等级, 值: 5033 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40307,
    uid: 40307,
    装备名称: '瀚海引',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 22000,
    武器伤害_最小值: 4739,
    武器伤害_最大值: 7898,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 20130 },
      { 属性: 属性类型.元气, 值: 1642 },
      { 属性: 属性类型.内功基础攻击, 值: 8857 },
      { 属性: 属性类型.内功会心等级, 值: 9608 },
      { 属性: 属性类型.内功破防等级, 值: 10980 },
      { 属性: 属性类型.加速等级, 值: 5033 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40302,
    uid: 40302,
    装备名称: '碧岚幽炎',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22000,
    武器伤害_最小值: 4739,
    武器伤害_最大值: 7898,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 20130 },
      { 属性: 属性类型.元气, 值: 1642 },
      { 属性: 属性类型.内功基础攻击, 值: 8857 },
      { 属性: 属性类型.外功会心等级, 值: 9608 },
      { 属性: 属性类型.内功破防等级, 值: 11438 },
      { 属性: 属性类型.加速等级, 值: 4575 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40300,
    uid: 40300,
    装备名称: '蜕骨',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 22000,
    武器伤害_最小值: 2369,
    武器伤害_最大值: 3949,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 20130 },
      { 属性: 属性类型.根骨, 值: 1642 },
      { 属性: 属性类型.内功基础攻击, 值: 8995 },
      { 属性: 属性类型.内功会心等级, 值: 9608 },
      { 属性: 属性类型.内功破防等级, 值: 10980 },
      { 属性: 属性类型.加速等级, 值: 4575 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40298,
    uid: 40298,
    装备名称: '龙鲤',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 22000,
    武器伤害_最小值: 3080,
    武器伤害_最大值: 5133,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 20130 },
      { 属性: 属性类型.根骨, 值: 1642 },
      { 属性: 属性类型.内功基础攻击, 值: 9133 },
      { 属性: 属性类型.内功会心等级, 值: 9608 },
      { 属性: 属性类型.内功破防等级, 值: 10065 },
      { 属性: 属性类型.加速等级, 值: 5033 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40296,
    uid: 40296,
    装备名称: '仙灵',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22000,
    武器伤害_最小值: 3080,
    武器伤害_最大值: 5133,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 20130 },
      { 属性: 属性类型.根骨, 值: 1642 },
      { 属性: 属性类型.内功基础攻击, 值: 8857 },
      { 属性: 属性类型.内功会心等级, 值: 11438 },
      { 属性: 属性类型.内功破防等级, 值: 9608 },
      { 属性: 属性类型.加速等级, 值: 4575 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40292,
    uid: 40292,
    装备名称: '意真',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 22000,
    武器伤害_最小值: 2369,
    武器伤害_最大值: 3949,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 20130 },
      { 属性: 属性类型.元气, 值: 1642 },
      { 属性: 属性类型.内功基础攻击, 值: 9133 },
      { 属性: 属性类型.内功会心等级, 值: 9608 },
      { 属性: 属性类型.内功破防等级, 值: 10523 },
      { 属性: 属性类型.加速等级, 值: 4575 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40290,
    uid: 40290,
    装备名称: '昭佛光',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 22000,
    武器伤害_最小值: 8056,
    武器伤害_最大值: 13426,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 20130 },
      { 属性: 属性类型.元气, 值: 1642 },
      { 属性: 属性类型.内功基础攻击, 值: 9410 },
      { 属性: 属性类型.内功会心等级, 值: 9150 },
      { 属性: 属性类型.内功破防等级, 值: 10065 },
      { 属性: 属性类型.加速等级, 值: 4575 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40289,
    uid: 40289,
    装备名称: '伏龙阳焰',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 20750,
    武器伤害_最小值: 4469,
    武器伤害_最大值: 7449,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 18986 },
      { 属性: 属性类型.元气, 值: 1549 },
      { 属性: 属性类型.内功基础攻击, 值: 8614 },
      { 属性: 属性类型.内功会心等级, 值: 9493 },
      { 属性: 属性类型.内功破防等级, 值: 9493 },
      { 属性: 属性类型.加速等级, 值: 4315 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40285,
    uid: 40285,
    装备名称: '烬灭',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 20750,
    武器伤害_最小值: 4469,
    武器伤害_最大值: 7449,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 18986 },
      { 属性: 属性类型.根骨, 值: 1549 },
      { 属性: 属性类型.内功基础攻击, 值: 8484 },
      { 属性: 属性类型.内功会心等级, 值: 9062 },
      { 属性: 属性类型.内功破防等级, 值: 10356 },
      { 属性: 属性类型.加速等级, 值: 4315 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40284,
    uid: 40284,
    装备名称: '子不语',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 20750,
    武器伤害_最小值: 4469,
    武器伤害_最大值: 7449,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 18986 },
      { 属性: 属性类型.元气, 值: 1549 },
      { 属性: 属性类型.内功基础攻击, 值: 8614 },
      { 属性: 属性类型.内功会心等级, 值: 8630 },
      { 属性: 属性类型.内功破防等级, 值: 9493 },
      { 属性: 属性类型.加速等级, 值: 5178 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40279,
    uid: 40279,
    装备名称: '栖贤韵',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 20750,
    武器伤害_最小值: 2235,
    武器伤害_最大值: 3725,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 18986 },
      { 属性: 属性类型.根骨, 值: 1549 },
      { 属性: 属性类型.内功基础攻击, 值: 8353 },
      { 属性: 属性类型.内功会心等级, 值: 9493 },
      { 属性: 属性类型.内功破防等级, 值: 9925 },
      { 属性: 属性类型.加速等级, 值: 4747 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40275,
    uid: 40275,
    装备名称: '瀚海引',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 20750,
    武器伤害_最小值: 4469,
    武器伤害_最大值: 7449,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 18986 },
      { 属性: 属性类型.元气, 值: 1549 },
      { 属性: 属性类型.内功基础攻击, 值: 8353 },
      { 属性: 属性类型.内功会心等级, 值: 9062 },
      { 属性: 属性类型.内功破防等级, 值: 10356 },
      { 属性: 属性类型.加速等级, 值: 4747 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40270,
    uid: 40270,
    装备名称: '碧岚幽炎',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 20750,
    武器伤害_最小值: 4469,
    武器伤害_最大值: 7449,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 18986 },
      { 属性: 属性类型.元气, 值: 1549 },
      { 属性: 属性类型.内功基础攻击, 值: 8353 },
      { 属性: 属性类型.外功会心等级, 值: 9062 },
      { 属性: 属性类型.内功破防等级, 值: 10788 },
      { 属性: 属性类型.加速等级, 值: 4315 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40268,
    uid: 40268,
    装备名称: '蜕骨',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 20750,
    武器伤害_最小值: 2235,
    武器伤害_最大值: 3725,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 18986 },
      { 属性: 属性类型.根骨, 值: 1549 },
      { 属性: 属性类型.内功基础攻击, 值: 8484 },
      { 属性: 属性类型.内功会心等级, 值: 9062 },
      { 属性: 属性类型.内功破防等级, 值: 10356 },
      { 属性: 属性类型.加速等级, 值: 4315 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40266,
    uid: 40266,
    装备名称: '龙鲤',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 20750,
    武器伤害_最小值: 2905,
    武器伤害_最大值: 4842,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 18986 },
      { 属性: 属性类型.根骨, 值: 1549 },
      { 属性: 属性类型.内功基础攻击, 值: 8614 },
      { 属性: 属性类型.内功会心等级, 值: 9062 },
      { 属性: 属性类型.内功破防等级, 值: 9493 },
      { 属性: 属性类型.加速等级, 值: 4747 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40264,
    uid: 40264,
    装备名称: '仙灵',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 20750,
    武器伤害_最小值: 2905,
    武器伤害_最大值: 4842,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 18986 },
      { 属性: 属性类型.根骨, 值: 1549 },
      { 属性: 属性类型.内功基础攻击, 值: 8353 },
      { 属性: 属性类型.内功会心等级, 值: 10788 },
      { 属性: 属性类型.内功破防等级, 值: 9062 },
      { 属性: 属性类型.加速等级, 值: 4315 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40260,
    uid: 40260,
    装备名称: '意真',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 20750,
    武器伤害_最小值: 2235,
    武器伤害_最大值: 3725,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 18986 },
      { 属性: 属性类型.元气, 值: 1549 },
      { 属性: 属性类型.内功基础攻击, 值: 8614 },
      { 属性: 属性类型.内功会心等级, 值: 9062 },
      { 属性: 属性类型.内功破防等级, 值: 9925 },
      { 属性: 属性类型.加速等级, 值: 4315 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40258,
    uid: 40258,
    装备名称: '昭佛光',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 20750,
    武器伤害_最小值: 7598,
    武器伤害_最大值: 12663,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 18986 },
      { 属性: 属性类型.元气, 值: 1549 },
      { 属性: 属性类型.内功基础攻击, 值: 8875 },
      { 属性: 属性类型.内功会心等级, 值: 8630 },
      { 属性: 属性类型.内功破防等级, 值: 9493 },
      { 属性: 属性类型.加速等级, 值: 4315 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40257,
    uid: 40257,
    装备名称: '伏龙阳焰',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 19500,
    武器伤害_最小值: 4200,
    武器伤害_最大值: 7000,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17843 },
      { 属性: 属性类型.元气, 值: 1456 },
      { 属性: 属性类型.内功基础攻击, 值: 8095 },
      { 属性: 属性类型.内功会心等级, 值: 8921 },
      { 属性: 属性类型.内功破防等级, 值: 8921 },
      { 属性: 属性类型.加速等级, 值: 4055 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40253,
    uid: 40253,
    装备名称: '烬灭',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 19500,
    武器伤害_最小值: 4200,
    武器伤害_最大值: 7000,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17843 },
      { 属性: 属性类型.根骨, 值: 1456 },
      { 属性: 属性类型.内功基础攻击, 值: 7973 },
      { 属性: 属性类型.内功会心等级, 值: 8516 },
      { 属性: 属性类型.内功破防等级, 值: 9732 },
      { 属性: 属性类型.加速等级, 值: 4055 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40252,
    uid: 40252,
    装备名称: '子不语',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 19500,
    武器伤害_最小值: 4200,
    武器伤害_最大值: 7000,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17843 },
      { 属性: 属性类型.元气, 值: 1456 },
      { 属性: 属性类型.内功基础攻击, 值: 8095 },
      { 属性: 属性类型.内功会心等级, 值: 8110 },
      { 属性: 属性类型.内功破防等级, 值: 8921 },
      { 属性: 属性类型.加速等级, 值: 4866 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40247,
    uid: 40247,
    装备名称: '栖贤韵',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 19500,
    武器伤害_最小值: 2100,
    武器伤害_最大值: 3500,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17843 },
      { 属性: 属性类型.根骨, 值: 1456 },
      { 属性: 属性类型.内功基础攻击, 值: 7850 },
      { 属性: 属性类型.内功会心等级, 值: 8921 },
      { 属性: 属性类型.内功破防等级, 值: 9327 },
      { 属性: 属性类型.加速等级, 值: 4461 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40243,
    uid: 40243,
    装备名称: '瀚海引',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 19500,
    武器伤害_最小值: 4200,
    武器伤害_最大值: 7000,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17843 },
      { 属性: 属性类型.元气, 值: 1456 },
      { 属性: 属性类型.内功基础攻击, 值: 7850 },
      { 属性: 属性类型.内功会心等级, 值: 8516 },
      { 属性: 属性类型.内功破防等级, 值: 9732 },
      { 属性: 属性类型.加速等级, 值: 4461 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40238,
    uid: 40238,
    装备名称: '碧岚幽炎',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 19500,
    武器伤害_最小值: 4200,
    武器伤害_最大值: 7000,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17843 },
      { 属性: 属性类型.元气, 值: 1456 },
      { 属性: 属性类型.内功基础攻击, 值: 7850 },
      { 属性: 属性类型.外功会心等级, 值: 8516 },
      { 属性: 属性类型.内功破防等级, 值: 10138 },
      { 属性: 属性类型.加速等级, 值: 4055 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40236,
    uid: 40236,
    装备名称: '蜕骨',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 19500,
    武器伤害_最小值: 2100,
    武器伤害_最大值: 3500,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17843 },
      { 属性: 属性类型.根骨, 值: 1456 },
      { 属性: 属性类型.内功基础攻击, 值: 7973 },
      { 属性: 属性类型.内功会心等级, 值: 8516 },
      { 属性: 属性类型.内功破防等级, 值: 9732 },
      { 属性: 属性类型.加速等级, 值: 4055 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40234,
    uid: 40234,
    装备名称: '龙鲤',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 19500,
    武器伤害_最小值: 2730,
    武器伤害_最大值: 4550,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17843 },
      { 属性: 属性类型.根骨, 值: 1456 },
      { 属性: 属性类型.内功基础攻击, 值: 8095 },
      { 属性: 属性类型.内功会心等级, 值: 8516 },
      { 属性: 属性类型.内功破防等级, 值: 8921 },
      { 属性: 属性类型.加速等级, 值: 4461 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40232,
    uid: 40232,
    装备名称: '仙灵',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 19500,
    武器伤害_最小值: 2730,
    武器伤害_最大值: 4550,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17843 },
      { 属性: 属性类型.根骨, 值: 1456 },
      { 属性: 属性类型.内功基础攻击, 值: 7850 },
      { 属性: 属性类型.内功会心等级, 值: 10138 },
      { 属性: 属性类型.内功破防等级, 值: 8516 },
      { 属性: 属性类型.加速等级, 值: 4055 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40228,
    uid: 40228,
    装备名称: '意真',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 19500,
    武器伤害_最小值: 2100,
    武器伤害_最大值: 3500,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17843 },
      { 属性: 属性类型.元气, 值: 1456 },
      { 属性: 属性类型.内功基础攻击, 值: 8095 },
      { 属性: 属性类型.内功会心等级, 值: 8516 },
      { 属性: 属性类型.内功破防等级, 值: 9327 },
      { 属性: 属性类型.加速等级, 值: 4055 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40226,
    uid: 40226,
    装备名称: '昭佛光',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 19500,
    武器伤害_最小值: 7140,
    武器伤害_最大值: 11900,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17843 },
      { 属性: 属性类型.元气, 值: 1456 },
      { 属性: 属性类型.内功基础攻击, 值: 8340 },
      { 属性: 属性类型.内功会心等级, 值: 8110 },
      { 属性: 属性类型.内功破防等级, 值: 8921 },
      { 属性: 属性类型.加速等级, 值: 4055 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40225,
    uid: 40225,
    装备名称: '伏龙阳焰',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 18300,
    武器伤害_最小值: 3942,
    武器伤害_最大值: 6570,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16744 },
      { 属性: 属性类型.元气, 值: 1366 },
      { 属性: 属性类型.内功基础攻击, 值: 7597 },
      { 属性: 属性类型.内功会心等级, 值: 8372 },
      { 属性: 属性类型.内功破防等级, 值: 8372 },
      { 属性: 属性类型.加速等级, 值: 3806 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40221,
    uid: 40221,
    装备名称: '烬灭',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 18300,
    武器伤害_最小值: 3942,
    武器伤害_最大值: 6570,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16744 },
      { 属性: 属性类型.根骨, 值: 1366 },
      { 属性: 属性类型.内功基础攻击, 值: 7482 },
      { 属性: 属性类型.内功会心等级, 值: 7992 },
      { 属性: 属性类型.内功破防等级, 值: 9133 },
      { 属性: 属性类型.加速等级, 值: 3806 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40220,
    uid: 40220,
    装备名称: '子不语',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 18300,
    武器伤害_最小值: 3942,
    武器伤害_最大值: 6570,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16744 },
      { 属性: 属性类型.元气, 值: 1366 },
      { 属性: 属性类型.内功基础攻击, 值: 7597 },
      { 属性: 属性类型.内功会心等级, 值: 7611 },
      { 属性: 属性类型.内功破防等级, 值: 8372 },
      { 属性: 属性类型.加速等级, 值: 4567 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40215,
    uid: 40215,
    装备名称: '栖贤韵',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 18300,
    武器伤害_最小值: 1971,
    武器伤害_最大值: 3285,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16744 },
      { 属性: 属性类型.根骨, 值: 1366 },
      { 属性: 属性类型.内功基础攻击, 值: 7367 },
      { 属性: 属性类型.内功会心等级, 值: 8372 },
      { 属性: 属性类型.内功破防等级, 值: 8753 },
      { 属性: 属性类型.加速等级, 值: 4186 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40211,
    uid: 40211,
    装备名称: '瀚海引',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 18300,
    武器伤害_最小值: 3942,
    武器伤害_最大值: 6570,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16744 },
      { 属性: 属性类型.元气, 值: 1366 },
      { 属性: 属性类型.内功基础攻击, 值: 7367 },
      { 属性: 属性类型.内功会心等级, 值: 7992 },
      { 属性: 属性类型.内功破防等级, 值: 9133 },
      { 属性: 属性类型.加速等级, 值: 4186 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40206,
    uid: 40206,
    装备名称: '碧岚幽炎',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 18300,
    武器伤害_最小值: 3942,
    武器伤害_最大值: 6570,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16744 },
      { 属性: 属性类型.元气, 值: 1366 },
      { 属性: 属性类型.内功基础攻击, 值: 7367 },
      { 属性: 属性类型.外功会心等级, 值: 7992 },
      { 属性: 属性类型.内功破防等级, 值: 9514 },
      { 属性: 属性类型.加速等级, 值: 3806 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40204,
    uid: 40204,
    装备名称: '蜕骨',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 18300,
    武器伤害_最小值: 1971,
    武器伤害_最大值: 3285,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16744 },
      { 属性: 属性类型.根骨, 值: 1366 },
      { 属性: 属性类型.内功基础攻击, 值: 7482 },
      { 属性: 属性类型.内功会心等级, 值: 7992 },
      { 属性: 属性类型.内功破防等级, 值: 9133 },
      { 属性: 属性类型.加速等级, 值: 3806 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40202,
    uid: 40202,
    装备名称: '龙鲤',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 18300,
    武器伤害_最小值: 2562,
    武器伤害_最大值: 4270,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16744 },
      { 属性: 属性类型.根骨, 值: 1366 },
      { 属性: 属性类型.内功基础攻击, 值: 7597 },
      { 属性: 属性类型.内功会心等级, 值: 7992 },
      { 属性: 属性类型.内功破防等级, 值: 8372 },
      { 属性: 属性类型.加速等级, 值: 4186 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40200,
    uid: 40200,
    装备名称: '仙灵',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 18300,
    武器伤害_最小值: 2562,
    武器伤害_最大值: 4270,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16744 },
      { 属性: 属性类型.根骨, 值: 1366 },
      { 属性: 属性类型.内功基础攻击, 值: 7367 },
      { 属性: 属性类型.内功会心等级, 值: 9514 },
      { 属性: 属性类型.内功破防等级, 值: 7992 },
      { 属性: 属性类型.加速等级, 值: 3806 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40196,
    uid: 40196,
    装备名称: '意真',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 18300,
    武器伤害_最小值: 1971,
    武器伤害_最大值: 3285,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16744 },
      { 属性: 属性类型.元气, 值: 1366 },
      { 属性: 属性类型.内功基础攻击, 值: 7597 },
      { 属性: 属性类型.内功会心等级, 值: 7992 },
      { 属性: 属性类型.内功破防等级, 值: 8753 },
      { 属性: 属性类型.加速等级, 值: 3806 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40194,
    uid: 40194,
    装备名称: '昭佛光',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 18300,
    武器伤害_最小值: 6701,
    武器伤害_最大值: 11168,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16744 },
      { 属性: 属性类型.元气, 值: 1366 },
      { 属性: 属性类型.内功基础攻击, 值: 7827 },
      { 属性: 属性类型.内功会心等级, 值: 7611 },
      { 属性: 属性类型.内功破防等级, 值: 8372 },
      { 属性: 属性类型.加速等级, 值: 3806 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40193,
    uid: 40193,
    装备名称: '伏龙阳焰',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 17100,
    武器伤害_最小值: 3683,
    武器伤害_最大值: 6138,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 15647 },
      { 属性: 属性类型.元气, 值: 1277 },
      { 属性: 属性类型.内功基础攻击, 值: 7099 },
      { 属性: 属性类型.内功会心等级, 值: 7823 },
      { 属性: 属性类型.内功破防等级, 值: 7823 },
      { 属性: 属性类型.加速等级, 值: 3556 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40189,
    uid: 40189,
    装备名称: '烬灭',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 17100,
    武器伤害_最小值: 3683,
    武器伤害_最大值: 6138,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 15647 },
      { 属性: 属性类型.根骨, 值: 1277 },
      { 属性: 属性类型.内功基础攻击, 值: 6991 },
      { 属性: 属性类型.内功会心等级, 值: 7468 },
      { 属性: 属性类型.内功破防等级, 值: 8534 },
      { 属性: 属性类型.加速等级, 值: 3556 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40188,
    uid: 40188,
    装备名称: '子不语',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 17100,
    武器伤害_最小值: 3683,
    武器伤害_最大值: 6138,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 15647 },
      { 属性: 属性类型.元气, 值: 1277 },
      { 属性: 属性类型.内功基础攻击, 值: 7099 },
      { 属性: 属性类型.内功会心等级, 值: 7112 },
      { 属性: 属性类型.内功破防等级, 值: 7823 },
      { 属性: 属性类型.加速等级, 值: 4267 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40183,
    uid: 40183,
    装备名称: '栖贤韵',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 17100,
    武器伤害_最小值: 1842,
    武器伤害_最大值: 3070,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 15647 },
      { 属性: 属性类型.根骨, 值: 1277 },
      { 属性: 属性类型.内功基础攻击, 值: 6884 },
      { 属性: 属性类型.内功会心等级, 值: 7823 },
      { 属性: 属性类型.内功破防等级, 值: 8179 },
      { 属性: 属性类型.加速等级, 值: 3912 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40179,
    uid: 40179,
    装备名称: '瀚海引',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 17100,
    武器伤害_最小值: 3683,
    武器伤害_最大值: 6138,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 15647 },
      { 属性: 属性类型.元气, 值: 1277 },
      { 属性: 属性类型.内功基础攻击, 值: 6884 },
      { 属性: 属性类型.内功会心等级, 值: 7468 },
      { 属性: 属性类型.内功破防等级, 值: 8534 },
      { 属性: 属性类型.加速等级, 值: 3912 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40174,
    uid: 40174,
    装备名称: '碧岚幽炎',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 17100,
    武器伤害_最小值: 3683,
    武器伤害_最大值: 6138,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 15647 },
      { 属性: 属性类型.元气, 值: 1277 },
      { 属性: 属性类型.内功基础攻击, 值: 6884 },
      { 属性: 属性类型.外功会心等级, 值: 7468 },
      { 属性: 属性类型.内功破防等级, 值: 8890 },
      { 属性: 属性类型.加速等级, 值: 3556 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40172,
    uid: 40172,
    装备名称: '蜕骨',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 17100,
    武器伤害_最小值: 1842,
    武器伤害_最大值: 3070,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 15647 },
      { 属性: 属性类型.根骨, 值: 1277 },
      { 属性: 属性类型.内功基础攻击, 值: 6991 },
      { 属性: 属性类型.内功会心等级, 值: 7468 },
      { 属性: 属性类型.内功破防等级, 值: 8534 },
      { 属性: 属性类型.加速等级, 值: 3556 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40170,
    uid: 40170,
    装备名称: '龙鲤',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 17100,
    武器伤害_最小值: 2394,
    武器伤害_最大值: 3990,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 15647 },
      { 属性: 属性类型.根骨, 值: 1277 },
      { 属性: 属性类型.内功基础攻击, 值: 7099 },
      { 属性: 属性类型.内功会心等级, 值: 7468 },
      { 属性: 属性类型.内功破防等级, 值: 7823 },
      { 属性: 属性类型.加速等级, 值: 3912 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40168,
    uid: 40168,
    装备名称: '仙灵',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 17100,
    武器伤害_最小值: 2394,
    武器伤害_最大值: 3990,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 15647 },
      { 属性: 属性类型.根骨, 值: 1277 },
      { 属性: 属性类型.内功基础攻击, 值: 6884 },
      { 属性: 属性类型.内功会心等级, 值: 8890 },
      { 属性: 属性类型.内功破防等级, 值: 7468 },
      { 属性: 属性类型.加速等级, 值: 3556 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40164,
    uid: 40164,
    装备名称: '意真',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 17100,
    武器伤害_最小值: 1842,
    武器伤害_最大值: 3070,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 15647 },
      { 属性: 属性类型.元气, 值: 1277 },
      { 属性: 属性类型.内功基础攻击, 值: 7099 },
      { 属性: 属性类型.内功会心等级, 值: 7468 },
      { 属性: 属性类型.内功破防等级, 值: 8179 },
      { 属性: 属性类型.加速等级, 值: 3556 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40162,
    uid: 40162,
    装备名称: '昭佛光',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 17100,
    武器伤害_最小值: 6261,
    武器伤害_最大值: 10435,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 15647 },
      { 属性: 属性类型.元气, 值: 1277 },
      { 属性: 属性类型.内功基础攻击, 值: 7314 },
      { 属性: 属性类型.内功会心等级, 值: 7112 },
      { 属性: 属性类型.内功破防等级, 值: 7823 },
      { 属性: 属性类型.加速等级, 值: 3556 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40557,
    uid: 40557,
    装备名称: '停湖雀',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 16000,
    武器伤害_最小值: 3446,
    武器伤害_最大值: 5743,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14640 },
      { 属性: 属性类型.元气, 值: 1194 },
      { 属性: 属性类型.内功基础攻击, 值: 6642 },
      { 属性: 属性类型.内功会心等级, 值: 7320 },
      { 属性: 属性类型.内功破防等级, 值: 7320 },
      { 属性: 属性类型.加速等级, 值: 3327 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40556,
    uid: 40556,
    装备名称: '停湖雀',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 15500,
    武器伤害_最小值: 3338,
    武器伤害_最大值: 5564,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14182 },
      { 属性: 属性类型.元气, 值: 1157 },
      { 属性: 属性类型.内功基础攻击, 值: 6435 },
      { 属性: 属性类型.内功会心等级, 值: 7091 },
      { 属性: 属性类型.内功破防等级, 值: 7091 },
      { 属性: 属性类型.加速等级, 值: 3223 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40555,
    uid: 40555,
    装备名称: '停湖雀',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 15000,
    武器伤害_最小值: 3231,
    武器伤害_最大值: 5385,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13725 },
      { 属性: 属性类型.元气, 值: 1120 },
      { 属性: 属性类型.内功基础攻击, 值: 6227 },
      { 属性: 属性类型.内功会心等级, 值: 6862 },
      { 属性: 属性类型.内功破防等级, 值: 6862 },
      { 属性: 属性类型.加速等级, 值: 3119 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40617,
    uid: 40617,
    装备名称: '他山月',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 2692,
    武器伤害_最大值: 4487,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11438 },
      { 属性: 属性类型.元气, 值: 933 },
      { 属性: 属性类型.内功基础攻击, 值: 5189 },
      { 属性: 属性类型.内功会心等级, 值: 5719 },
      { 属性: 属性类型.内功破防等级, 值: 5719 },
      { 属性: 属性类型.加速等级, 值: 2599 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40554,
    uid: 40554,
    装备名称: '煞·他山月',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 2692,
    武器伤害_最大值: 4487,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11438 },
      { 属性: 属性类型.元气, 值: 933 },
      { 属性: 属性类型.内功基础攻击, 值: 5189 },
      { 属性: 属性类型.内功会心等级, 值: 5719 },
      { 属性: 属性类型.内功破防等级, 值: 5719 },
      { 属性: 属性类型.加速等级, 值: 2599 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38584,
    uid: 38584,
    装备名称: '煞·鹿王本生',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 2692,
    武器伤害_最大值: 4487,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11438 },
      { 属性: 属性类型.根骨, 值: 933 },
      { 属性: 属性类型.内功基础攻击, 值: 5111 },
      { 属性: 属性类型.内功会心等级, 值: 5459 },
      { 属性: 属性类型.内功破防等级, 值: 6239 },
      { 属性: 属性类型.加速等级, 值: 2599 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38583,
    uid: 38583,
    装备名称: '煞·月升海楼',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 2692,
    武器伤害_最大值: 4487,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11438 },
      { 属性: 属性类型.元气, 值: 933 },
      { 属性: 属性类型.内功基础攻击, 值: 5189 },
      { 属性: 属性类型.内功会心等级, 值: 5199 },
      { 属性: 属性类型.内功破防等级, 值: 5719 },
      { 属性: 属性类型.加速等级, 值: 3119 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38578,
    uid: 38578,
    装备名称: '煞·抚今',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 1346,
    武器伤害_最大值: 2243,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11438 },
      { 属性: 属性类型.根骨, 值: 933 },
      { 属性: 属性类型.内功基础攻击, 值: 5032 },
      { 属性: 属性类型.内功会心等级, 值: 5719 },
      { 属性: 属性类型.内功破防等级, 值: 5979 },
      { 属性: 属性类型.加速等级, 值: 2859 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38574,
    uid: 38574,
    装备名称: '煞·血月',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 2692,
    武器伤害_最大值: 4487,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11438 },
      { 属性: 属性类型.元气, 值: 933 },
      { 属性: 属性类型.内功基础攻击, 值: 5032 },
      { 属性: 属性类型.内功会心等级, 值: 5459 },
      { 属性: 属性类型.内功破防等级, 值: 6239 },
      { 属性: 属性类型.加速等级, 值: 2859 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38569,
    uid: 38569,
    装备名称: '煞·罪骨浮屠',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 2692,
    武器伤害_最大值: 4487,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11438 },
      { 属性: 属性类型.元气, 值: 933 },
      { 属性: 属性类型.内功基础攻击, 值: 5032 },
      { 属性: 属性类型.外功会心等级, 值: 5459 },
      { 属性: 属性类型.内功破防等级, 值: 6499 },
      { 属性: 属性类型.加速等级, 值: 2599 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38567,
    uid: 38567,
    装备名称: '煞·墨语沉香',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 1346,
    武器伤害_最大值: 2243,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11438 },
      { 属性: 属性类型.根骨, 值: 933 },
      { 属性: 属性类型.内功基础攻击, 值: 5111 },
      { 属性: 属性类型.内功会心等级, 值: 5459 },
      { 属性: 属性类型.内功破防等级, 值: 6239 },
      { 属性: 属性类型.加速等级, 值: 2599 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38565,
    uid: 38565,
    装备名称: '煞·飞霜绛露',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 1750,
    武器伤害_最大值: 2917,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11438 },
      { 属性: 属性类型.根骨, 值: 933 },
      { 属性: 属性类型.内功基础攻击, 值: 5189 },
      { 属性: 属性类型.内功会心等级, 值: 5459 },
      { 属性: 属性类型.内功破防等级, 值: 5719 },
      { 属性: 属性类型.加速等级, 值: 2859 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38563,
    uid: 38563,
    装备名称: '煞·苍冥游',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 1750,
    武器伤害_最大值: 2917,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11438 },
      { 属性: 属性类型.根骨, 值: 933 },
      { 属性: 属性类型.内功基础攻击, 值: 5032 },
      { 属性: 属性类型.内功会心等级, 值: 6499 },
      { 属性: 属性类型.内功破防等级, 值: 5459 },
      { 属性: 属性类型.加速等级, 值: 2599 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38559,
    uid: 38559,
    装备名称: '煞·璃光浮远',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 1346,
    武器伤害_最大值: 2243,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11438 },
      { 属性: 属性类型.元气, 值: 933 },
      { 属性: 属性类型.内功基础攻击, 值: 5189 },
      { 属性: 属性类型.内功会心等级, 值: 5459 },
      { 属性: 属性类型.内功破防等级, 值: 5979 },
      { 属性: 属性类型.加速等级, 值: 2599 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38557,
    uid: 38557,
    装备名称: '煞·桑莲妙境',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 4576,
    武器伤害_最大值: 7627,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11438 },
      { 属性: 属性类型.元气, 值: 933 },
      { 属性: 属性类型.内功基础攻击, 值: 5346 },
      { 属性: 属性类型.内功会心等级, 值: 5199 },
      { 属性: 属性类型.内功破防等级, 值: 5719 },
      { 属性: 属性类型.加速等级, 值: 2599 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38074,
    uid: 38074,
    装备名称: '鹿王本生',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 2692,
    武器伤害_最大值: 4487,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11438 },
      { 属性: 属性类型.根骨, 值: 933 },
      { 属性: 属性类型.内功基础攻击, 值: 5111 },
      { 属性: 属性类型.内功会心等级, 值: 5459 },
      { 属性: 属性类型.内功破防等级, 值: 6239 },
      { 属性: 属性类型.加速等级, 值: 2599 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38073,
    uid: 38073,
    装备名称: '月升海楼',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 2692,
    武器伤害_最大值: 4487,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11438 },
      { 属性: 属性类型.元气, 值: 933 },
      { 属性: 属性类型.内功基础攻击, 值: 5189 },
      { 属性: 属性类型.内功会心等级, 值: 5199 },
      { 属性: 属性类型.内功破防等级, 值: 5719 },
      { 属性: 属性类型.加速等级, 值: 3119 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38068,
    uid: 38068,
    装备名称: '抚今',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 1346,
    武器伤害_最大值: 2243,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11438 },
      { 属性: 属性类型.根骨, 值: 933 },
      { 属性: 属性类型.内功基础攻击, 值: 5032 },
      { 属性: 属性类型.内功会心等级, 值: 5719 },
      { 属性: 属性类型.内功破防等级, 值: 5979 },
      { 属性: 属性类型.加速等级, 值: 2859 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38064,
    uid: 38064,
    装备名称: '血月',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 2692,
    武器伤害_最大值: 4487,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11438 },
      { 属性: 属性类型.元气, 值: 933 },
      { 属性: 属性类型.内功基础攻击, 值: 5032 },
      { 属性: 属性类型.内功会心等级, 值: 5459 },
      { 属性: 属性类型.内功破防等级, 值: 6239 },
      { 属性: 属性类型.加速等级, 值: 2859 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38059,
    uid: 38059,
    装备名称: '罪骨浮屠',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 2692,
    武器伤害_最大值: 4487,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11438 },
      { 属性: 属性类型.元气, 值: 933 },
      { 属性: 属性类型.内功基础攻击, 值: 5032 },
      { 属性: 属性类型.外功会心等级, 值: 5459 },
      { 属性: 属性类型.内功破防等级, 值: 6499 },
      { 属性: 属性类型.加速等级, 值: 2599 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38057,
    uid: 38057,
    装备名称: '墨语沉香',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 1346,
    武器伤害_最大值: 2243,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11438 },
      { 属性: 属性类型.根骨, 值: 933 },
      { 属性: 属性类型.内功基础攻击, 值: 5111 },
      { 属性: 属性类型.内功会心等级, 值: 5459 },
      { 属性: 属性类型.内功破防等级, 值: 6239 },
      { 属性: 属性类型.加速等级, 值: 2599 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38055,
    uid: 38055,
    装备名称: '飞霜绛露',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 1750,
    武器伤害_最大值: 2917,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11438 },
      { 属性: 属性类型.根骨, 值: 933 },
      { 属性: 属性类型.内功基础攻击, 值: 5189 },
      { 属性: 属性类型.内功会心等级, 值: 5459 },
      { 属性: 属性类型.内功破防等级, 值: 5719 },
      { 属性: 属性类型.加速等级, 值: 2859 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38053,
    uid: 38053,
    装备名称: '苍冥游',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 1750,
    武器伤害_最大值: 2917,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11438 },
      { 属性: 属性类型.根骨, 值: 933 },
      { 属性: 属性类型.内功基础攻击, 值: 5032 },
      { 属性: 属性类型.内功会心等级, 值: 6499 },
      { 属性: 属性类型.内功破防等级, 值: 5459 },
      { 属性: 属性类型.加速等级, 值: 2599 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38049,
    uid: 38049,
    装备名称: '璃光浮远',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 1346,
    武器伤害_最大值: 2243,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11438 },
      { 属性: 属性类型.元气, 值: 933 },
      { 属性: 属性类型.内功基础攻击, 值: 5189 },
      { 属性: 属性类型.内功会心等级, 值: 5459 },
      { 属性: 属性类型.内功破防等级, 值: 5979 },
      { 属性: 属性类型.加速等级, 值: 2599 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38047,
    uid: 38047,
    装备名称: '桑莲妙境',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 4576,
    武器伤害_最大值: 7627,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11438 },
      { 属性: 属性类型.元气, 值: 933 },
      { 属性: 属性类型.内功基础攻击, 值: 5346 },
      { 属性: 属性类型.内功会心等级, 值: 5199 },
      { 属性: 属性类型.内功破防等级, 值: 5719 },
      { 属性: 属性类型.加速等级, 值: 2599 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40421,
    uid: 40421,
    装备名称: '陶然意·月容扇',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 26300,
    武器伤害_最小值: 4079,
    武器伤害_最大值: 6798,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17326 },
      { 属性: 属性类型.元气, 值: 1313 },
      { 属性: 属性类型.内功基础攻击, 值: 6671 },
      { 属性: 属性类型.内功破防等级, 值: 11813 },
      { 属性: 属性类型.全能等级, 值: 4725 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 40418,
    uid: 40418,
    装备名称: '陶然意·修茂卷',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 26300,
    武器伤害_最小值: 4079,
    武器伤害_最大值: 6798,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17326 },
      { 属性: 属性类型.根骨, 值: 1313 },
      { 属性: 属性类型.内功基础攻击, 值: 6671 },
      { 属性: 属性类型.内功破防等级, 值: 11813 },
      { 属性: 属性类型.全能等级, 值: 4725 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 40417,
    uid: 40417,
    装备名称: '陶然意·理乾灯',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 26300,
    武器伤害_最小值: 4079,
    武器伤害_最大值: 6798,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17326 },
      { 属性: 属性类型.元气, 值: 1313 },
      { 属性: 属性类型.内功基础攻击, 值: 6671 },
      { 属性: 属性类型.内功破防等级, 值: 11813 },
      { 属性: 属性类型.全能等级, 值: 4725 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 40413,
    uid: 40413,
    装备名称: '陶然意·来仪琴',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 26300,
    武器伤害_最小值: 2039,
    武器伤害_最大值: 3399,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17326 },
      { 属性: 属性类型.根骨, 值: 1313 },
      { 属性: 属性类型.内功基础攻击, 值: 6671 },
      { 属性: 属性类型.内功破防等级, 值: 11813 },
      { 属性: 属性类型.全能等级, 值: 4725 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 40411,
    uid: 40411,
    装备名称: '陶然意·凡圣双刀',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 26300,
    武器伤害_最小值: 4079,
    武器伤害_最大值: 6798,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17326 },
      { 属性: 属性类型.元气, 值: 1313 },
      { 属性: 属性类型.内功基础攻击, 值: 6671 },
      { 属性: 属性类型.内功破防等级, 值: 11813 },
      { 属性: 属性类型.全能等级, 值: 4725 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 40406,
    uid: 40406,
    装备名称: '陶然意·连筠弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 26300,
    武器伤害_最小值: 4079,
    武器伤害_最大值: 6798,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17326 },
      { 属性: 属性类型.元气, 值: 1313 },
      { 属性: 属性类型.内功基础攻击, 值: 6671 },
      { 属性: 属性类型.内功破防等级, 值: 11813 },
      { 属性: 属性类型.全能等级, 值: 4725 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 40405,
    uid: 40405,
    装备名称: '陶然意·蛇言笛',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 26300,
    武器伤害_最小值: 2039,
    武器伤害_最大值: 3399,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17326 },
      { 属性: 属性类型.根骨, 值: 1313 },
      { 属性: 属性类型.内功基础攻击, 值: 6671 },
      { 属性: 属性类型.内功破防等级, 值: 11813 },
      { 属性: 属性类型.全能等级, 值: 4725 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 40404,
    uid: 40404,
    装备名称: '陶然意·寒河双剑',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 26300,
    武器伤害_最小值: 2651,
    武器伤害_最大值: 4418,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17326 },
      { 属性: 属性类型.根骨, 值: 1313 },
      { 属性: 属性类型.内功基础攻击, 值: 6671 },
      { 属性: 属性类型.内功破防等级, 值: 11813 },
      { 属性: 属性类型.全能等级, 值: 4725 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 40402,
    uid: 40402,
    装备名称: '陶然意·清静剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 26300,
    武器伤害_最小值: 2651,
    武器伤害_最大值: 4418,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17326 },
      { 属性: 属性类型.根骨, 值: 1313 },
      { 属性: 属性类型.内功基础攻击, 值: 6671 },
      { 属性: 属性类型.内功破防等级, 值: 11813 },
      { 属性: 属性类型.全能等级, 值: 4725 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 40400,
    uid: 40400,
    装备名称: '陶然意·簇锦笔',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 26300,
    武器伤害_最小值: 2039,
    武器伤害_最大值: 3399,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17326 },
      { 属性: 属性类型.元气, 值: 1313 },
      { 属性: 属性类型.内功基础攻击, 值: 6671 },
      { 属性: 属性类型.内功破防等级, 值: 11813 },
      { 属性: 属性类型.全能等级, 值: 4725 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 40399,
    uid: 40399,
    装备名称: '陶然意·祇树杖',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 26300,
    武器伤害_最小值: 6934,
    武器伤害_最大值: 11557,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17326 },
      { 属性: 属性类型.元气, 值: 1313 },
      { 属性: 属性类型.内功基础攻击, 值: 6671 },
      { 属性: 属性类型.内功破防等级, 值: 11813 },
      { 属性: 属性类型.全能等级, 值: 4725 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 39395,
    uid: 39395,
    装备名称: '富贵人间',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 26300,
    武器伤害_最小值: 4079,
    武器伤害_最大值: 6798,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17326 },
      { 属性: 属性类型.元气, 值: 1414 },
      { 属性: 属性类型.内功基础攻击, 值: 7861 },
      { 属性: 属性类型.内功会心等级, 值: 11813 },
      { 属性: 属性类型.无双等级, 值: 11420 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 39391,
    uid: 39391,
    装备名称: '江湖岁晚',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 26300,
    武器伤害_最小值: 4079,
    武器伤害_最大值: 6798,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17326 },
      { 属性: 属性类型.根骨, 值: 1414 },
      { 属性: 属性类型.内功基础攻击, 值: 7861 },
      { 属性: 属性类型.内功会心等级, 值: 11813 },
      { 属性: 属性类型.无双等级, 值: 11420 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 39390,
    uid: 39390,
    装备名称: '古人',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 26300,
    武器伤害_最小值: 4079,
    武器伤害_最大值: 6798,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17326 },
      { 属性: 属性类型.元气, 值: 1414 },
      { 属性: 属性类型.内功基础攻击, 值: 7861 },
      { 属性: 属性类型.内功会心等级, 值: 11813 },
      { 属性: 属性类型.无双等级, 值: 11420 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 39385,
    uid: 39385,
    装备名称: '共奏清音',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 26300,
    武器伤害_最小值: 2039,
    武器伤害_最大值: 3399,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17326 },
      { 属性: 属性类型.根骨, 值: 1414 },
      { 属性: 属性类型.内功基础攻击, 值: 7861 },
      { 属性: 属性类型.内功会心等级, 值: 11813 },
      { 属性: 属性类型.无双等级, 值: 11420 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 39381,
    uid: 39381,
    装备名称: '冷露',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 26300,
    武器伤害_最小值: 4079,
    武器伤害_最大值: 6798,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17326 },
      { 属性: 属性类型.元气, 值: 1414 },
      { 属性: 属性类型.内功基础攻击, 值: 7861 },
      { 属性: 属性类型.内功会心等级, 值: 11813 },
      { 属性: 属性类型.无双等级, 值: 11420 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 39376,
    uid: 39376,
    装备名称: '寰区',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 26300,
    武器伤害_最小值: 4079,
    武器伤害_最大值: 6798,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17326 },
      { 属性: 属性类型.元气, 值: 1414 },
      { 属性: 属性类型.内功基础攻击, 值: 7861 },
      { 属性: 属性类型.外功会心等级, 值: 11813 },
      { 属性: 属性类型.无双等级, 值: 11420 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 39374,
    uid: 39374,
    装备名称: '古调',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 26300,
    武器伤害_最小值: 2039,
    武器伤害_最大值: 3399,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17326 },
      { 属性: 属性类型.根骨, 值: 1414 },
      { 属性: 属性类型.内功基础攻击, 值: 7861 },
      { 属性: 属性类型.内功会心等级, 值: 11813 },
      { 属性: 属性类型.无双等级, 值: 11420 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 39372,
    uid: 39372,
    装备名称: '舞青龙',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 26300,
    武器伤害_最小值: 2651,
    武器伤害_最大值: 4418,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17326 },
      { 属性: 属性类型.根骨, 值: 1414 },
      { 属性: 属性类型.内功基础攻击, 值: 7861 },
      { 属性: 属性类型.内功会心等级, 值: 11813 },
      { 属性: 属性类型.无双等级, 值: 11420 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 39370,
    uid: 39370,
    装备名称: '万丈',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 26300,
    武器伤害_最小值: 2651,
    武器伤害_最大值: 4418,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17326 },
      { 属性: 属性类型.根骨, 值: 1414 },
      { 属性: 属性类型.内功基础攻击, 值: 7861 },
      { 属性: 属性类型.内功会心等级, 值: 11813 },
      { 属性: 属性类型.无双等级, 值: 11420 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 39366,
    uid: 39366,
    装备名称: '照树',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 26300,
    武器伤害_最小值: 2039,
    武器伤害_最大值: 3399,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17326 },
      { 属性: 属性类型.元气, 值: 1414 },
      { 属性: 属性类型.内功基础攻击, 值: 7861 },
      { 属性: 属性类型.内功会心等级, 值: 11813 },
      { 属性: 属性类型.无双等级, 值: 11420 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 39364,
    uid: 39364,
    装备名称: '问师',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 26300,
    武器伤害_最小值: 6934,
    武器伤害_最大值: 11557,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17326 },
      { 属性: 属性类型.元气, 值: 1414 },
      { 属性: 属性类型.内功基础攻击, 值: 7861 },
      { 属性: 属性类型.内功会心等级, 值: 11813 },
      { 属性: 属性类型.无双等级, 值: 11420 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 39357,
    uid: 39357,
    装备名称: '竟无归',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备特效: 装备特效枚举.水特效武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.内功破防等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39353,
    uid: 39353,
    装备名称: '哀声急',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备特效: 装备特效枚举.水特效武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.根骨, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.内功破防等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39352,
    uid: 39352,
    装备名称: '立长庚',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备特效: 装备特效枚举.水特效武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.内功破防等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39347,
    uid: 39347,
    装备名称: '愿相从',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 2008,
    武器伤害_最大值: 3347,
    装备特效: 装备特效枚举.水特效武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.根骨, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.内功破防等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39343,
    uid: 39343,
    装备名称: '阅世悲',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备特效: 装备特效枚举.水特效武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.内功破防等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39338,
    uid: 39338,
    装备名称: '蜀道山',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备特效: 装备特效枚举.水特效武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.内功破防等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39336,
    uid: 39336,
    装备名称: '空度夜',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 2008,
    武器伤害_最大值: 3347,
    装备特效: 装备特效枚举.水特效武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.根骨, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.内功破防等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39334,
    uid: 39334,
    装备名称: '月初生',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 2611,
    武器伤害_最大值: 4352,
    装备特效: 装备特效枚举.水特效武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.根骨, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.内功破防等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39332,
    uid: 39332,
    装备名称: '如云浮',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 2611,
    武器伤害_最大值: 4352,
    装备特效: 装备特效枚举.水特效武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.根骨, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.内功破防等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39328,
    uid: 39328,
    装备名称: '萝垂带',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 2008,
    武器伤害_最大值: 3347,
    装备特效: 装备特效枚举.水特效武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.内功破防等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39326,
    uid: 39326,
    装备名称: '度好日',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6828,
    武器伤害_最大值: 11380,
    装备特效: 装备特效枚举.水特效武器,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.内功破防等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39323,
    uid: 39323,
    装备名称: '云思扇',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39319,
    uid: 39319,
    装备名称: '窥岩卷',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.根骨, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39318,
    uid: 39318,
    装备名称: '闻传魂灯',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39313,
    uid: 39313,
    装备名称: '碧缀琴',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 2008,
    武器伤害_最大值: 3347,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.根骨, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39309,
    uid: 39309,
    装备名称: '千念刀',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39304,
    uid: 39304,
    装备名称: '十夕弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39302,
    uid: 39302,
    装备名称: '皎明笛',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 2008,
    武器伤害_最大值: 3347,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.根骨, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39300,
    uid: 39300,
    装备名称: '垂光双剑',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 2611,
    武器伤害_最大值: 4352,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.根骨, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39298,
    uid: 39298,
    装备名称: '寸心剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 2611,
    武器伤害_最大值: 4352,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.根骨, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39294,
    uid: 39294,
    装备名称: '览镜笔',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 2008,
    武器伤害_最大值: 3347,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39292,
    uid: 39292,
    装备名称: '灵真棍',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6828,
    武器伤害_最大值: 11380,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39283,
    uid: 39283,
    装备名称: '馀樽扇',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.内功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 39279,
    uid: 39279,
    装备名称: '流黄卷',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.根骨, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.内功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 39278,
    uid: 39278,
    装备名称: '广寒灯',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.内功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 39273,
    uid: 39273,
    装备名称: '行酌琴',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 2008,
    武器伤害_最大值: 3347,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.根骨, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.内功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 39269,
    uid: 39269,
    装备名称: '边月刀',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.内功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 39264,
    uid: 39264,
    装备名称: '照露弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.外功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 39262,
    uid: 39262,
    装备名称: '幽人笛',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 2008,
    武器伤害_最大值: 3347,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.根骨, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.内功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 39260,
    uid: 39260,
    装备名称: '映雪双剑',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 2611,
    武器伤害_最大值: 4352,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.根骨, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.内功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 39258,
    uid: 39258,
    装备名称: '朝真剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 2611,
    武器伤害_最大值: 4352,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.根骨, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.内功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 39254,
    uid: 39254,
    装备名称: '愁悴笔',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 2008,
    武器伤害_最大值: 3347,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.内功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 39252,
    uid: 39252,
    装备名称: '灵音棍',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6828,
    武器伤害_最大值: 11380,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.内功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 39179,
    uid: 39179,
    装备名称: '绣白衣',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39175,
    uid: 39175,
    装备名称: '奇理续命',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.根骨, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39174,
    uid: 39174,
    装备名称: '熠耀光',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39169,
    uid: 39169,
    装备名称: '月洗凡尘',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 2008,
    武器伤害_最大值: 3347,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.根骨, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39165,
    uid: 39165,
    装备名称: '茫沙野',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39160,
    uid: 39160,
    装备名称: '碧叶溅朱',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39158,
    uid: 39158,
    装备名称: '何哀',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 2008,
    武器伤害_最大值: 3347,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.根骨, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39156,
    uid: 39156,
    装备名称: '轻姿',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 2611,
    武器伤害_最大值: 4352,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.根骨, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39154,
    uid: 39154,
    装备名称: '步云轻',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 2611,
    武器伤害_最大值: 4352,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.根骨, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39150,
    uid: 39150,
    装备名称: '夜微',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 2008,
    武器伤害_最大值: 3347,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39148,
    uid: 39148,
    装备名称: '松照禅衣',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6828,
    武器伤害_最大值: 11380,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40366,
    uid: 40366,
    装备名称: '陶然意·月容扇',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 22600,
    武器伤害_最小值: 3505,
    武器伤害_最大值: 5842,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14889 },
      { 属性: 属性类型.元气, 值: 1128 },
      { 属性: 属性类型.内功基础攻击, 值: 5733 },
      { 属性: 属性类型.内功破防等级, 值: 10152 },
      { 属性: 属性类型.全能等级, 值: 4061 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 40363,
    uid: 40363,
    装备名称: '陶然意·修茂卷',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 22600,
    武器伤害_最小值: 3505,
    武器伤害_最大值: 5842,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14889 },
      { 属性: 属性类型.根骨, 值: 1128 },
      { 属性: 属性类型.内功基础攻击, 值: 5733 },
      { 属性: 属性类型.内功破防等级, 值: 10152 },
      { 属性: 属性类型.全能等级, 值: 4061 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 40362,
    uid: 40362,
    装备名称: '陶然意·理乾灯',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 22600,
    武器伤害_最小值: 3505,
    武器伤害_最大值: 5842,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14889 },
      { 属性: 属性类型.元气, 值: 1128 },
      { 属性: 属性类型.内功基础攻击, 值: 5733 },
      { 属性: 属性类型.内功破防等级, 值: 10152 },
      { 属性: 属性类型.全能等级, 值: 4061 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 40358,
    uid: 40358,
    装备名称: '陶然意·来仪琴',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 22600,
    武器伤害_最小值: 1752,
    武器伤害_最大值: 2920,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14889 },
      { 属性: 属性类型.根骨, 值: 1128 },
      { 属性: 属性类型.内功基础攻击, 值: 5733 },
      { 属性: 属性类型.内功破防等级, 值: 10152 },
      { 属性: 属性类型.全能等级, 值: 4061 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 40356,
    uid: 40356,
    装备名称: '陶然意·凡圣双刀',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 22600,
    武器伤害_最小值: 3505,
    武器伤害_最大值: 5842,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14889 },
      { 属性: 属性类型.元气, 值: 1128 },
      { 属性: 属性类型.内功基础攻击, 值: 5733 },
      { 属性: 属性类型.内功破防等级, 值: 10152 },
      { 属性: 属性类型.全能等级, 值: 4061 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 40351,
    uid: 40351,
    装备名称: '陶然意·连筠弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22600,
    武器伤害_最小值: 3505,
    武器伤害_最大值: 5842,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14889 },
      { 属性: 属性类型.元气, 值: 1128 },
      { 属性: 属性类型.内功基础攻击, 值: 5733 },
      { 属性: 属性类型.内功破防等级, 值: 10152 },
      { 属性: 属性类型.全能等级, 值: 4061 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 40350,
    uid: 40350,
    装备名称: '陶然意·蛇言笛',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 22600,
    武器伤害_最小值: 1752,
    武器伤害_最大值: 2920,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14889 },
      { 属性: 属性类型.根骨, 值: 1128 },
      { 属性: 属性类型.内功基础攻击, 值: 5733 },
      { 属性: 属性类型.内功破防等级, 值: 10152 },
      { 属性: 属性类型.全能等级, 值: 4061 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 40349,
    uid: 40349,
    装备名称: '陶然意·寒河双剑',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 22600,
    武器伤害_最小值: 2278,
    武器伤害_最大值: 3797,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14889 },
      { 属性: 属性类型.根骨, 值: 1128 },
      { 属性: 属性类型.内功基础攻击, 值: 5733 },
      { 属性: 属性类型.内功破防等级, 值: 10152 },
      { 属性: 属性类型.全能等级, 值: 4061 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 40347,
    uid: 40347,
    装备名称: '陶然意·清静剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22600,
    武器伤害_最小值: 2278,
    武器伤害_最大值: 3797,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14889 },
      { 属性: 属性类型.根骨, 值: 1128 },
      { 属性: 属性类型.内功基础攻击, 值: 5733 },
      { 属性: 属性类型.内功破防等级, 值: 10152 },
      { 属性: 属性类型.全能等级, 值: 4061 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 40345,
    uid: 40345,
    装备名称: '陶然意·簇锦笔',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 22600,
    武器伤害_最小值: 1752,
    武器伤害_最大值: 2920,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14889 },
      { 属性: 属性类型.元气, 值: 1128 },
      { 属性: 属性类型.内功基础攻击, 值: 5733 },
      { 属性: 属性类型.内功破防等级, 值: 10152 },
      { 属性: 属性类型.全能等级, 值: 4061 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 40344,
    uid: 40344,
    装备名称: '陶然意·祇树杖',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 22600,
    武器伤害_最小值: 5958,
    武器伤害_最大值: 9930,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14889 },
      { 属性: 属性类型.元气, 值: 1128 },
      { 属性: 属性类型.内功基础攻击, 值: 5733 },
      { 属性: 属性类型.内功破防等级, 值: 10152 },
      { 属性: 属性类型.全能等级, 值: 4061 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 40095,
    uid: 40095,
    装备名称: '孤山寒月·云羽',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.内功破防等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40091,
    uid: 40091,
    装备名称: '孤山寒月·和方',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.根骨, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.内功破防等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40090,
    uid: 40090,
    装备名称: '孤山寒月·玄夜',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.内功破防等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40085,
    uid: 40085,
    装备名称: '孤山寒月·仙音',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 1745,
    武器伤害_最大值: 2908,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.根骨, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.内功破防等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40081,
    uid: 40081,
    装备名称: '孤山寒月·漠风',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.内功破防等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40076,
    uid: 40076,
    装备名称: '孤山寒月·疾电',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.内功破防等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40074,
    uid: 40074,
    装备名称: '孤山寒月·蓝翎',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 1745,
    武器伤害_最大值: 2908,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.根骨, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.内功破防等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40072,
    uid: 40072,
    装备名称: '孤山寒月·明珠',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 2268,
    武器伤害_最大值: 3780,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.根骨, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.内功破防等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40070,
    uid: 40070,
    装备名称: '孤山寒月·雪岚',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 2268,
    武器伤害_最大值: 3780,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.根骨, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.内功破防等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40066,
    uid: 40066,
    装备名称: '孤山寒月·花影',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 1745,
    武器伤害_最大值: 2908,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.内功破防等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40064,
    uid: 40064,
    装备名称: '孤山寒月·普济',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5932,
    武器伤害_最大值: 9887,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.内功破防等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39139,
    uid: 39139,
    装备名称: '眠霜扇',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39135,
    uid: 39135,
    装备名称: '天构卷',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.根骨, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39134,
    uid: 39134,
    装备名称: '旋月魂灯',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39129,
    uid: 39129,
    装备名称: '长谣琴',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 1745,
    武器伤害_最大值: 2908,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.根骨, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39125,
    uid: 39125,
    装备名称: '炽羲双刀',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39120,
    uid: 39120,
    装备名称: '竹尘弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39118,
    uid: 39118,
    装备名称: '和烛笛',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 1745,
    武器伤害_最大值: 2908,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.根骨, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39116,
    uid: 39116,
    装备名称: '樱繁双剑',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 2268,
    武器伤害_最大值: 3780,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.根骨, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39114,
    uid: 39114,
    装备名称: '愁雪剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 2268,
    武器伤害_最大值: 3780,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.根骨, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39110,
    uid: 39110,
    装备名称: '误云笔',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 1745,
    武器伤害_最大值: 2908,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39108,
    uid: 39108,
    装备名称: '阎浮棍',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5932,
    武器伤害_最大值: 9887,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39099,
    uid: 39099,
    装备名称: '无渡扇',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.内功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 39095,
    uid: 39095,
    装备名称: '含秋卷',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.根骨, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.内功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 39094,
    uid: 39094,
    装备名称: '曜罗魂灯',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.内功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 39089,
    uid: 39089,
    装备名称: '希音琴',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 1745,
    武器伤害_最大值: 2908,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.根骨, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.内功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 39085,
    uid: 39085,
    装备名称: '炎彰双刀',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.内功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 39080,
    uid: 39080,
    装备名称: '濯清弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.外功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 39078,
    uid: 39078,
    装备名称: '别欢笛',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 1745,
    武器伤害_最大值: 2908,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.根骨, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.内功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 39076,
    uid: 39076,
    装备名称: '敛雾双剑',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 2268,
    武器伤害_最大值: 3780,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.根骨, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.内功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 39074,
    uid: 39074,
    装备名称: '云鹤剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 2268,
    武器伤害_最大值: 3780,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.根骨, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.内功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 39070,
    uid: 39070,
    装备名称: '空烟笔',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 1745,
    武器伤害_最大值: 2908,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.内功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 39068,
    uid: 39068,
    装备名称: '闻尘棍',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5932,
    武器伤害_最大值: 9887,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.内功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 39061,
    uid: 39061,
    装备名称: '寻踪觅宝·乘渚扇',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39057,
    uid: 39057,
    装备名称: '寻踪觅宝·兰蕙卷',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.根骨, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39056,
    uid: 39056,
    装备名称: '寻踪觅宝·德火灯',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39051,
    uid: 39051,
    装备名称: '寻踪觅宝·俟音琴',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 1745,
    武器伤害_最大值: 2908,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.根骨, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39047,
    uid: 39047,
    装备名称: '寻踪觅宝·烛炘双刀',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39042,
    uid: 39042,
    装备名称: '寻踪觅宝·乐水弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39040,
    uid: 39040,
    装备名称: '寻踪觅宝·怜自笛',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 1745,
    武器伤害_最大值: 2908,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.根骨, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39038,
    uid: 39038,
    装备名称: '寻踪觅宝·旋仪双剑',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 2268,
    武器伤害_最大值: 3780,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.根骨, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39036,
    uid: 39036,
    装备名称: '寻踪觅宝·太常剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 2268,
    武器伤害_最大值: 3780,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.根骨, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39032,
    uid: 39032,
    装备名称: '寻踪觅宝·金谷笔',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 1745,
    武器伤害_最大值: 2908,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39030,
    uid: 39030,
    装备名称: '寻踪觅宝·悟解棍',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5932,
    武器伤害_最大值: 9887,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40993,
    uid: 40993,
    装备名称: '云风手抓饭·火候',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 22200,
    武器伤害_最小值: 3443,
    武器伤害_最大值: 5738,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14625 },
      { 属性: 属性类型.元气, 值: 1193 },
      { 属性: 属性类型.内功基础攻击, 值: 6636 },
      { 属性: 属性类型.内功破防等级, 值: 9972 },
      { 属性: 属性类型.无双等级, 值: 9639 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40989,
    uid: 40989,
    装备名称: '迎新春饼·火候',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 22200,
    武器伤害_最小值: 3443,
    武器伤害_最大值: 5738,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14625 },
      { 属性: 属性类型.根骨, 值: 1193 },
      { 属性: 属性类型.内功基础攻击, 值: 6636 },
      { 属性: 属性类型.内功破防等级, 值: 9972 },
      { 属性: 属性类型.无双等级, 值: 9639 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40988,
    uid: 40988,
    装备名称: '寻龙鸳鸯镬·火候',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 22200,
    武器伤害_最小值: 3443,
    武器伤害_最大值: 5738,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14625 },
      { 属性: 属性类型.元气, 值: 1193 },
      { 属性: 属性类型.内功基础攻击, 值: 6636 },
      { 属性: 属性类型.内功破防等级, 值: 9972 },
      { 属性: 属性类型.无双等级, 值: 9639 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40983,
    uid: 40983,
    装备名称: '古意调味匣·火候',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 22200,
    武器伤害_最小值: 1721,
    武器伤害_最大值: 2869,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14625 },
      { 属性: 属性类型.根骨, 值: 1193 },
      { 属性: 属性类型.内功基础攻击, 值: 6636 },
      { 属性: 属性类型.内功破防等级, 值: 9972 },
      { 属性: 属性类型.无双等级, 值: 9639 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40979,
    uid: 40979,
    装备名称: '妙火天香串·火候',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 22200,
    武器伤害_最小值: 3443,
    武器伤害_最大值: 5738,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14625 },
      { 属性: 属性类型.元气, 值: 1193 },
      { 属性: 属性类型.内功基础攻击, 值: 6636 },
      { 属性: 属性类型.内功破防等级, 值: 9972 },
      { 属性: 属性类型.无双等级, 值: 9639 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40974,
    uid: 40974,
    装备名称: '蜀月竹筒饭·火候',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22200,
    武器伤害_最小值: 3443,
    武器伤害_最大值: 5738,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14625 },
      { 属性: 属性类型.元气, 值: 1193 },
      { 属性: 属性类型.内功基础攻击, 值: 6636 },
      { 属性: 属性类型.内功破防等级, 值: 9972 },
      { 属性: 属性类型.无双等级, 值: 9639 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40972,
    uid: 40972,
    装备名称: '瑶星青玉葱·火候',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 22200,
    武器伤害_最小值: 1721,
    武器伤害_最大值: 2869,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14625 },
      { 属性: 属性类型.根骨, 值: 1193 },
      { 属性: 属性类型.内功基础攻击, 值: 6636 },
      { 属性: 属性类型.内功破防等级, 值: 9972 },
      { 属性: 属性类型.无双等级, 值: 9639 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40970,
    uid: 40970,
    装备名称: '霓裳玉女勺·火候',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 22200,
    武器伤害_最小值: 2238,
    武器伤害_最大值: 3730,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14625 },
      { 属性: 属性类型.根骨, 值: 1193 },
      { 属性: 属性类型.内功基础攻击, 值: 6636 },
      { 属性: 属性类型.内功破防等级, 值: 9972 },
      { 属性: 属性类型.无双等级, 值: 9639 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40968,
    uid: 40968,
    装备名称: '灵虚大菜刀·火候',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22200,
    武器伤害_最小值: 2238,
    武器伤害_最大值: 3730,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14625 },
      { 属性: 属性类型.根骨, 值: 1193 },
      { 属性: 属性类型.内功基础攻击, 值: 6636 },
      { 属性: 属性类型.内功破防等级, 值: 9972 },
      { 属性: 属性类型.无双等级, 值: 9639 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40964,
    uid: 40964,
    装备名称: '翡翠玲珑筷·火候',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 22200,
    武器伤害_最小值: 1721,
    武器伤害_最大值: 2869,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14625 },
      { 属性: 属性类型.元气, 值: 1193 },
      { 属性: 属性类型.内功基础攻击, 值: 6636 },
      { 属性: 属性类型.内功破防等级, 值: 9972 },
      { 属性: 属性类型.无双等级, 值: 9639 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40962,
    uid: 40962,
    装备名称: '菩提擀面杖·火候',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 22200,
    武器伤害_最小值: 5853,
    武器伤害_最大值: 9755,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14625 },
      { 属性: 属性类型.元气, 值: 1193 },
      { 属性: 属性类型.内功基础攻击, 值: 6636 },
      { 属性: 属性类型.内功破防等级, 值: 9972 },
      { 属性: 属性类型.无双等级, 值: 9639 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39029,
    uid: 39029,
    装备名称: '寻踪觅宝·群殊扇',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 3257,
    武器伤害_最大值: 5428,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.元气, 值: 1129 },
      { 属性: 属性类型.内功基础攻击, 值: 6277 },
      { 属性: 属性类型.破招值, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39025,
    uid: 39025,
    装备名称: '寻踪觅宝·放夏卷',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 3257,
    武器伤害_最大值: 5428,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.根骨, 值: 1129 },
      { 属性: 属性类型.内功基础攻击, 值: 6277 },
      { 属性: 属性类型.破招值, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39024,
    uid: 39024,
    装备名称: '寻踪觅宝·朗月灯',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 3257,
    武器伤害_最大值: 5428,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.元气, 值: 1129 },
      { 属性: 属性类型.内功基础攻击, 值: 6277 },
      { 属性: 属性类型.破招值, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39019,
    uid: 39019,
    装备名称: '寻踪觅宝·衔书琴',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 1628,
    武器伤害_最大值: 2714,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.根骨, 值: 1129 },
      { 属性: 属性类型.内功基础攻击, 值: 6277 },
      { 属性: 属性类型.破招值, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39015,
    uid: 39015,
    装备名称: '寻踪觅宝·蒲陶双刀',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 3257,
    武器伤害_最大值: 5428,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.元气, 值: 1129 },
      { 属性: 属性类型.内功基础攻击, 值: 6277 },
      { 属性: 属性类型.破招值, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39010,
    uid: 39010,
    装备名称: '寻踪觅宝·竹色弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 3257,
    武器伤害_最大值: 5428,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.元气, 值: 1129 },
      { 属性: 属性类型.内功基础攻击, 值: 6277 },
      { 属性: 属性类型.破招值, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39008,
    uid: 39008,
    装备名称: '寻踪觅宝·碧华笛',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 1628,
    武器伤害_最大值: 2714,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.根骨, 值: 1129 },
      { 属性: 属性类型.内功基础攻击, 值: 6277 },
      { 属性: 属性类型.破招值, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39006,
    uid: 39006,
    装备名称: '寻踪觅宝·楹铭双剑',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 2117,
    武器伤害_最大值: 3528,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.根骨, 值: 1129 },
      { 属性: 属性类型.内功基础攻击, 值: 6277 },
      { 属性: 属性类型.破招值, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39004,
    uid: 39004,
    装备名称: '寻踪觅宝·北阙剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 2117,
    武器伤害_最大值: 3528,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.根骨, 值: 1129 },
      { 属性: 属性类型.内功基础攻击, 值: 6277 },
      { 属性: 属性类型.破招值, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39000,
    uid: 39000,
    装备名称: '寻踪觅宝·丹篆笔',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 1628,
    武器伤害_最大值: 2714,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.元气, 值: 1129 },
      { 属性: 属性类型.内功基础攻击, 值: 6277 },
      { 属性: 属性类型.破招值, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38998,
    uid: 38998,
    装备名称: '寻踪觅宝·丰灵棍',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 5536,
    武器伤害_最大值: 9227,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.元气, 值: 1129 },
      { 属性: 属性类型.内功基础攻击, 值: 6277 },
      { 属性: 属性类型.破招值, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38997,
    uid: 38997,
    装备名称: '玄壁扇',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 3257,
    武器伤害_最大值: 5428,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.元气, 值: 1129 },
      { 属性: 属性类型.内功基础攻击, 值: 6277 },
      { 属性: 属性类型.内功会心等级, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 38993,
    uid: 38993,
    装备名称: '踏壁卷',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 3257,
    武器伤害_最大值: 5428,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.根骨, 值: 1129 },
      { 属性: 属性类型.内功基础攻击, 值: 6277 },
      { 属性: 属性类型.内功会心等级, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 38992,
    uid: 38992,
    装备名称: '合壁灯',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 3257,
    武器伤害_最大值: 5428,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.元气, 值: 1129 },
      { 属性: 属性类型.内功基础攻击, 值: 6277 },
      { 属性: 属性类型.内功会心等级, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 38987,
    uid: 38987,
    装备名称: '东壁琴',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 1628,
    武器伤害_最大值: 2714,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.根骨, 值: 1129 },
      { 属性: 属性类型.内功基础攻击, 值: 6277 },
      { 属性: 属性类型.内功会心等级, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 38983,
    uid: 38983,
    装备名称: '壁语双刀',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 3257,
    武器伤害_最大值: 5428,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.元气, 值: 1129 },
      { 属性: 属性类型.内功基础攻击, 值: 6277 },
      { 属性: 属性类型.内功会心等级, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 38978,
    uid: 38978,
    装备名称: '影壁弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 3257,
    武器伤害_最大值: 5428,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.元气, 值: 1129 },
      { 属性: 属性类型.内功基础攻击, 值: 6277 },
      { 属性: 属性类型.外功会心等级, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 38976,
    uid: 38976,
    装备名称: '尺壁笛',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 1628,
    武器伤害_最大值: 2714,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.根骨, 值: 1129 },
      { 属性: 属性类型.内功基础攻击, 值: 6277 },
      { 属性: 属性类型.内功会心等级, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 38974,
    uid: 38974,
    装备名称: '霞壁双剑',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 2117,
    武器伤害_最大值: 3528,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.根骨, 值: 1129 },
      { 属性: 属性类型.内功基础攻击, 值: 6277 },
      { 属性: 属性类型.内功会心等级, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 38972,
    uid: 38972,
    装备名称: '壁云剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 2117,
    武器伤害_最大值: 3528,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.根骨, 值: 1129 },
      { 属性: 属性类型.内功基础攻击, 值: 6277 },
      { 属性: 属性类型.内功会心等级, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 38968,
    uid: 38968,
    装备名称: '壁泉笔',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 1628,
    武器伤害_最大值: 2714,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.元气, 值: 1129 },
      { 属性: 属性类型.内功基础攻击, 值: 6277 },
      { 属性: 属性类型.内功会心等级, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 38966,
    uid: 38966,
    装备名称: '悬壁棍',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 5536,
    武器伤害_最大值: 9227,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.元气, 值: 1129 },
      { 属性: 属性类型.内功基础攻击, 值: 6277 },
      { 属性: 属性类型.内功会心等级, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 40960,
    uid: 40960,
    装备名称: '映泫扇',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功会心等级 },
    ],
  },
  {
    id: 40957,
    uid: 40957,
    装备名称: '采蘩卷',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功会心等级 },
    ],
  },
  {
    id: 40956,
    uid: 40956,
    装备名称: '月皓灯',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功会心等级 },
    ],
  },
  {
    id: 40952,
    uid: 40952,
    装备名称: '归言琴',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 1590,
    武器伤害_最大值: 2650,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功会心等级 },
    ],
  },
  {
    id: 40948,
    uid: 40948,
    装备名称: '扬砂双刀',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功会心等级 },
    ],
  },
  {
    id: 40943,
    uid: 40943,
    装备名称: '越塘弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.外功会心等级 },
    ],
  },
  {
    id: 40942,
    uid: 40942,
    装备名称: '露行笛',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 1590,
    武器伤害_最大值: 2650,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功会心等级 },
    ],
  },
  {
    id: 40941,
    uid: 40941,
    装备名称: '增冰双剑',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 2066,
    武器伤害_最大值: 3444,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功会心等级 },
    ],
  },
  {
    id: 40939,
    uid: 40939,
    装备名称: '禄观剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 2066,
    武器伤害_最大值: 3444,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功会心等级 },
    ],
  },
  {
    id: 40936,
    uid: 40936,
    装备名称: '停华笔',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 1590,
    武器伤害_最大值: 2650,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功会心等级 },
    ],
  },
  {
    id: 40934,
    uid: 40934,
    装备名称: '观心棍',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 5405,
    武器伤害_最大值: 9008,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功会心等级 },
    ],
  },
  {
    id: 40914,
    uid: 40914,
    装备名称: '容雾扇',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40910,
    uid: 40910,
    装备名称: '涤川卷',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40909,
    uid: 40909,
    装备名称: '转月灯',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40904,
    uid: 40904,
    装备名称: '兰泽琴',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 1590,
    武器伤害_最大值: 2650,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40902,
    uid: 40902,
    装备名称: '振野双刀',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40897,
    uid: 40897,
    装备名称: '半岩弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40895,
    uid: 40895,
    装备名称: '垂影笛',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 1590,
    武器伤害_最大值: 2650,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40893,
    uid: 40893,
    装备名称: '兰沚双剑',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 2066,
    武器伤害_最大值: 3444,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40891,
    uid: 40891,
    装备名称: '千霄剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 2066,
    武器伤害_最大值: 3444,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40888,
    uid: 40888,
    装备名称: '聿云笔',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 1590,
    武器伤害_最大值: 2650,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40887,
    uid: 40887,
    装备名称: '真妄棍',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 5405,
    武器伤害_最大值: 9008,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40613,
    uid: 40613,
    装备名称: '汎舟扇',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 40609,
    uid: 40609,
    装备名称: '长榆卷',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 40608,
    uid: 40608,
    装备名称: '朔望灯',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 40603,
    uid: 40603,
    装备名称: '丹镜琴',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 1590,
    武器伤害_最大值: 2650,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 40599,
    uid: 40599,
    装备名称: '沙棠双刀',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 40594,
    uid: 40594,
    装备名称: '斜川弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 40592,
    uid: 40592,
    装备名称: '冰泉笛',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 1590,
    武器伤害_最大值: 2650,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 40590,
    uid: 40590,
    装备名称: '绮朝双剑',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 2066,
    武器伤害_最大值: 3444,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 40588,
    uid: 40588,
    装备名称: '北第剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 2066,
    武器伤害_最大值: 3444,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 40584,
    uid: 40584,
    装备名称: '兴悲笔',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 1590,
    武器伤害_最大值: 2650,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 40582,
    uid: 40582,
    装备名称: '寂灭棍',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 5405,
    武器伤害_最大值: 9008,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 38959,
    uid: 38959,
    装备名称: '映泫扇',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功会心等级 },
    ],
  },
  {
    id: 38955,
    uid: 38955,
    装备名称: '采蘩卷',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功会心等级 },
    ],
  },
  {
    id: 38954,
    uid: 38954,
    装备名称: '月皓灯',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功会心等级 },
    ],
  },
  {
    id: 38949,
    uid: 38949,
    装备名称: '归言琴',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 1590,
    武器伤害_最大值: 2650,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功会心等级 },
    ],
  },
  {
    id: 38945,
    uid: 38945,
    装备名称: '扬砂双刀',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功会心等级 },
    ],
  },
  {
    id: 38940,
    uid: 38940,
    装备名称: '越塘弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.外功会心等级 },
    ],
  },
  {
    id: 38938,
    uid: 38938,
    装备名称: '露行笛',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 1590,
    武器伤害_最大值: 2650,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功会心等级 },
    ],
  },
  {
    id: 38936,
    uid: 38936,
    装备名称: '增冰双剑',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 2066,
    武器伤害_最大值: 3444,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功会心等级 },
    ],
  },
  {
    id: 38934,
    uid: 38934,
    装备名称: '禄观剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 2066,
    武器伤害_最大值: 3444,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功会心等级 },
    ],
  },
  {
    id: 38930,
    uid: 38930,
    装备名称: '停华笔',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 1590,
    武器伤害_最大值: 2650,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功会心等级 },
    ],
  },
  {
    id: 38928,
    uid: 38928,
    装备名称: '观心棍',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 5405,
    武器伤害_最大值: 9008,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功会心等级 },
    ],
  },
  {
    id: 38921,
    uid: 38921,
    装备名称: '汎舟扇',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 38917,
    uid: 38917,
    装备名称: '长榆卷',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 38916,
    uid: 38916,
    装备名称: '朔望灯',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 38911,
    uid: 38911,
    装备名称: '丹镜琴',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 1590,
    武器伤害_最大值: 2650,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 38907,
    uid: 38907,
    装备名称: '沙棠双刀',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 38902,
    uid: 38902,
    装备名称: '斜川弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 38900,
    uid: 38900,
    装备名称: '冰泉笛',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 1590,
    武器伤害_最大值: 2650,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 38898,
    uid: 38898,
    装备名称: '绮朝双剑',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 2066,
    武器伤害_最大值: 3444,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 38896,
    uid: 38896,
    装备名称: '北第剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 2066,
    武器伤害_最大值: 3444,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 38892,
    uid: 38892,
    装备名称: '兴悲笔',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 1590,
    武器伤害_最大值: 2650,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 38890,
    uid: 38890,
    装备名称: '寂灭棍',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 5405,
    武器伤害_最大值: 9008,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 38883,
    uid: 38883,
    装备名称: '容雾扇',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38879,
    uid: 38879,
    装备名称: '涤川卷',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38878,
    uid: 38878,
    装备名称: '转月灯',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38873,
    uid: 38873,
    装备名称: '兰泽琴',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 1590,
    武器伤害_最大值: 2650,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38869,
    uid: 38869,
    装备名称: '振野双刀',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38864,
    uid: 38864,
    装备名称: '半岩弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38862,
    uid: 38862,
    装备名称: '垂影笛',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 1590,
    武器伤害_最大值: 2650,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38860,
    uid: 38860,
    装备名称: '兰沚双剑',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 2066,
    武器伤害_最大值: 3444,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38858,
    uid: 38858,
    装备名称: '千霄剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 2066,
    武器伤害_最大值: 3444,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38854,
    uid: 38854,
    装备名称: '聿云笔',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 1590,
    武器伤害_最大值: 2650,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38852,
    uid: 38852,
    装备名称: '真妄棍',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 5405,
    武器伤害_最大值: 9008,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38821,
    uid: 38821,
    装备名称: '棠月扇',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1023 },
      { 属性: 属性类型.内功基础攻击, 值: 5200 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38817,
    uid: 38817,
    装备名称: '半明卷',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1023 },
      { 属性: 属性类型.内功基础攻击, 值: 5200 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38816,
    uid: 38816,
    装备名称: '疏光灯',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1023 },
      { 属性: 属性类型.内功基础攻击, 值: 5200 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38811,
    uid: 38811,
    装备名称: '落愁琴',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 1590,
    武器伤害_最大值: 2650,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1023 },
      { 属性: 属性类型.内功基础攻击, 值: 5200 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38807,
    uid: 38807,
    装备名称: '西逾双刀',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1023 },
      { 属性: 属性类型.内功基础攻击, 值: 5200 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38802,
    uid: 38802,
    装备名称: '锁潇弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1023 },
      { 属性: 属性类型.内功基础攻击, 值: 5200 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38800,
    uid: 38800,
    装备名称: '迟花虫笛',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 1590,
    武器伤害_最大值: 2650,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1023 },
      { 属性: 属性类型.内功基础攻击, 值: 5200 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38798,
    uid: 38798,
    装备名称: '遥簪双剑',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 2066,
    武器伤害_最大值: 3444,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1023 },
      { 属性: 属性类型.内功基础攻击, 值: 5200 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38796,
    uid: 38796,
    装备名称: '云散剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 2066,
    武器伤害_最大值: 3444,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1023 },
      { 属性: 属性类型.内功基础攻击, 值: 5200 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38792,
    uid: 38792,
    装备名称: '墨云笔',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 1590,
    武器伤害_最大值: 2650,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1023 },
      { 属性: 属性类型.内功基础攻击, 值: 5200 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38790,
    uid: 38790,
    装备名称: '残语棍',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 5405,
    武器伤害_最大值: 9008,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1023 },
      { 属性: 属性类型.内功基础攻击, 值: 5200 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38783,
    uid: 38783,
    装备名称: '枕凉扇',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1023 },
      { 属性: 属性类型.内功基础攻击, 值: 5200 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38779,
    uid: 38779,
    装备名称: '皎影卷',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1023 },
      { 属性: 属性类型.内功基础攻击, 值: 5200 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38778,
    uid: 38778,
    装备名称: '塞渊灯',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1023 },
      { 属性: 属性类型.内功基础攻击, 值: 5200 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38773,
    uid: 38773,
    装备名称: '拥春琴',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 1590,
    武器伤害_最大值: 2650,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1023 },
      { 属性: 属性类型.内功基础攻击, 值: 5200 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38769,
    uid: 38769,
    装备名称: '远碧双刀',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1023 },
      { 属性: 属性类型.内功基础攻击, 值: 5200 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38764,
    uid: 38764,
    装备名称: '桑枝弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1023 },
      { 属性: 属性类型.内功基础攻击, 值: 5200 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38762,
    uid: 38762,
    装备名称: '缘水虫笛',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 1590,
    武器伤害_最大值: 2650,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1023 },
      { 属性: 属性类型.内功基础攻击, 值: 5200 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38760,
    uid: 38760,
    装备名称: '莺振双剑',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 2066,
    武器伤害_最大值: 3444,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1023 },
      { 属性: 属性类型.内功基础攻击, 值: 5200 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38758,
    uid: 38758,
    装备名称: '残棋剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 2066,
    武器伤害_最大值: 3444,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1023 },
      { 属性: 属性类型.内功基础攻击, 值: 5200 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38754,
    uid: 38754,
    装备名称: '意枝笔',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 1590,
    武器伤害_最大值: 2650,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1023 },
      { 属性: 属性类型.内功基础攻击, 值: 5200 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38752,
    uid: 38752,
    装备名称: '数刹棍',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 5405,
    武器伤害_最大值: 9008,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1023 },
      { 属性: 属性类型.内功基础攻击, 值: 5200 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 39469,
    uid: 39469,
    装备名称: '风翎扇·丝路',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 19800,
    武器伤害_最小值: 3071,
    武器伤害_最大值: 5118,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13044 },
      { 属性: 属性类型.元气, 值: 1064 },
      { 属性: 属性类型.内功基础攻击, 值: 5918 },
      { 属性: 属性类型.内功破防等级, 值: 8894 },
      { 属性: 属性类型.无双等级, 值: 8597 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39465,
    uid: 39465,
    装备名称: '长白卷·内·丝路',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 19800,
    武器伤害_最小值: 3071,
    武器伤害_最大值: 5118,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13044 },
      { 属性: 属性类型.根骨, 值: 1064 },
      { 属性: 属性类型.内功基础攻击, 值: 5918 },
      { 属性: 属性类型.内功破防等级, 值: 8894 },
      { 属性: 属性类型.无双等级, 值: 8597 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39464,
    uid: 39464,
    装备名称: '循天魂灯·丝路',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 19800,
    武器伤害_最小值: 3071,
    武器伤害_最大值: 5118,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13044 },
      { 属性: 属性类型.元气, 值: 1064 },
      { 属性: 属性类型.内功基础攻击, 值: 5918 },
      { 属性: 属性类型.内功破防等级, 值: 8894 },
      { 属性: 属性类型.无双等级, 值: 8597 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39459,
    uid: 39459,
    装备名称: '儒风琴·内·丝路',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 19800,
    武器伤害_最小值: 1535,
    武器伤害_最大值: 2559,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13044 },
      { 属性: 属性类型.根骨, 值: 1064 },
      { 属性: 属性类型.内功基础攻击, 值: 5918 },
      { 属性: 属性类型.内功破防等级, 值: 8894 },
      { 属性: 属性类型.无双等级, 值: 8597 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39455,
    uid: 39455,
    装备名称: '日月双刀·内·丝路',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 19800,
    武器伤害_最小值: 3071,
    武器伤害_最大值: 5118,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13044 },
      { 属性: 属性类型.元气, 值: 1064 },
      { 属性: 属性类型.内功基础攻击, 值: 5918 },
      { 属性: 属性类型.内功破防等级, 值: 8894 },
      { 属性: 属性类型.无双等级, 值: 8597 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39450,
    uid: 39450,
    装备名称: '千机弩·内·丝路',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 19800,
    武器伤害_最小值: 3071,
    武器伤害_最大值: 5118,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13044 },
      { 属性: 属性类型.元气, 值: 1064 },
      { 属性: 属性类型.内功基础攻击, 值: 5918 },
      { 属性: 属性类型.内功破防等级, 值: 8894 },
      { 属性: 属性类型.无双等级, 值: 8597 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39448,
    uid: 39448,
    装备名称: '千蛊笛·内·丝路',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 19800,
    武器伤害_最小值: 1535,
    武器伤害_最大值: 2559,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13044 },
      { 属性: 属性类型.根骨, 值: 1064 },
      { 属性: 属性类型.内功基础攻击, 值: 5918 },
      { 属性: 属性类型.内功破防等级, 值: 8894 },
      { 属性: 属性类型.无双等级, 值: 8597 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39446,
    uid: 39446,
    装备名称: '妙若双剑·内·丝路',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 19800,
    武器伤害_最小值: 1996,
    武器伤害_最大值: 3327,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13044 },
      { 属性: 属性类型.根骨, 值: 1064 },
      { 属性: 属性类型.内功基础攻击, 值: 5918 },
      { 属性: 属性类型.内功破防等级, 值: 8894 },
      { 属性: 属性类型.无双等级, 值: 8597 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39444,
    uid: 39444,
    装备名称: '太清剑·内·断浪',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 19800,
    武器伤害_最小值: 1996,
    武器伤害_最大值: 3327,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13044 },
      { 属性: 属性类型.根骨, 值: 1064 },
      { 属性: 属性类型.内功基础攻击, 值: 5918 },
      { 属性: 属性类型.内功破防等级, 值: 8894 },
      { 属性: 属性类型.无双等级, 值: 8597 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39440,
    uid: 39440,
    装备名称: '青岩笔·内·丝路',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 19800,
    武器伤害_最小值: 1535,
    武器伤害_最大值: 2559,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13044 },
      { 属性: 属性类型.元气, 值: 1064 },
      { 属性: 属性类型.内功基础攻击, 值: 5918 },
      { 属性: 属性类型.内功破防等级, 值: 8894 },
      { 属性: 属性类型.无双等级, 值: 8597 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39438,
    uid: 39438,
    装备名称: '齐眉棍·内·丝路',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 19800,
    武器伤害_最小值: 5220,
    武器伤害_最大值: 8700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13044 },
      { 属性: 属性类型.元气, 值: 1064 },
      { 属性: 属性类型.内功基础攻击, 值: 5918 },
      { 属性: 属性类型.内功破防等级, 值: 8894 },
      { 属性: 属性类型.无双等级, 值: 8597 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38745,
    uid: 38745,
    装备名称: '羽凌扇',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 18800,
    武器伤害_最小值: 2915,
    武器伤害_最大值: 4859,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12385 },
      { 属性: 属性类型.元气, 值: 1010 },
      { 属性: 属性类型.内功基础攻击, 值: 5619 },
      { 属性: 属性类型.内功破防等级, 值: 8445 },
      { 属性: 属性类型.无双等级, 值: 8163 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38741,
    uid: 38741,
    装备名称: '凝翠卷',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 18800,
    武器伤害_最小值: 2915,
    武器伤害_最大值: 4859,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12385 },
      { 属性: 属性类型.根骨, 值: 1010 },
      { 属性: 属性类型.内功基础攻击, 值: 5619 },
      { 属性: 属性类型.内功破防等级, 值: 8445 },
      { 属性: 属性类型.无双等级, 值: 8163 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38740,
    uid: 38740,
    装备名称: '微暮灯',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 18800,
    武器伤害_最小值: 2915,
    武器伤害_最大值: 4859,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12385 },
      { 属性: 属性类型.元气, 值: 1010 },
      { 属性: 属性类型.内功基础攻击, 值: 5619 },
      { 属性: 属性类型.内功破防等级, 值: 8445 },
      { 属性: 属性类型.无双等级, 值: 8163 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38735,
    uid: 38735,
    装备名称: '涤心琴',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 18800,
    武器伤害_最小值: 1458,
    武器伤害_最大值: 2430,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12385 },
      { 属性: 属性类型.根骨, 值: 1010 },
      { 属性: 属性类型.内功基础攻击, 值: 5619 },
      { 属性: 属性类型.内功破防等级, 值: 8445 },
      { 属性: 属性类型.无双等级, 值: 8163 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38731,
    uid: 38731,
    装备名称: '冷影双刀',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 18800,
    武器伤害_最小值: 2915,
    武器伤害_最大值: 4859,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12385 },
      { 属性: 属性类型.元气, 值: 1010 },
      { 属性: 属性类型.内功基础攻击, 值: 5619 },
      { 属性: 属性类型.内功破防等级, 值: 8445 },
      { 属性: 属性类型.无双等级, 值: 8163 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38726,
    uid: 38726,
    装备名称: '烁影弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 18800,
    武器伤害_最小值: 2915,
    武器伤害_最大值: 4859,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12385 },
      { 属性: 属性类型.元气, 值: 1010 },
      { 属性: 属性类型.内功基础攻击, 值: 5619 },
      { 属性: 属性类型.内功破防等级, 值: 8445 },
      { 属性: 属性类型.无双等级, 值: 8163 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38724,
    uid: 38724,
    装备名称: '暮霜虫笛',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 18800,
    武器伤害_最小值: 1458,
    武器伤害_最大值: 2430,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12385 },
      { 属性: 属性类型.根骨, 值: 1010 },
      { 属性: 属性类型.内功基础攻击, 值: 5619 },
      { 属性: 属性类型.内功破防等级, 值: 8445 },
      { 属性: 属性类型.无双等级, 值: 8163 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38722,
    uid: 38722,
    装备名称: '羽鸿双剑',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 18800,
    武器伤害_最小值: 1895,
    武器伤害_最大值: 3158,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12385 },
      { 属性: 属性类型.根骨, 值: 1010 },
      { 属性: 属性类型.内功基础攻击, 值: 5619 },
      { 属性: 属性类型.内功破防等级, 值: 8445 },
      { 属性: 属性类型.无双等级, 值: 8163 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38720,
    uid: 38720,
    装备名称: '云筑剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 18800,
    武器伤害_最小值: 1895,
    武器伤害_最大值: 3158,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12385 },
      { 属性: 属性类型.根骨, 值: 1010 },
      { 属性: 属性类型.内功基础攻击, 值: 5619 },
      { 属性: 属性类型.内功破防等级, 值: 8445 },
      { 属性: 属性类型.无双等级, 值: 8163 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38716,
    uid: 38716,
    装备名称: '澈初笔',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 18800,
    武器伤害_最小值: 1458,
    武器伤害_最大值: 2430,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12385 },
      { 属性: 属性类型.元气, 值: 1010 },
      { 属性: 属性类型.内功基础攻击, 值: 5619 },
      { 属性: 属性类型.内功破防等级, 值: 8445 },
      { 属性: 属性类型.无双等级, 值: 8163 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38714,
    uid: 38714,
    装备名称: '浩天棍',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 18800,
    武器伤害_最小值: 4956,
    武器伤害_最大值: 8260,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12385 },
      { 属性: 属性类型.元气, 值: 1010 },
      { 属性: 属性类型.内功基础攻击, 值: 5619 },
      { 属性: 属性类型.内功破防等级, 值: 8445 },
      { 属性: 属性类型.无双等级, 值: 8163 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38707,
    uid: 38707,
    装备名称: '霜华扇',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 17600,
    武器伤害_最小值: 2729,
    武器伤害_最大值: 4549,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11595 },
      { 属性: 属性类型.元气, 值: 946 },
      { 属性: 属性类型.内功基础攻击, 值: 5261 },
      { 属性: 属性类型.内功破防等级, 值: 7906 },
      { 属性: 属性类型.无双等级, 值: 6324 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38703,
    uid: 38703,
    装备名称: '葭苇卷',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 17600,
    武器伤害_最小值: 2729,
    武器伤害_最大值: 4549,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11595 },
      { 属性: 属性类型.根骨, 值: 946 },
      { 属性: 属性类型.内功基础攻击, 值: 5261 },
      { 属性: 属性类型.内功破防等级, 值: 7906 },
      { 属性: 属性类型.无双等级, 值: 6324 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38702,
    uid: 38702,
    装备名称: '薇玖灯',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 17600,
    武器伤害_最小值: 2729,
    武器伤害_最大值: 4549,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11595 },
      { 属性: 属性类型.元气, 值: 946 },
      { 属性: 属性类型.内功基础攻击, 值: 5261 },
      { 属性: 属性类型.内功破防等级, 值: 7906 },
      { 属性: 属性类型.无双等级, 值: 6324 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38697,
    uid: 38697,
    装备名称: '落霞琴',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 17600,
    武器伤害_最小值: 1365,
    武器伤害_最大值: 2275,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11595 },
      { 属性: 属性类型.根骨, 值: 946 },
      { 属性: 属性类型.内功基础攻击, 值: 5261 },
      { 属性: 属性类型.内功破防等级, 值: 7906 },
      { 属性: 属性类型.无双等级, 值: 6324 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38693,
    uid: 38693,
    装备名称: '渊阔双刀',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 17600,
    武器伤害_最小值: 2729,
    武器伤害_最大值: 4549,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11595 },
      { 属性: 属性类型.元气, 值: 946 },
      { 属性: 属性类型.内功基础攻击, 值: 5261 },
      { 属性: 属性类型.内功破防等级, 值: 7906 },
      { 属性: 属性类型.无双等级, 值: 6324 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38688,
    uid: 38688,
    装备名称: '蕴真弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 17600,
    武器伤害_最小值: 2729,
    武器伤害_最大值: 4549,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11595 },
      { 属性: 属性类型.元气, 值: 946 },
      { 属性: 属性类型.内功基础攻击, 值: 5261 },
      { 属性: 属性类型.内功破防等级, 值: 7906 },
      { 属性: 属性类型.无双等级, 值: 6324 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38686,
    uid: 38686,
    装备名称: '澜静虫笛',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 17600,
    武器伤害_最小值: 1365,
    武器伤害_最大值: 2275,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11595 },
      { 属性: 属性类型.根骨, 值: 946 },
      { 属性: 属性类型.内功基础攻击, 值: 5261 },
      { 属性: 属性类型.内功破防等级, 值: 7906 },
      { 属性: 属性类型.无双等级, 值: 6324 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38684,
    uid: 38684,
    装备名称: '芸溪双剑',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 17600,
    武器伤害_最小值: 1774,
    武器伤害_最大值: 2957,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11595 },
      { 属性: 属性类型.根骨, 值: 946 },
      { 属性: 属性类型.内功基础攻击, 值: 5261 },
      { 属性: 属性类型.内功破防等级, 值: 7906 },
      { 属性: 属性类型.无双等级, 值: 6324 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38682,
    uid: 38682,
    装备名称: '镜明剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 17600,
    武器伤害_最小值: 1774,
    武器伤害_最大值: 2957,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11595 },
      { 属性: 属性类型.根骨, 值: 946 },
      { 属性: 属性类型.内功基础攻击, 值: 5261 },
      { 属性: 属性类型.内功破防等级, 值: 7906 },
      { 属性: 属性类型.无双等级, 值: 6324 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38678,
    uid: 38678,
    装备名称: '澜月笔',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 17600,
    武器伤害_最小值: 1365,
    武器伤害_最大值: 2275,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11595 },
      { 属性: 属性类型.元气, 值: 946 },
      { 属性: 属性类型.内功基础攻击, 值: 5261 },
      { 属性: 属性类型.内功破防等级, 值: 7906 },
      { 属性: 属性类型.无双等级, 值: 6324 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38676,
    uid: 38676,
    装备名称: '泓渟棍',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 17600,
    武器伤害_最小值: 4640,
    武器伤害_最大值: 7733,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11595 },
      { 属性: 属性类型.元气, 值: 946 },
      { 属性: 属性类型.内功基础攻击, 值: 5261 },
      { 属性: 属性类型.内功破防等级, 值: 7906 },
      { 属性: 属性类型.无双等级, 值: 6324 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39507,
    uid: 39507,
    装备名称: '幽寻扇',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 16500,
    武器伤害_最小值: 2559,
    武器伤害_最大值: 4265,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10870 },
      { 属性: 属性类型.元气, 值: 887 },
      { 属性: 属性类型.内功基础攻击, 值: 4932 },
      { 属性: 属性类型.破招值, 值: 7412 },
      { 属性: 属性类型.无双等级, 值: 7164 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39503,
    uid: 39503,
    装备名称: '洲萦卷',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 16500,
    武器伤害_最小值: 2559,
    武器伤害_最大值: 4265,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10870 },
      { 属性: 属性类型.根骨, 值: 887 },
      { 属性: 属性类型.内功基础攻击, 值: 4932 },
      { 属性: 属性类型.破招值, 值: 7412 },
      { 属性: 属性类型.无双等级, 值: 7164 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39502,
    uid: 39502,
    装备名称: '霄寂魂灯',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 16500,
    武器伤害_最小值: 2559,
    武器伤害_最大值: 4265,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10870 },
      { 属性: 属性类型.元气, 值: 887 },
      { 属性: 属性类型.内功基础攻击, 值: 4932 },
      { 属性: 属性类型.破招值, 值: 7412 },
      { 属性: 属性类型.无双等级, 值: 7164 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39497,
    uid: 39497,
    装备名称: '徂物琴',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 16500,
    武器伤害_最小值: 1279,
    武器伤害_最大值: 2132,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10870 },
      { 属性: 属性类型.根骨, 值: 887 },
      { 属性: 属性类型.内功基础攻击, 值: 4932 },
      { 属性: 属性类型.破招值, 值: 7412 },
      { 属性: 属性类型.无双等级, 值: 7164 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39493,
    uid: 39493,
    装备名称: '谅世双刀',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 16500,
    武器伤害_最小值: 2559,
    武器伤害_最大值: 4265,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10870 },
      { 属性: 属性类型.元气, 值: 887 },
      { 属性: 属性类型.内功基础攻击, 值: 4932 },
      { 属性: 属性类型.破招值, 值: 7412 },
      { 属性: 属性类型.无双等级, 值: 7164 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39488,
    uid: 39488,
    装备名称: '穷音弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 16500,
    武器伤害_最小值: 2559,
    武器伤害_最大值: 4265,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10870 },
      { 属性: 属性类型.元气, 值: 887 },
      { 属性: 属性类型.内功基础攻击, 值: 4932 },
      { 属性: 属性类型.破招值, 值: 7412 },
      { 属性: 属性类型.无双等级, 值: 7164 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39486,
    uid: 39486,
    装备名称: '弃世笛',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 16500,
    武器伤害_最小值: 1279,
    武器伤害_最大值: 2132,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10870 },
      { 属性: 属性类型.根骨, 值: 887 },
      { 属性: 属性类型.内功基础攻击, 值: 4932 },
      { 属性: 属性类型.破招值, 值: 7412 },
      { 属性: 属性类型.无双等级, 值: 7164 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39484,
    uid: 39484,
    装备名称: '离声双剑',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 16500,
    武器伤害_最小值: 1663,
    武器伤害_最大值: 2772,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10870 },
      { 属性: 属性类型.根骨, 值: 887 },
      { 属性: 属性类型.内功基础攻击, 值: 4932 },
      { 属性: 属性类型.破招值, 值: 7412 },
      { 属性: 属性类型.无双等级, 值: 7164 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39482,
    uid: 39482,
    装备名称: '息阴剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 16500,
    武器伤害_最小值: 1663,
    武器伤害_最大值: 2772,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10870 },
      { 属性: 属性类型.根骨, 值: 887 },
      { 属性: 属性类型.内功基础攻击, 值: 4932 },
      { 属性: 属性类型.破招值, 值: 7412 },
      { 属性: 属性类型.无双等级, 值: 7164 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39478,
    uid: 39478,
    装备名称: '绝心笔',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 16500,
    武器伤害_最小值: 1279,
    武器伤害_最大值: 2132,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10870 },
      { 属性: 属性类型.元气, 值: 887 },
      { 属性: 属性类型.内功基础攻击, 值: 4932 },
      { 属性: 属性类型.破招值, 值: 7412 },
      { 属性: 属性类型.无双等级, 值: 7164 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39476,
    uid: 39476,
    装备名称: '宵归棍',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 16500,
    武器伤害_最小值: 4350,
    武器伤害_最大值: 7250,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10870 },
      { 属性: 属性类型.元气, 值: 887 },
      { 属性: 属性类型.内功基础攻击, 值: 4932 },
      { 属性: 属性类型.破招值, 值: 7412 },
      { 属性: 属性类型.无双等级, 值: 7164 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38669,
    uid: 38669,
    装备名称: '澹泊扇',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 16500,
    武器伤害_最小值: 2559,
    武器伤害_最大值: 4265,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10870 },
      { 属性: 属性类型.元气, 值: 824 },
      { 属性: 属性类型.内功基础攻击, 值: 4185 },
      { 属性: 属性类型.内功会心等级, 值: 7412 },
      { 属性: 属性类型.全能等级, 值: 2965 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38665,
    uid: 38665,
    装备名称: '荼源卷',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 16500,
    武器伤害_最小值: 2559,
    武器伤害_最大值: 4265,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10870 },
      { 属性: 属性类型.根骨, 值: 824 },
      { 属性: 属性类型.内功基础攻击, 值: 4185 },
      { 属性: 属性类型.内功会心等级, 值: 7412 },
      { 属性: 属性类型.全能等级, 值: 2965 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38664,
    uid: 38664,
    装备名称: '烁樱灯',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 16500,
    武器伤害_最小值: 2559,
    武器伤害_最大值: 4265,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10870 },
      { 属性: 属性类型.元气, 值: 824 },
      { 属性: 属性类型.内功基础攻击, 值: 4185 },
      { 属性: 属性类型.内功会心等级, 值: 7412 },
      { 属性: 属性类型.全能等级, 值: 2965 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38659,
    uid: 38659,
    装备名称: '承露琴',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 16500,
    武器伤害_最小值: 1279,
    武器伤害_最大值: 2132,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10870 },
      { 属性: 属性类型.根骨, 值: 824 },
      { 属性: 属性类型.内功基础攻击, 值: 4185 },
      { 属性: 属性类型.内功会心等级, 值: 7412 },
      { 属性: 属性类型.全能等级, 值: 2965 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38655,
    uid: 38655,
    装备名称: '炎晖双刀',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 16500,
    武器伤害_最小值: 2559,
    武器伤害_最大值: 4265,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10870 },
      { 属性: 属性类型.元气, 值: 824 },
      { 属性: 属性类型.内功基础攻击, 值: 4185 },
      { 属性: 属性类型.内功会心等级, 值: 7412 },
      { 属性: 属性类型.全能等级, 值: 2965 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38650,
    uid: 38650,
    装备名称: '菁芜弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 16500,
    武器伤害_最小值: 2559,
    武器伤害_最大值: 4265,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10870 },
      { 属性: 属性类型.元气, 值: 824 },
      { 属性: 属性类型.内功基础攻击, 值: 4185 },
      { 属性: 属性类型.外功会心等级, 值: 7412 },
      { 属性: 属性类型.全能等级, 值: 2965 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38648,
    uid: 38648,
    装备名称: '泠玉虫笛',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 16500,
    武器伤害_最小值: 1279,
    武器伤害_最大值: 2132,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10870 },
      { 属性: 属性类型.根骨, 值: 824 },
      { 属性: 属性类型.内功基础攻击, 值: 4185 },
      { 属性: 属性类型.内功会心等级, 值: 7412 },
      { 属性: 属性类型.全能等级, 值: 2965 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38646,
    uid: 38646,
    装备名称: '黛凝双剑',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 16500,
    武器伤害_最小值: 1663,
    武器伤害_最大值: 2772,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10870 },
      { 属性: 属性类型.根骨, 值: 824 },
      { 属性: 属性类型.内功基础攻击, 值: 4185 },
      { 属性: 属性类型.内功会心等级, 值: 7412 },
      { 属性: 属性类型.全能等级, 值: 2965 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38644,
    uid: 38644,
    装备名称: '觅薇剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 16500,
    武器伤害_最小值: 1663,
    武器伤害_最大值: 2772,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10870 },
      { 属性: 属性类型.根骨, 值: 824 },
      { 属性: 属性类型.内功基础攻击, 值: 4185 },
      { 属性: 属性类型.内功会心等级, 值: 7412 },
      { 属性: 属性类型.全能等级, 值: 2965 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38640,
    uid: 38640,
    装备名称: '霁华笔',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 16500,
    武器伤害_最小值: 1279,
    武器伤害_最大值: 2132,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10870 },
      { 属性: 属性类型.元气, 值: 824 },
      { 属性: 属性类型.内功基础攻击, 值: 4185 },
      { 属性: 属性类型.内功会心等级, 值: 7412 },
      { 属性: 属性类型.全能等级, 值: 2965 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38638,
    uid: 38638,
    装备名称: '溪渊棍',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 16500,
    武器伤害_最小值: 4350,
    武器伤害_最大值: 7250,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10870 },
      { 属性: 属性类型.元气, 值: 824 },
      { 属性: 属性类型.内功基础攻击, 值: 4185 },
      { 属性: 属性类型.内功会心等级, 值: 7412 },
      { 属性: 属性类型.全能等级, 值: 2965 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 40557,
    uid: 40557,
    装备名称: '停湖雀',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 16000,
    武器伤害_最小值: 3446,
    武器伤害_最大值: 5743,
    装备特效: 装备特效枚举.小橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14640 },
      { 属性: 属性类型.元气, 值: 1194 },
      { 属性: 属性类型.内功基础攻击, 值: 6642 },
      { 属性: 属性类型.内功会心等级, 值: 7320 },
      { 属性: 属性类型.内功破防等级, 值: 7320 },
      { 属性: 属性类型.加速等级, 值: 3327 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
]

export default 武器装备数据
