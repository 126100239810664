import { 技能基础数据模型 } from '@/@types/技能'
import { 获取破招实际系数 } from '@/数据/数据工具/获取技能系数'

import 通用增益 from './技能增益/通用增益/通用增益'
import 普攻增益 from './技能增益/普攻'
import 韦陀献杵增益 from './技能增益/韦陀献杵'
import 拿云式增益 from './技能增益/拿云式'
import 守缺式增益 from './技能增益/守缺式'
import 捕风式增益 from './技能增益/捕风式'
import 普渡四方增益 from './技能增益/普渡四方'
import 横扫六合增益 from './技能增益/横扫六合'
import 通用武伤增益 from './技能增益/通用增益/通用武伤'
import 真实伤害系数 from '@/心法模块/统一数据/真实伤害系数'

const 技能基础数据: 技能基础数据模型[] = [
  {
    技能ID: '32887',
    技能名称: '破',
    统计名称: '破',
    技能伤害系数: 获取破招实际系数(1048576 * (0.654075 * 1.4 - 1)),
    技能增益列表: 通用增益,
    技能等级数据: {
      1: { 技能伤害系数: 获取破招实际系数(1048576 * (0.654075 * 1.4 - 1)) },
      2: { 技能伤害系数: 获取破招实际系数(1048576 * (0.654075 * 1.4 - 1)) },
    },
  },
  {
    技能ID: '11',
    技能名称: '六合棍',
    伤害类型: '外功',
    技能伤害系数: 27,
    武器伤害系数: 1,
    技能增益列表: 普攻增益,
  },
  {
    技能ID: '3849',
    技能名称: '韦陀献杵·一',
    秘籍依赖技能: '韦陀献杵',
    统计名称: '韦陀献杵',
    技能伤害系数: 148.75091195580004,
    基础伤害_基础值: 60,
    技能增益列表: [...韦陀献杵增益],
  },
  {
    技能ID: '3850',
    技能名称: '韦陀献杵·二',
    秘籍依赖技能: '韦陀献杵',
    统计名称: '韦陀献杵',
    技能伤害系数: 297.50182391160007,
    基础伤害_基础值: 120,
    技能增益列表: [...韦陀献杵增益],
  },
  {
    技能ID: '3848',
    技能名称: '韦陀献杵·三',
    秘籍依赖技能: '韦陀献杵',
    统计名称: '韦陀献杵',
    技能伤害系数: 446.2527358674,
    基础伤害_基础值: 174,
    基础伤害_浮动值: 10,
    技能增益列表: [...韦陀献杵增益],
  },
  {
    技能ID: '19090_1',
    技能名称: '普渡四方·武伤',
    统计名称: '普渡四方',
    伤害类型: '外功',
    技能伤害系数: 16,
    武器伤害系数: 1,
    技能增益列表: 通用武伤增益,
  },
  {
    技能ID: '19090_2',
    技能名称: '韦陀献杵·武伤',
    统计名称: '韦陀献杵',
    伤害类型: '外功',
    技能伤害系数: 16,
    武器伤害系数: 2,
    技能增益列表: 通用武伤增益,
  },
  {
    技能ID: '19090_3',
    技能名称: '横扫六合·武伤',
    统计名称: '横扫六合',
    伤害类型: '外功',
    技能伤害系数: 16,
    武器伤害系数: 1,
    技能增益列表: 通用武伤增益,
  },
  {
    技能ID: '19090_4',
    技能名称: '摩诃无量·武伤',
    统计名称: '摩诃无量',
    伤害类型: '外功',
    技能伤害系数: 16,
    武器伤害系数: 1,
    技能增益列表: 通用武伤增益,
  },
  {
    技能ID: '236',
    技能名称: '摩诃无量',
    统计名称: '摩诃无量',
    技能伤害系数: 16,
    基础伤害_基础值: 22,
    基础伤害_浮动值: 2,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '271',
    技能名称: '降魔',
    统计名称: '降魔',
    秘籍依赖技能: '韦陀献杵',
    技能伤害系数: 111.56318396685,
    基础伤害_基础值: 6,
    技能增益列表: 韦陀献杵增益,
    // 技能增益列表: 通用增益,
    技能等级数据: {
      '1,8': {
        技能伤害系数: (i) =>
          [111.56318396685, 128.0, 36.8, 48.0, 132.69665784375002, 64.0, 64.0, 65.0]?.[i - 1],
        基础伤害_基础值: [0, 6, 11, 16, 21, 26, 31, 36, 26],
      },
    },
  },
  {
    技能ID: '3814',
    技能名称: '守缺式·二',
    秘籍依赖技能: '守缺式',
    统计名称: '守缺式',
    技能伤害系数: 459.712638,
    基础伤害_基础值: 142,
    基础伤害_浮动值: 5,
    技能增益列表: 守缺式增益,
  },
  {
    技能ID: '3816',
    技能名称: '守缺式·一',
    秘籍依赖技能: '守缺式',
    统计名称: '守缺式',
    技能伤害系数: 383.093865,
    基础伤害_基础值: 142,
    基础伤害_浮动值: 5,
    技能增益列表: 守缺式增益,
  },
  {
    技能ID: '13681',
    技能名称: '拿云一段高血量',
    统计名称: '拿云式',
    秘籍依赖技能: '拿云式',
    技能伤害系数: 175.56788576249997,
    基础伤害_基础值: 82,
    基础伤害_浮动值: 8,
    技能增益列表: 拿云式增益,
  },
  {
    技能ID: '13685',
    技能名称: '拿云三段高血量',
    统计名称: '拿云式',
    秘籍依赖技能: '拿云式',
    技能伤害系数: 530.7866313750001,
    基础伤害_基础值: 246,
    基础伤害_浮动值: 25,
    技能增益列表: 拿云式增益,
  },
  {
    技能ID: '36051',
    技能名称: '拿云三段低血量净果',
    统计名称: '拿云式',
    秘籍依赖技能: '拿云式',
    技能伤害系数: 610.40462608125,
    基础伤害_基础值: 246,
    基础伤害_浮动值: 25,
    技能增益列表: 拿云式增益,
  },
  {
    技能ID: '3810',
    技能名称: '横扫六合·200',
    秘籍依赖技能: '横扫六合',
    统计名称: '横扫六合',
    技能伤害系数: 320.21102334374996,
    基础伤害_基础值: 75,
    技能增益列表: 横扫六合增益,
  },
  {
    // 743_3810
    技能ID: '743',
    技能名称: '横扫六合(DOT)',
    秘籍依赖技能: '横扫六合',
    统计名称: '横扫六合(DOT)',
    DOT生效间隔: 32,
    DOT跳数: 6,
    技能伤害系数: 320.21102334374996,
    基础伤害_基础值: 45,
    技能增益列表: 通用增益,
  },
  {
    // 743_28539
    技能ID: '743_28539',
    技能名称: '横扫六合(DOT)·无执',
    秘籍依赖技能: '横扫六合',
    统计名称: '横扫六合(DOT)',
    DOT生效间隔: 32,
    DOT跳数: 6,
    技能伤害系数: 128.30895,
    基础伤害_基础值: 45,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '14951',
    技能名称: '捕风式',
    技能伤害系数: 139.46625,
    基础伤害_基础值: 60,
    基础伤害_浮动值: 6,
    技能增益列表: 捕风式增益,
  },
  {
    技能ID: '17641',
    技能名称: '普渡四方·一',
    秘籍依赖技能: '普渡四方',
    统计名称: '普渡四方',
    技能伤害系数: 246.863232,
    基础伤害_基础值: 156,
    基础伤害_浮动值: 15,
    技能增益列表: 普渡四方增益,
  },
  {
    技能ID: '17642',
    技能名称: '普渡四方·二',
    秘籍依赖技能: '普渡四方',
    统计名称: '普渡四方',
    技能伤害系数: 246.863232,
    基础伤害_基础值: 156,
    基础伤害_浮动值: 15,
    技能增益列表: 普渡四方增益,
  },
  {
    技能ID: '24028',
    技能名称: '佛果',
    技能伤害系数: 183.45600000000005,
    基础伤害_基础值: 77,
    基础伤害_浮动值: 101,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '24883',
    技能名称: '缩地',
    技能伤害系数: 480,
    基础伤害_基础值: 400,
    基础伤害_浮动值: 15,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '25766',
    技能名称: '守缺式·神兵',
    技能伤害系数: 50,
    基础伤害_浮动值: 2,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '28542',
    技能名称: '六合棍意',
    技能伤害系数: 119.6,
    基础伤害_基础值: 36,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '28619',
    技能名称: '千斤坠',
    技能伤害系数: 50,
    基础伤害_基础值: 28,
    基础伤害_浮动值: 3,
    技能增益列表: 通用增益,
    技能等级数据: {
      '1': {
        技能伤害系数: 50.0,
      },
      '2': {
        技能伤害系数: 75.0,
      },
    },
  },
  {
    技能ID: '29516',
    技能名称: '金刚日轮',
    技能伤害系数: 480,
    基础伤害_基础值: 28,
    基础伤害_浮动值: 3,
    技能增益列表: 通用增益,
  },
  { 技能ID: '32656', 技能名称: '金刚龙爪功', 技能伤害系数: 390, 技能增益列表: 通用增益 },
  { 技能ID: '32659', 技能名称: '果报守缺', 技能伤害系数: 2184, 技能增益列表: 通用增益 },
  {
    技能ID: '32660',
    技能名称: '果报横扫',
    技能伤害系数: 1820,
    基础伤害_基础值: 28,
    基础伤害_浮动值: 3,
    技能增益列表: 通用增益,
  },
  { 技能ID: '38615', 技能名称: '布泽', 技能伤害系数: 800, 技能增益列表: 通用增益 },
  // --------------
  ...真实伤害系数,
  {
    // 龙门武器
    技能名称: '剑风',
    基础伤害_基础值: 3950,
    技能增益列表: 通用增益,
  },
]

export default 技能基础数据

const 技能增益 = {
  通用增益,
}

export { 技能增益 }
