import { 技能基础数据模型 } from '@/@types/技能'

import 通用增益 from './技能增益/通用增益/通用增益'
import { 获取破招实际系数 } from '@/数据/数据工具/获取技能系数'
import 绝刀增益 from './技能增益/绝刀'
import 盾飞增益 from './技能增益/盾飞'
import 劫刀增益 from './技能增益/劫刀'
import 盾压增益 from './技能增益/盾压'
import 斩刀增益 from './技能增益/斩刀'
import 盾击增益 from './技能增益/盾击'
import { 属性类型 } from '@/@types/属性'
import 真实伤害系数 from '../../统一数据/真实伤害系数'

const 技能基础数据: 技能基础数据模型[] = [
  {
    技能ID: '32745',
    技能名称: '破',
    统计名称: '破',
    技能伤害系数: 获取破招实际系数(-629145.6),
    技能增益列表: 通用增益,
    技能等级数据: {
      1: { 技能伤害系数: 获取破招实际系数(-629145.6) },
      2: { 技能伤害系数: 获取破招实际系数(-854453.12512) },
      3: { 技能伤害系数: 获取破招实际系数(-314572.80000000005) },
      4: { 技能伤害系数: 获取破招实际系数(-766215.45472) },
      5: { 技能伤害系数: 获取破招实际系数(-209715.19999999995) },
      6: { 技能伤害系数: 获取破招实际系数(-104857.59999999998) },
      7: { 技能伤害系数: 获取破招实际系数(-815628.550144) },
      8: { 技能伤害系数: 获取破招实际系数(-709743.345664) },
      9: {
        技能伤害系数: 获取破招实际系数(-201494.36415999988),
        技能增益列表: [
          ...通用增益,
          {
            增益名称: '技能无视',
            增益启用: true,
            增益所在位置: '技能',
            增益类型: '全局启用',
            增益集合: [{ 属性: 属性类型.郭氏外功基础防御, 值: -1024 }],
          },
        ],
      },
    },
  },
  {
    技能ID: '37448',
    技能名称: '破·麟光',
    是否为破招伤害: true,
    技能伤害系数: 获取破招实际系数(-913513.5391744),
    技能增益列表: 通用增益,
  },
  {
    技能ID: '38890',
    技能名称: '破·绝刀惊涌',
    统计名称: '破',
    是否为破招伤害: true,
    技能伤害系数: 获取破招实际系数(-709743.345664),
    技能增益列表: [
      ...通用增益,
      {
        // 9889
        增益名称: '刀煞',
        增益所在位置: '奇穴',
        增益类型: '全局启用',
        增益集合: [{ 属性: 属性类型.郭氏外功基础防御, 值: -1024 }],
      },
    ],
    技能等级数据: {
      1: { 技能伤害系数: 获取破招实际系数(-709743.345664) },
      2: { 技能伤害系数: 获取破招实际系数(-641976.8147968) },
      3: { 技能伤害系数: 获取破招实际系数(-574210.2839295999) },
      4: { 技能伤害系数: 获取破招实际系数(-506443.7530623999) },
      5: { 技能伤害系数: 获取破招实际系数(-438677.2221951999) },
    },
  },
  {
    技能ID: '38889',
    技能名称: '破·绝刀',
    统计名称: '破',
    是否为破招伤害: true,
    技能伤害系数: 获取破招实际系数(-766215.45472),
    技能等级数据: {
      1: { 技能伤害系数: 获取破招实际系数(-766215.45472) },
      2: { 技能伤害系数: 获取破招实际系数(-709743.345664) },
      3: { 技能伤害系数: 获取破招实际系数(-653271.236608) },
      4: { 技能伤害系数: 获取破招实际系数(-596799.1275519999) },
      5: { 技能伤害系数: 获取破招实际系数(-540327.018496) },
    },
    技能增益列表: [
      ...通用增益,
      {
        // 9889
        增益名称: '刀煞',
        增益所在位置: '奇穴',
        增益类型: '全局启用',
        增益集合: [{ 属性: 属性类型.郭氏外功基础防御, 值: -1024 }],
      },
    ],
  },
  {
    技能ID: '38971',
    技能名称: '破·盾舞',
    统计名称: '破',
    是否为破招伤害: true,
    技能伤害系数: 获取破招实际系数(-914882.56),
    技能等级数据: {
      1: { 技能伤害系数: 获取破招实际系数(-914882.56) },
      2: { 技能伤害系数: 获取破招实际系数(-888143.872) },
    },
    技能增益列表: 通用增益,
  },
  {
    技能ID: '36065',
    技能名称: '击破·援戈',
    是否为破招伤害: true,
    技能伤害系数: 获取破招实际系数(-862207.34464),
    技能增益列表: 通用增益,
    技能等级数据: {
      1: { 技能伤害系数: 获取破招实际系数(-862207.34464) },
      2: { 技能伤害系数: 获取破招实际系数(-901235.1336448) },
      3: { 技能伤害系数: 获取破招实际系数(-901235.1336448) },
      4: { 技能伤害系数: 获取破招实际系数(-925791.944704) },
      5: { 技能伤害系数: 获取破招实际系数(-925791.944704) },
    },
  },
  {
    技能ID: '13039',
    技能名称: '卷雪刀',
    技能伤害系数: 24,
    武器伤害系数: 1,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '13075',
    技能名称: '绝刀',
    统计名称: '绝刀',
    秘籍依赖技能: '绝刀',
    技能伤害系数: 485,
    基础伤害_基础值: 180,
    基础伤害_浮动值: 12,
    武器伤害系数: 1,
    技能增益列表: 绝刀增益,
  },
  {
    技能ID: '13085',
    技能名称: '绝刀0-10',
    统计名称: '绝刀',
    秘籍依赖技能: '绝刀',
    技能伤害系数: 790.55,
    基础伤害_基础值: 293,
    基础伤害_浮动值: 20,
    武器伤害系数: 1,
    技能增益列表: 绝刀增益,
  },
  {
    技能ID: '13084',
    技能名称: '绝刀10-20',
    统计名称: '绝刀',
    秘籍依赖技能: '绝刀',
    技能伤害系数: 756.6,
    基础伤害_基础值: 280,
    基础伤害_浮动值: 19,
    武器伤害系数: 1,
    技能增益列表: 绝刀增益,
  },
  {
    技能ID: '13083',
    技能名称: '绝刀20-30',
    统计名称: '绝刀',
    秘籍依赖技能: '绝刀',
    技能伤害系数: 722.65,
    基础伤害_基础值: 268,
    基础伤害_浮动值: 18,
    武器伤害系数: 1,
    技能增益列表: 绝刀增益,
  },
  {
    技能ID: '13082',
    技能名称: '绝刀30-40',
    统计名称: '绝刀',
    秘籍依赖技能: '绝刀',
    技能伤害系数: 688.6999999999999,
    基础伤害_基础值: 255,
    基础伤害_浮动值: 17,
    武器伤害系数: 1,
    技能增益列表: 绝刀增益,
  },
  {
    技能ID: '13081',
    技能名称: '绝刀40-50',
    统计名称: '绝刀',
    秘籍依赖技能: '绝刀',
    技能伤害系数: 654.75,
    基础伤害_基础值: 243,
    基础伤害_浮动值: 16,
    武器伤害系数: 1,
    技能增益列表: 绝刀增益,
  },
  {
    技能ID: '13080',
    技能名称: '绝刀50-60',
    统计名称: '绝刀',
    秘籍依赖技能: '绝刀',
    技能伤害系数: 620.8000000000001,
    基础伤害_基础值: 230,
    基础伤害_浮动值: 16,
    武器伤害系数: 1,
    技能增益列表: 绝刀增益,
  },
  {
    技能ID: '13079',
    技能名称: '绝刀60-70',
    统计名称: '绝刀',
    秘籍依赖技能: '绝刀',
    技能伤害系数: 586.85,
    基础伤害_基础值: 217,
    基础伤害_浮动值: 15,
    武器伤害系数: 1,
    技能增益列表: 绝刀增益,
  },
  {
    技能ID: '13078',
    技能名称: '绝刀70-80',
    统计名称: '绝刀',
    秘籍依赖技能: '绝刀',
    技能伤害系数: 552.9,
    基础伤害_基础值: 205,
    基础伤害_浮动值: 14,
    武器伤害系数: 1,
    技能增益列表: 绝刀增益,
  },
  {
    技能ID: '13077',
    技能名称: '绝刀80-90',
    统计名称: '绝刀',
    秘籍依赖技能: '绝刀',
    技能伤害系数: 518.95,
    基础伤害_基础值: 192,
    基础伤害_浮动值: 13,
    武器伤害系数: 1,
    技能增益列表: 绝刀增益,
  },
  {
    技能ID: '13076',
    技能名称: '绝刀90-100',
    统计名称: '绝刀',
    秘籍依赖技能: '绝刀',
    技能伤害系数: 485,
    基础伤害_基础值: 180,
    基础伤害_浮动值: 12,
    武器伤害系数: 1,
    技能增益列表: 绝刀增益,
  },
  {
    技能ID: '13092',
    技能名称: '斩刀',
    统计名称: '斩刀',
    秘籍依赖技能: '斩刀',
    技能伤害系数: 652,
    基础伤害_基础值: 228,
    基础伤害_浮动值: 10,
    武器伤害系数: 1,
    技能增益列表: 斩刀增益,
  },
  {
    技能ID: '18364',
    技能名称: '斩刀0-10',
    统计名称: '斩刀',
    秘籍依赖技能: '斩刀',
    技能伤害系数: 1062.76,
    基础伤害_基础值: 228,
    基础伤害_浮动值: 10,
    武器伤害系数: 1,
    技能增益列表: 斩刀增益,
  },
  {
    技能ID: '18363',
    技能名称: '斩刀10-20',
    统计名称: '斩刀',
    秘籍依赖技能: '斩刀',
    技能伤害系数: 1017.12,
    基础伤害_基础值: 228,
    基础伤害_浮动值: 10,
    武器伤害系数: 1,
    技能增益列表: 斩刀增益,
  },
  {
    技能ID: '18362',
    技能名称: '斩刀20-30',
    统计名称: '斩刀',
    秘籍依赖技能: '斩刀',
    技能伤害系数: 971.48,
    基础伤害_基础值: 228,
    基础伤害_浮动值: 10,
    武器伤害系数: 1,
    技能增益列表: 斩刀增益,
  },
  {
    技能ID: '18361',
    技能名称: '斩刀30-40',
    统计名称: '斩刀',
    秘籍依赖技能: '斩刀',
    技能伤害系数: 925.8399999999999,
    基础伤害_基础值: 228,
    基础伤害_浮动值: 10,
    武器伤害系数: 1,
    技能增益列表: 斩刀增益,
  },
  {
    技能ID: '18360',
    技能名称: '斩刀40-50',
    统计名称: '斩刀',
    秘籍依赖技能: '斩刀',
    技能伤害系数: 880.2,
    基础伤害_基础值: 228,
    基础伤害_浮动值: 10,
    武器伤害系数: 1,
    技能增益列表: 斩刀增益,
  },
  {
    技能ID: '18359',
    技能名称: '斩刀50-60',
    统计名称: '斩刀',
    秘籍依赖技能: '斩刀',
    技能伤害系数: 834.5600000000001,
    基础伤害_基础值: 228,
    基础伤害_浮动值: 10,
    武器伤害系数: 1,
    技能增益列表: 斩刀增益,
  },
  {
    技能ID: '18358',
    技能名称: '斩刀60-70',
    统计名称: '斩刀',
    秘籍依赖技能: '斩刀',
    技能伤害系数: 788.92,
    基础伤害_基础值: 228,
    基础伤害_浮动值: 10,
    武器伤害系数: 1,
    技能增益列表: 斩刀增益,
  },
  {
    技能ID: '18357',
    技能名称: '斩刀70-80',
    统计名称: '斩刀',
    秘籍依赖技能: '斩刀',
    技能伤害系数: 743.28,
    基础伤害_基础值: 228,
    基础伤害_浮动值: 10,
    武器伤害系数: 1,
    技能增益列表: 斩刀增益,
  },
  {
    技能ID: '18356',
    技能名称: '斩刀80-90',
    统计名称: '斩刀',
    秘籍依赖技能: '斩刀',
    技能伤害系数: 697.64,
    基础伤害_基础值: 228,
    基础伤害_浮动值: 10,
    武器伤害系数: 1,
    技能增益列表: 斩刀增益,
  },
  {
    技能ID: '18355',
    技能名称: '斩刀90-100',
    统计名称: '斩刀',
    秘籍依赖技能: '斩刀',
    技能伤害系数: 652.0,
    基础伤害_基础值: 228,
    基础伤害_浮动值: 10,
    武器伤害系数: 1,
    技能增益列表: 斩刀增益,
  },
  {
    技能ID: '13099',
    技能名称: '盾猛',
    技能伤害系数: 643,
    基础伤害_基础值: 130,
    基础伤害_浮动值: 12,
    武器伤害系数: 1,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '19409',
    技能名称: '盾压',
    技能伤害系数: 443,
    基础伤害_基础值: 202,
    基础伤害_浮动值: 18,
    武器伤害系数: 1,
    技能增益列表: 盾压增益,
  },
  {
    技能ID: '38973',
    技能名称: '盾舞',
    技能伤害系数: 156,
    基础伤害_基础值: 325,
    基础伤害_浮动值: 30,
    武器伤害系数: 1,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '13110',
    技能名称: '盾击',
    统计名称: '盾击',
    技能伤害系数: 521.9,
    基础伤害_基础值: 104,
    基础伤害_浮动值: 10,
    武器伤害系数: 1,
    技能增益列表: 盾击增益,
  },
  {
    技能ID: '13161',
    技能名称: '盾击·二',
    统计名称: '盾击',
    技能伤害系数: 307.0,
    基础伤害_基础值: 104,
    基础伤害_浮动值: 10,
    武器伤害系数: 1,
    技能增益列表: 盾击增益,
  },
  {
    技能ID: '13106',
    技能名称: '盾击·正常',
    统计名称: '盾击',
    技能伤害系数: 307.0,
    基础伤害_基础值: 104,
    基础伤害_浮动值: 10,
    武器伤害系数: 1,
    技能增益列表: 盾击增益,
  },
  {
    技能ID: '13160',
    技能名称: '盾击·正常换动作',
    统计名称: '盾击',
    技能伤害系数: 307.0,
    基础伤害_基础值: 104,
    基础伤害_浮动值: 10,
    武器伤害系数: 1,
    技能增益列表: 盾击增益,
  },
  {
    技能ID: '13107',
    技能名称: '盾击·双倍',
    统计名称: '盾击',
    技能伤害系数: 521.9,
    基础伤害_基础值: 208,
    基础伤害_浮动值: 20,
    武器伤害系数: 1,
    技能增益列表: 盾击增益,
  },
  {
    技能ID: '13108',
    技能名称: '盾击·三倍',
    统计名称: '盾击',
    技能伤害系数: 521.9,
    基础伤害_基础值: 312,
    基础伤害_浮动值: 31,
    武器伤害系数: 1,
    技能增益列表: 盾击增益,
  },
  {
    技能ID: '13463',
    技能名称: '盾飞',
    技能伤害系数: 27,
    基础伤害_基础值: 24,
    基础伤害_浮动值: 2,
    武器伤害系数: 1,
    技能增益列表: 盾飞增益,
  },
  {
    技能ID: '29188', // 8249_29188
    技能名称: '流血(DOT)',
    统计名称: '流血(DOT)',
    技能伤害系数: 291.06,
    DOT生效间隔: 32,
    DOT跳数: 13,
    基础伤害_基础值: 47,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '37568', // 8249_37568
    技能名称: '流血(DOT)·登锋履刃',
    统计名称: '流血(DOT)',
    技能伤害系数: 1455.3,
    DOT生效间隔: 16,
    DOT跳数: 26,
    基础伤害_基础值: 47,
    技能增益列表: [
      ...通用增益,
      {
        增益名称: '登锋',
        依赖奇穴: '登锋',
        增益所在位置: '奇穴',
        增益类型: '全局启用',
        增益集合: [{ 属性: 属性类型.系数增伤, 值: 1.7 }],
      },
    ],
  },
  {
    技能ID: '37600', // 8249_37600
    技能名称: '流血(DOT)·登锋履刃斩刀',
    统计名称: '流血(DOT)',
    技能伤害系数: 1455.3,
    DOT生效间隔: 16,
    DOT跳数: 26,
    基础伤害_基础值: 47,
    技能增益列表: [
      ...通用增益,
      {
        增益名称: '登锋',
        依赖奇穴: '登锋',
        增益所在位置: '奇穴',
        增益类型: '全局启用',
        增益集合: [{ 属性: 属性类型.系数增伤, 值: 1.7 }],
      },
    ],
  },
  {
    技能ID: '29187', // 8249_29187
    技能名称: '流血(DOT)·登锋',
    统计名称: '流血(DOT)',
    技能伤害系数: 582.12,
    DOT生效间隔: 16,
    DOT跳数: 26,
    基础伤害_基础值: 47,
    技能增益列表: [
      ...通用增益,
      {
        增益名称: '登锋',
        依赖奇穴: '登锋',
        增益所在位置: '奇穴',
        增益类型: '全局启用',
        增益集合: [{ 属性: 属性类型.系数增伤, 值: 1.7 }],
      },
    ],
  },
  {
    技能ID: '25780',
    技能名称: '盾击·神兵',
    技能伤害系数: 50,
    基础伤害_浮动值: 2,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '28479',
    技能名称: '劫刀',
    统计名称: '劫刀',
    秘籍依赖技能: '劫刀',
    技能伤害系数: 317,
    基础伤害_基础值: 110,
    基础伤害_浮动值: 29,
    武器伤害系数: 1,
    技能增益列表: 劫刀增益,
  },
  {
    技能ID: '28477',
    技能名称: '劫刀0-10',
    统计名称: '劫刀',
    秘籍依赖技能: '劫刀',
    技能伤害系数: 516.7099999999999,
    基础伤害_基础值: 159,
    武器伤害系数: 1,
    技能增益列表: 劫刀增益,
  },
  {
    技能ID: '28476',
    技能名称: '劫刀10-20',
    统计名称: '劫刀',
    秘籍依赖技能: '劫刀',
    技能伤害系数: 494.52000000000004,
    基础伤害_基础值: 154,
    武器伤害系数: 1,
    技能增益列表: 劫刀增益,
  },
  {
    技能ID: '28475',
    技能名称: '劫刀20-30',
    统计名称: '劫刀',
    秘籍依赖技能: '劫刀',
    技能伤害系数: 472.33,
    基础伤害_基础值: 148,
    武器伤害系数: 1,
    技能增益列表: 劫刀增益,
  },
  {
    技能ID: '28474',
    技能名称: '劫刀30-40',
    统计名称: '劫刀',
    秘籍依赖技能: '劫刀',
    技能伤害系数: 450.14,
    基础伤害_基础值: 143,
    武器伤害系数: 1,
    技能增益列表: 劫刀增益,
  },
  {
    技能ID: '28473',
    技能名称: '劫刀40-50',
    统计名称: '劫刀',
    秘籍依赖技能: '劫刀',
    技能伤害系数: 427.95000000000005,
    基础伤害_基础值: 137,
    武器伤害系数: 1,
    技能增益列表: 劫刀增益,
  },
  {
    技能ID: '28472',
    技能名称: '劫刀50-60',
    统计名称: '劫刀',
    秘籍依赖技能: '劫刀',
    技能伤害系数: 405.76,
    基础伤害_基础值: 132,
    武器伤害系数: 1,
    技能增益列表: 劫刀增益,
  },
  {
    技能ID: '28471',
    技能名称: '劫刀60-70',
    统计名称: '劫刀',
    秘籍依赖技能: '劫刀',
    技能伤害系数: 383.57,
    基础伤害_基础值: 133,
    武器伤害系数: 1,
    技能增益列表: 劫刀增益,
  },
  {
    技能ID: '28470',
    技能名称: '劫刀70-80',
    统计名称: '劫刀',
    秘籍依赖技能: '劫刀',
    技能伤害系数: 361.38,
    基础伤害_基础值: 125,
    武器伤害系数: 1,
    技能增益列表: 劫刀增益,
  },
  {
    技能ID: '28469',
    技能名称: '劫刀80-90',
    统计名称: '劫刀',
    秘籍依赖技能: '劫刀',
    技能伤害系数: 339.19,
    基础伤害_基础值: 117,
    武器伤害系数: 1,
    技能增益列表: 劫刀增益,
  },
  {
    技能ID: '28468',
    技能名称: '劫刀90-100',
    统计名称: '劫刀',
    秘籍依赖技能: '劫刀',
    技能伤害系数: 317,
    基础伤害_基础值: 110,
    基础伤害_浮动值: 29,
    武器伤害系数: 1,
    技能增益列表: 劫刀增益,
  },
  {
    技能ID: '13453',
    技能名称: '闪刀',
    统计名称: '闪刀',
    秘籍依赖技能: '闪刀',
    技能伤害系数: 297.865512,
    基础伤害_基础值: 150,
    基础伤害_浮动值: 15,
    武器伤害系数: 1,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '30857',
    技能名称: '雁门迢递',
    统计名称: '阵云结晦',
    技能伤害系数: 810,
    基础伤害_基础值: 120,
    基础伤害_浮动值: 9,
    武器伤害系数: 1,
    技能增益列表: [
      ...通用增益,
      {
        增益名称: '技能无视',
        增益启用: true,
        增益所在位置: '技能',
        增益类型: '全局启用',
        增益集合: [{ 属性: 属性类型.郭氏外功基础防御, 值: -1024 }],
      },
    ],
  },
  {
    技能ID: '30925',
    技能名称: '阵云结晦',
    统计名称: '阵云结晦',
    技能伤害系数: 540,
    基础伤害_基础值: 72,
    基础伤害_浮动值: 2,
    武器伤害系数: 1,
    技能增益列表: [
      ...通用增益,
      {
        增益名称: '技能无视',
        增益启用: true,
        增益所在位置: '技能',
        增益类型: '全局启用',
        增益集合: [{ 属性: 属性类型.郭氏外功基础防御, 值: -1024 }],
      },
    ],
  },
  {
    技能ID: '30926',
    技能名称: '月照连营',
    统计名称: '阵云结晦',
    技能伤害系数: 675,
    基础伤害_基础值: 96,
    基础伤害_浮动值: 3,
    武器伤害系数: 1,
    技能增益列表: [
      ...通用增益,
      {
        增益名称: '技能无视',
        增益启用: true,
        增益所在位置: '技能',
        增益类型: '全局启用',
        增益集合: [{ 属性: 属性类型.郭氏外功基础防御, 值: -1024 }],
      },
    ],
  },
  {
    技能ID: '34674',
    技能名称: '麟光甲寒',
    技能伤害系数: 377.52000000000004,
    基础伤害_基础值: 80,
    基础伤害_浮动值: 15,
    武器伤害系数: 1,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '34714',
    技能名称: '业火焚城·云盾',
    技能伤害系数: 228.8,
    基础伤害_基础值: 80,
    基础伤害_浮动值: 15,
    武器伤害系数: 1,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '36482',
    技能名称: '援戈·血影',
    技能伤害系数: 687.2399999999999,
    基础伤害_基础值: 80,
    基础伤害_浮动值: 15,
    武器伤害系数: 1,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '37253',
    技能名称: '麾远',
    技能伤害系数: 340,
    基础伤害_基础值: 80,
    基础伤害_浮动值: 15,
    武器伤害系数: 1,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '38651',
    技能名称: '过涯',
    技能伤害系数: 120,
    基础伤害_基础值: 54,
    基础伤害_浮动值: 1,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '33097',
    技能名称: '血誓',
    秘籍依赖技能: '绝刀',
    技能伤害系数: 162,
    基础伤害_基础值: 80,
    基础伤害_浮动值: 15,
    武器伤害系数: 1,
    技能增益列表: 绝刀增益,
  },
  // ===============
  {
    // 龙门武器
    技能名称: '剑风',
    基础伤害_基础值: 3950,
    技能增益列表: 通用增益,
  },
  ...真实伤害系数,
]

export default 技能基础数据

const 技能增益 = {
  通用增益,
}

export { 技能增益 }
