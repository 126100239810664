import type { 循环数据 } from '@/@types/循环'

/**
 * @name 用于计算的循环数据
 * 该数据可以通过JCL分析器进行获取，也可以自己根据实际情况编写
 */

// 循环
// import 测试循环 from './测试循环.json'
import 身意布泽 from './身意布泽.json'
import 身意布泽二段 from './身意布泽二段.json'
import 二段加速橙武 from './二段加速橙武.json'
// import 二段加速合并爆发 from './Mondest测试/二段加速合并爆发.json'
// import 二段加速拆分爆发 from './Mondest测试/二段加速拆分爆发.json'
// import 小飞棍循环 from './小飞棍循环.json'

const 计算循环: 循环数据[] = [
  身意布泽,
  身意布泽二段,
  二段加速橙武,
  // 一段加速合并爆发,
  // 二段加速合并爆发,
  // 二段加速拆分爆发,
  // 小飞棍循环,
] as 循环数据[]

export default 计算循环
