import { 技能基础数据模型 } from '@/@types/技能'
import { 计算公式计算类型 } from '@/@types/伤害计算'

const 真实伤害系数: 技能基础数据模型[] = [
  // ====================
  {
    技能名称: '逐云寒蕊',
    伤害计算次数: 1,
    真实伤害: 1,
    伤害计算类型标记: [计算公式计算类型.真实伤害, 计算公式计算类型.等级减伤, 计算公式计算类型.易伤],
  },
  {
    // 伤腕
    技能ID: '37562',
    技能名称: '昆吾·弦刃',
    真实伤害: 244355, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 伤腕
    技能ID: '37562',
    技能名称: '昆吾·弦刃·英雄',
    真实伤害: 281280, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 伤鞋
    技能ID: '37561',
    技能名称: '刃凌',
    真实伤害: 162903, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 伤鞋
    技能ID: '37561',
    技能名称: '刃凌·英雄',
    真实伤害: 187520, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 试炼之地暗器DOT
    技能ID: '38966',
    技能名称: '无修·荒',
    真实伤害: 86500, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
  {
    // 试炼之地暗器DOT
    技能ID: '38966',
    技能名称: '无修·荒·英雄',
    真实伤害: 90000, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
  },
]

export default 真实伤害系数
