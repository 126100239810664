import { 技能增益列表类型 } from '@/@types/技能'
import 通用增益 from './通用增益/通用增益'
import { 属性类型 } from '@/@types/属性'

const 列宿游增益: 技能增益列表类型[] = [
  ...通用增益,
  {
    增益名称: `无界_通用易伤_5`,
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.易伤增伤, 值: 52 / 1024 }],
  },
  {
    增益名称: `无界_通用易伤_20`,
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.易伤增伤, 值: 205 / 1024 }],
  },
  {
    增益名称: `无界_通用易伤_30`,
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.易伤增伤, 值: 308 / 1024 }],
  },
  {
    增益名称: `无界_通用易伤_35`,
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.易伤增伤, 值: 359 / 1024 }],
  },
  {
    增益名称: `无界_通用易伤_40`,
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.易伤增伤, 值: 410 / 1024 }],
  },
  {
    增益名称: `无界_通用易伤_45`,
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.易伤增伤, 值: 461 / 1024 }],
  },
]

export default 列宿游增益
