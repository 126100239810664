import { 团队增益数据类型 } from '@/@types/团队增益'
import { 属性类型 } from '@/@types/属性'
import 通用团队增益数据 from '../通用'

const 外功团队增益数据: 团队增益数据类型[] = [
  {
    增益名称: '撼如雷',
    层数: 1,
    覆盖率: 100,
    增益图片: 'https://icon.jx3box.com/icon/635.png',
    增益描述: '外功基础攻击力提高【5%】，气血最大值提高【5%】',
    增益来源: '天策',
    过滤心法: '傲血战意',
    团队增益类型: '常用增益',
    增益集合: [
      { 属性: 属性类型.郭氏外功基础攻击, 值: 51 },
      { 属性: 属性类型.郭氏最终气血上限, 值: 51 },
    ],
  },
  {
    增益名称: '破风',
    层数: 1,
    覆盖率: 100,
    增益图片: 'https://icon.jx3box.com/icon/647.png',
    增益描述: '外功防御等级降低【3500】点',
    增益来源: '傲血战意/铁牢律',
    过滤心法: '傲血战意',
    团队增益类型: '目标减益',
    冲突增益: ['劲风'],
    增益集合: [{ 属性: 属性类型.外功基础防御, 值: -3500 }],
  },
  {
    增益名称: '劲风',
    层数: 1,
    覆盖率: 100,
    增益图片: 'https://icon.jx3box.com/icon/650.png',
    增益描述: '外功防御等级降低【4655】点',
    增益来源: '铁牢律_奇穴增幅破风效果',
    团队增益类型: '目标减益',
    过滤心法: '傲血战意',
    冲突增益: ['破风'],
    增益集合: [{ 属性: 属性类型.外功基础防御, 值: -4655 }],
  },
  {
    增益名称: '劲风',
    层数: 1,
    覆盖率: 100,
    增益图片: 'https://icon.jx3box.com/icon/650.png',
    增益描述: '外功防御等级降低【4655】点',
    增益来源: '铁牢律_奇穴增幅破风效果',
    团队增益类型: '目标减益',
    心法特供: '傲血战意',
    冲突增益: ['破风'],
    增益集合: [{ 属性: 属性类型.外功基础防御, 值: -(4655 - 3500) }],
  },
  {
    增益名称: '虚弱',
    层数: 1,
    覆盖率: 100,
    增益图片: 'https://icon.jx3box.com/icon/6347.png',
    增益描述: '使目标外功防御等级降低【5%】',
    增益来源: '铁骨衣',
    团队增益类型: '目标减益',
    增益集合: [{ 属性: 属性类型.郭氏外功基础防御, 值: -51 }],
  },
  {
    增益名称: '破甲',
    层数: 1,
    覆盖率: 33,
    覆盖率支持手动录入: true,
    增益图片: 'https://icon.jx3box.com/icon/2987.png',
    增益描述: '外功防御等级降低【10%】',
    增益来源: '铁牢律_乘龙箭',
    团队增益类型: '目标减益',
    增益集合: [{ 属性: 属性类型.郭氏外功基础防御, 值: -102 }],
  },
  {
    增益名称: '龙吟·悟',
    增益心法端: '无界',
    层数: 1,
    覆盖率: 100,
    增益图片: 'https://icon.jx3box.com/icon/650.png',
    增益描述: '受到的外功伤害提高【3%】',
    增益来源: '铁牢律·悟',
    团队增益类型: '目标减益',
    冲突增益: ['战锋·悟'],
    增益集合: [{ 属性: 属性类型.易伤增伤, 值: 31 / 1024 }],
  },
  {
    增益名称: '战锋·悟',
    增益心法端: '无界',
    层数: 1,
    覆盖率: 100,
    增益图片: 'https://icon.jx3box.com/icon/14126.png',
    增益描述: '受到的外功伤害提高【4%】',
    增益来源: '铁牢律·悟',
    冲突增益: ['龙吟·悟'],
    团队增益类型: '目标减益',
    增益集合: [{ 属性: 属性类型.易伤增伤, 值: 41 / 1024 }],
  },
  {
    增益名称: '云片糕',
    层数: 1,
    覆盖率: 100,
    增益图片: 'https://icon.jx3box.com/icon/6892.png',
    增益描述: '增加【972】外功攻击',
    增益来源: '端午节日活动',
    团队增益类型: '节日增益',
    增益集合: [{ 属性: 属性类型.外功基础攻击, 值: 972 }],
  },
  {
    增益名称: '芦兜粽',
    层数: 1,
    覆盖率: 100,
    增益图片: 'https://icon.jx3box.com/icon/2582.png',
    增益描述: '增加【907】外功攻击',
    增益来源: '端午节日活动',
    冲突增益: ['瘦肉粽', '瓜仁粽', '瑰栗粽'],
    团队增益类型: '节日增益',
    增益集合: [{ 属性: 属性类型.外功基础攻击, 值: 907 }],
  },
  {
    增益名称: '酣畅淋漓',
    层数: 1,
    覆盖率: 100,
    增益图片: 'https://icon.jx3box.com/icon/4892.png',
    增益描述: '外功会心几率提高【10%】，招式威胁值降低25%',
    增益来源: '丐帮_酒中仙',
    团队增益类型: '稀缺增益',
    增益集合: [{ 属性: 属性类型.外功会心百分比, 值: 0.1 }],
  },
  {
    增益名称: '碎星辰',
    层数: 1,
    覆盖率: 50,
    覆盖率支持手动录入: true,
    增益图片: 'https://icon.jx3box.com/icon/1450.png',
    增益描述: '外功会心效果提高【10%】',
    增益来源: '太虚剑意',
    团队增益类型: '稀缺增益',
    增益集合: [{ 属性: 属性类型.郭氏外功会心效果等级, 值: 102 }],
  },
  ...通用团队增益数据,
]

export default 外功团队增益数据
