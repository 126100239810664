import { 奇穴列表数据类型 } from '@/@types/奇穴'

const 奇穴数据: 奇穴列表数据类型[] = [
  {
    奇穴列表: [
      {
        奇穴名称: '归兮·悟',
        奇穴图片: 'https://icon.jx3box.com/icon/100487.png',
        id: '100487',
        奇穴描述: '施展【傍身招式】后使自身移速提高15%、受到的伤害降低15%，持续8秒。',
      },
      {
        奇穴名称: '陶然·悟',
        奇穴图片: 'https://icon.jx3box.com/icon/100488.png',
        id: '100488',
        奇穴描述: '施展【傍身招式】后使自身无双和造成的伤害提高10%，持续8秒。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '忘机·悟',
        奇穴图片: 'https://icon.jx3box.com/icon/100489.png',
        id: '100489',
        奇穴描述: '{兰摧}伤害提高30%，【玉石俱焚·悟】对非侠士目标伤害提高50%。',
      },
      {
        奇穴名称: '夕落·悟',
        奇穴图片: 'https://icon.jx3box.com/icon/100490.png',
        id: '100490',
        奇穴描述: '【玉石俱焚·悟】伤害提高30%，{兰摧}立即生效后使目标被疗伤效果降低50%，持续5秒。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '渡泉·悟',
        奇穴图片: 'https://icon.jx3box.com/icon/100491.png',
        id: '100491',
        奇穴描述: '施展伤害招式命中气血值低于50%的目标自身会心率和会心效果提高20%，持续10秒。',
      },
      {
        奇穴名称: '常心·悟',
        奇穴图片: 'https://icon.jx3box.com/icon/100492.png',
        id: '100492',
        奇穴描述: '施展【太阴指·悟】后免疫控制持续3秒，受到的伤害降低30%持续8秒。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '水月无间·悟',
        奇穴图片: 'https://icon.jx3box.com/icon/100050.png',
        id: '100050',
        奇穴描述: '10秒内下一个运功招式无需运功且必定会心。',
      },
      {
        奇穴名称: '乱洒青荷·悟',
        奇穴图片: 'https://icon.jx3box.com/icon/100051.png',
        id: '100051',
        奇穴描述: '重置【玉石俱焚·悟】调息时间，15秒内下一个【傍身招式】附带{兰摧}效果。',
      },
    ],
  },
]

export default 奇穴数据
