import { 属性类型 } from '@/@types/属性'
import { 技能增益列表类型 } from '@/@types/技能'
// import 获取快照增益 from '@/心法模块/统一数据/快照增益'

const 通用外功增益: 技能增益列表类型[] = [
  // ...获取快照增益('内功'),
  {
    增益名称: '非侠',
    增益所在位置: '职业',
    增益启用: true,
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.非侠增伤, 值: 307 / 1024 }],
  },
  {
    // 10023
    增益名称: '伏魔',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 102 / 1024 }],
  },
  {
    // 19635_1
    增益名称: '普渡·1',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.易伤增伤, 值: 41 / 1024 }],
  },
  {
    // 19635_2
    增益名称: '普渡·2',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.易伤增伤, 值: 82 / 1024 }],
  },
  {
    // 19635_3
    增益名称: '普渡·3',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.易伤增伤, 值: 123 / 1024 }],
  },
  {
    // 12590_1
    增益名称: '三生·1',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.郭氏无双, 值: 102 }],
  },
  {
    // 12590_2
    增益名称: '三生·2',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.郭氏无双, 值: 205 }],
  },
  {
    // 12590_3
    增益名称: '三生·3',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.郭氏无双, 值: 307 }],
  },
  {
    // 13910
    增益名称: '众嗔',
    增益所在位置: '奇穴',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 205 / 1024 }],
  },
]

export default 通用外功增益
