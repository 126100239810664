import { 奇穴列表数据类型 } from '@/@types/奇穴'

const 奇穴数据: 奇穴列表数据类型[] = [
  {
    奇穴列表: [
      {
        奇穴名称: '封侯',
        奇穴图片: 'https://icon.jx3box.com/icon/4558.png',
        id: '5656',
        奇穴描述: '“龙吟”会心几率提高10%，会心效果提高10%。',
      },
      {
        奇穴名称: '扬戈',
        奇穴图片: 'https://icon.jx3box.com/icon/4513.png',
        id: '5657',
        奇穴描述: '“穿云”的会心几率提高10%，会心效果提高10%。',
      },
      {
        奇穴名称: '百折',
        奇穴图片: 'https://icon.jx3box.com/icon/7446.png',
        id: '18487',
        奇穴描述:
          '“御”可以在基础调息时间中施展，施展后0.5秒内成功抵御伤害则使自身下一次招式伤害提高30%。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '神勇',
        奇穴图片: 'https://icon.jx3box.com/icon/637.png',
        id: '5660',
        奇穴描述: '“龙牙”会心几率提高10%，会心效果提高10%。',
      },
      {
        奇穴名称: '挫锐',
        奇穴图片: 'https://icon.jx3box.com/icon/11904.png',
        id: '32911',
        奇穴描述: '施展“龙吟”后，20秒内下一次施展“穿云”距离增加4尺并会造成3段外功伤害。',
      },
      {
        奇穴名称: '穿',
        奇穴图片: 'https://icon.jx3box.com/icon/655.png',
        id: '18224',
        奇穴描述: '“龙吟”对目标周围8尺内最多5个目标造成外功伤害且移动速度降低60%，持续12秒。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '骁勇',
        奇穴图片: 'https://icon.jx3box.com/icon/647.png',
        id: '18602',
        奇穴描述: '“流血”效果伤害提高12%。',
      },
      {
        奇穴名称: '龙啸',
        奇穴图片: 'https://icon.jx3box.com/icon/2989.png',
        id: '5658',
        奇穴描述:
          '“龙牙”命中目标后，对目标周围6尺的5个目标造成外功伤害。该伤害不受到“龙牙”秘籍的影响。',
      },
      {
        奇穴名称: '大漠',
        奇穴图片: 'https://icon.jx3box.com/icon/4522.png',
        id: '5659',
        奇穴描述: '施展“灭”后，使下一个“龙吟”额外造成点外功伤害。',
      },
      {
        奇穴名称: '徐如林',
        奇穴图片: 'https://icon.jx3box.com/icon/653.png',
        id: '18228',
        奇穴描述:
          '施展“突”“断魂刺”之后，自身将获得“徐如林”效果，使自身外功伤害招式命中敌人后，有50%的几率额外造成一段伤害并使自身气血与内力在1秒后恢复5%（每秒最多1次），持续5秒。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '击水',
        奇穴图片: 'https://icon.jx3box.com/icon/4504.png',
        id: '18226',
        奇穴描述:
          '“流血”效果可叠3层，持续效果增加3跳。对有自身三层流血的非侠士目标再次添加流血效果，会额外造成外功伤害。',
      },
      {
        奇穴名称: '飞将',
        奇穴图片: 'https://icon.jx3box.com/icon/639.png',
        id: '5673',
        奇穴描述: '对击倒状态的目标伤害提高35%。',
      },
      {
        奇穴名称: '龙驭',
        奇穴图片: 'https://icon.jx3box.com/icon/13434.png',
        id: '24896',
        奇穴描述:
          '“任驰骋”调息时间降低6秒，“骑御”期间每次移动施展“羽林枪法”会额外消耗自身2%的气血值，并获得“龙驭”效果：本次“骑御”状态造成的伤害提高7%，最多叠加3层。',
      },
      {
        奇穴名称: '背水一战',
        奇穴图片: 'https://icon.jx3box.com/icon/13432.png',
        id: '37338',
        奇穴描述:
          '施展“撼如雷”获得12秒“背水一战”效果。&lt;br/&gt;背水一战：效果持续期间每2秒对自身造成最大气血值2%的伤害，同时对自身6尺内2名敌对侠士目标额外造成点外功伤害，持续12秒，若命中目标，则下次伤害增加10%，最多提高50%。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '出云',
        奇穴图片: 'https://icon.jx3box.com/icon/643.png',
        id: '5669',
        奇穴描述: '“战八方”会心几率提高10%，会心效果提高10%，可以在马下施展。',
      },
      {
        奇穴名称: '临阵',
        奇穴图片: 'https://icon.jx3box.com/icon/18304.png',
        id: '32893',
        奇穴描述:
          '冲刺至15尺内目标身边打断目标运功并造成100%武器伤害附带点外功伤害。在马上施展会用长枪挑飞目标，使目标2秒内无法施展轻功，成功挑飞目标则3秒内可额外施展1次无需运功的乘龙箭，成功击落目标则使目标倒地4秒并获得5秒“破军”效果。',
      },
      {
        奇穴名称: '驰骋',
        奇穴图片: 'https://icon.jx3box.com/icon/640.png',
        id: '14824',
        奇穴描述: '施展“任驰骋”获得的外功基础攻击力效果提高至30%，可以在“骑御”状态施展“灭”。',
      },
      {
        奇穴名称: '振弦',
        奇穴图片: 'https://icon.jx3box.com/icon/17185.png',
        id: '30653',
        奇穴描述:
          '施展“突”、“沧月”、“破坚阵”成功击倒目标后，会延迟对目标位置发射一波半径6尺的箭雨，对最多5个目标造成外功伤害，视作“乘龙箭”效果。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '坚城',
        奇穴图片: 'https://icon.jx3box.com/icon/2990.png',
        id: '5694',
        奇穴描述:
          '“守如山”调息时间降低55秒，减伤效果变为50%，在“守如山”效果期间受到“定身”、“眩晕”、“击倒”控制效果缩短30%，马上施展则额外增加5秒持续时间。',
      },
      {
        奇穴名称: '牧云',
        奇穴图片: 'https://icon.jx3box.com/icon/642.png',
        id: '6511',
        奇穴描述:
          '“突”的调息时间增加37秒，施展后自身获得“牧云”效果，持续60秒，效果期间施展“龙牙”则叠加一层“牧云”效果，最多作用3次。',
      },
      {
        奇穴名称: '破军',
        奇穴图片: 'https://icon.jx3box.com/icon/9521.png',
        id: '23997',
        奇穴描述:
          '“破坚阵”命中目标后，目标获得的“破军”持续时间提高至10秒，累计5次伤害则使目标获得8秒“重创”效果：受到的下一次治疗效果无效，最多叠加5层。',
      },
      {
        奇穴名称: '青阳',
        奇穴图片: 'https://icon.jx3box.com/icon/4576.png',
        id: '6530',
        奇穴描述:
          '“灭”的施展距离提高到25尺，施展时解除自身锁足减速不利效果并冲向目标，命中后使目标眩晕2秒。命中有自身“流血”效果的目标，每层“流血”额外造成1段点外功伤害。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '守孤城',
        奇穴图片: 'https://icon.jx3box.com/icon/4549.png',
        id: '6528',
        奇穴描述: '施展“御”后每秒回复2%的气血与内力，持续5秒。',
      },
      {
        奇穴名称: '风虎',
        奇穴图片: 'https://icon.jx3box.com/icon/2993.png',
        id: '5666',
        奇穴描述: '每点“战意”增加“穿云”“龙吟”“龙牙”“灭”5%伤害。',
      },
      {
        奇穴名称: '千驰',
        奇穴图片: 'https://icon.jx3box.com/icon/1458.png',
        id: '18233',
        奇穴描述:
          '“沧月”若在马上释放则每0.5秒造成一次击退效果，且对刀气障碍具有额外的伤害，持续3秒。释放期间具有“纵轻骑”效果。',
      },
      {
        奇穴名称: '霹雳',
        奇穴图片: 'https://icon.jx3box.com/icon/2986.png',
        id: '2611',
        奇穴描述:
          '对目标及其附近额外的3个目标造成200%武器伤害并附加点外功伤害，使目标受到的疗伤成效降低50%，持续12秒，若玩家目标身上存在护盾，则基于自身最大生命值的40%击碎其护盾值，再造成外功伤害。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '关山月',
        奇穴图片: 'https://icon.jx3box.com/icon/3426.png',
        id: '21285',
        奇穴描述: '力道、体质提高10%。',
      },
      {
        奇穴名称: '流电',
        奇穴图片: 'https://icon.jx3box.com/icon/13431.png',
        id: '18557',
        奇穴描述:
          '“乘龙箭”命中有自身“破军”效果的目标，在目标位置生成一个半径6尺持续4秒的“飞鸣镝”区域，区域内最多5个敌对目标每秒会受到外功伤害。在区域内施展“撼如雷”则会引爆该区域对其中5个敌对目标造成点外功伤害并使目标获得1层“重创”效果。',
      },
      {
        奇穴名称: '飞遁',
        奇穴图片: 'https://icon.jx3box.com/icon/2997.png',
        id: '5676',
        奇穴描述: '“任驰骋”上马后移动速度提高50%，“纵轻骑”效果持续过程中将不受任何位移招式影响。',
      },
      {
        奇穴名称: '战心',
        奇穴图片: 'https://icon.jx3box.com/icon/4531.png',
        id: '6781',
        奇穴描述:
          '“灭”附带流血和破风效果并使“流血”伤害提高20%，使自身立刻获得3点战意，并每秒回复3%内力和气血，持续3秒。',
      },
      {
        奇穴名称: '疾雨',
        奇穴图片: 'https://icon.jx3box.com/icon/14131.png',
        id: '24895',
        奇穴描述:
          '“乘龙箭”每0.8秒朝目标区域发射箭雨，持续2.4秒。每波箭雨延迟0.8秒后对目标6尺范围最多5个目标造成外功伤害，每波箭雨范围内有3个及以上敌方目标会降低“乘龙箭”8秒调息时间。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '破楼兰',
        奇穴图片: 'https://icon.jx3box.com/icon/4558.png',
        id: '6524',
        奇穴描述:
          '“灭”对于气血值低于80%的目标造成100%的额外伤害，施展灭8秒内获得一次额外施展的机会，同时额外附带一段破招伤害。',
      },
      {
        奇穴名称: '长征',
        奇穴图片: 'https://icon.jx3box.com/icon/638.png',
        id: '6514',
        奇穴描述: '“突”变为2层充能，命中目标时获得1点“战意”。',
      },
      {
        奇穴名称: '挥金镝',
        奇穴图片: 'https://icon.jx3box.com/icon/18305.png',
        id: '32905',
        奇穴描述:
          '蓄力射箭，期间自身免疫控制，最多蓄力3秒对最多8个目标造成最远30尺最多的外功伤害，使目标获得最少2秒最多6秒的“破军”效果。',
      },
      {
        奇穴名称: '渊',
        奇穴图片: 'https://icon.jx3box.com/icon/2985.png',
        id: '2628',
        奇穴描述:
          '解除自身所有移动限制(击倒和被击僵直除外)，冲刺到友方目标身边，为其承受4次伤害，并使自身外功基础攻击力提高35%(点)，持续10秒。',
      },
      {
        奇穴名称: '铁骑',
        奇穴图片: 'https://icon.jx3box.com/icon/14129.png',
        id: '28442',
        奇穴描述:
          '“任驰骋”调息时间降低至37秒且不再充能，“纵轻骑”效果持续时间增加6秒，“纵轻骑”效果下自身每造成三次外功伤害会延长一秒“纵轻骑”效果的时间，最多延长4次。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '泾威',
        奇穴图片: 'https://icon.jx3box.com/icon/11900.png',
        id: '22727',
        奇穴描述: '“疾如风”调息时间降低5秒，自身免疫控制效果提高至5秒。可额外透支使用一次。',
      },
      {
        奇穴名称: '激雷',
        奇穴图片: 'https://icon.jx3box.com/icon/635.png',
        id: '6806',
        奇穴描述: '施展“撼如雷”后小队成员的外功破防值提高20%，外功基础攻击力提高20%，持续6秒。',
      },
      {
        奇穴名称: '夜征',
        奇穴图片: 'https://icon.jx3box.com/icon/7462.png',
        id: '5678',
        奇穴描述: '“激雷”效果持续期间自身无视目标70%外功防御，效果持续时间提高200%。',
      },
      {
        奇穴名称: '雷行',
        奇穴图片: 'https://icon.jx3box.com/icon/4543.png',
        id: '18231',
        奇穴描述:
          '“沧月”击退距离提高，每击退1个目标，增加0.5秒“纵轻骑”效果时间，降低0.5秒“沧月”调息时间。',
      },
      {
        奇穴名称: '破国',
        奇穴图片: 'https://icon.jx3box.com/icon/13433.png',
        id: '24899',
        奇穴描述:
          '“任驰骋”招式无需运功，且“纵轻骑”效果持续时间提高1秒，期间自身加速值突破上限，提高50%，施展“羽林枪法”招式额外附带一次100%的武器伤害效果。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '牙璋',
        奇穴图片: 'https://icon.jx3box.com/icon/7424.png',
        id: '14821',
        奇穴描述:
          '“流血”作用间隔缩短0.25秒，“战八方”调息时间增加6秒，命中目标附带“流血”“破风”效果。',
      },
      {
        奇穴名称: '易险',
        奇穴图片: 'https://icon.jx3box.com/icon/11901.png',
        id: '28564',
        奇穴描述:
          '“乘龙箭”命中10尺外的目标时，重置自身“断魂刺”和“破坚阵”的招式调息时间，每次骑御状态下只可触发一次。',
      },
      {
        奇穴名称: '龙血',
        奇穴图片: 'https://icon.jx3box.com/icon/7495.png',
        id: '15001',
        奇穴描述: '“龙牙”施展距离提高4尺，命中有自身“流血”效果的目标额外造成40%伤害。',
      },
      {
        奇穴名称: '薄伐',
        奇穴图片: 'https://icon.jx3box.com/icon/7447.png',
        id: '14823',
        奇穴描述:
          '“破坚阵”可冲刺至15尺内目标身边对其释放，将目标周围8尺范围5个目标击倒并立即造成外功伤害。',
      },
      {
        奇穴名称: '戎行',
        奇穴图片: 'https://icon.jx3box.com/icon/20081.png',
        id: '24901',
        奇穴描述:
          '施展“任驰骋”后10秒内可以对右后方8尺以内倒地状态的目标施展二段招式，若目标为小队成员则解除目标倒地状态并尝试使其进入双骑状态,并给予目标最大气血值10%护盾,持续5秒；若目标为敌方目标则维持其倒地状态，自身和目标一起向前冲刺并对其造成100%武器伤害附加最高外功伤害，自身速度越快，冲刺距离越远伤害越高。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '虎贲',
        奇穴图片: 'https://icon.jx3box.com/icon/1457.png',
        id: '6517',
        奇穴描述: '每累计施展龙牙3次，使下一次龙牙施展时不消耗战意。',
      },
      {
        奇穴名称: '啸西风',
        奇穴图片: 'https://icon.jx3box.com/icon/23480.png',
        id: '38671',
        奇穴描述:
          '施展后对50尺距离内随机身上存在“流血”效果的最多3名侠士目标周围召唤战狼对其进行攻击，持续9秒，战狼伤害频率受召唤时的“流血”效果层数影响，层数越高频率越快。',
      },
      {
        奇穴名称: '车悬阵',
        奇穴图片: 'https://icon.jx3box.com/icon/17186.png',
        id: '30654',
        奇穴描述:
          '该招式“骑御”状态会替换“龙牙”招式，不受“龙牙”秘籍影响，消耗3点战意。施展后自身向右侧划出枪刃，移动状态下对右侧6尺范围内最多5个敌方目标持续造成100%武器伤害外加点外功伤害，持续4秒，期间可以施展冲刺招式。行进距离越远自身伤害越高，再次释放或者结束时会对前方8尺范围造成100%武器伤害外加点外功伤害。',
      },
      {
        奇穴名称: '破重围',
        奇穴图片: 'https://icon.jx3box.com/icon/7506.png',
        id: '15158',
        奇穴描述:
          '纵马疾驰，自身免疫控制，受到的伤害降低30%，自身招式施展距离提高2尺，控制目标并连续造成13次共计点外功伤害效果并使目标无法施展轻功，命中击倒中的目标伤害提高50%，骑马时方可施展此招式。',
      },
      {
        奇穴名称: '力破万钧',
        奇穴图片: 'https://icon.jx3box.com/icon/14130.png',
        id: '24841',
        奇穴描述:
          '只能在马下施展，蓄力刺出长枪，蓄力期间自身免疫控制，移动速度降低50%，最多蓄力1.5秒，对面前12尺最多4个目标击退并最多造成点外功伤害。若将目标击退至障碍上，使得目标击倒，最多3秒。蓄力时间越长，伤害越高，击倒时间越长。对气血值低于50%的目标伤害提高70%。',
      },
    ],
  },
]

export default 奇穴数据
