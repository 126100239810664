import type { 循环数据 } from '@/@types/循环'

/**
 * @name 用于计算的循环数据
 * 该数据可以通过JCL分析器进行获取，也可以自己根据实际情况编写
 */

// import 测试循环 from './测试循环.json'
// import 紫武_老循环 from './紫武_老循环.json'
import 手动 from './手动.json'
// import 紫武_三动物 from './bak/紫武_三动物.json'

const 计算循环: 循环数据[] = [
  // 测试循环,
  手动,
  // 紫武_老循环,
  // 紫武_三动物,
] as 循环数据[]

export default 计算循环
