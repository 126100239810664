import { 获取实际帧数 } from '@/工具函数/data'

export const 获取阈值 = (原始帧数 = 24, 计算档位 = 5) => {
  const res = { 0: 0 }
  let 加速等级 = 0
  // 52519.5
  const 最多计算次数 = 52520
  while (!res[计算档位] && 加速等级 < 最多计算次数) {
    const 当前帧数 = 获取实际帧数(原始帧数, 加速等级)
    if (原始帧数 - 当前帧数 > 0) {
      const 加速档位 = 原始帧数 - 当前帧数
      if (!res[加速档位]) {
        res[加速档位] = 加速等级
      }
    }
    加速等级 = 加速等级 + 1
  }
  return res
}

// 在整个项目迁移前先用本地方法
