import { 属性加成, 属性类型 } from './属性'
// import { 镶嵌增伤类型枚举 } from './枚举'
import { 角色基础属性类型 } from './角色'

// 装备类型
export enum 装备类型枚举 {
  普通 = '普通',
  副本精简 = '副本精简',
  切糕 = '切糕',
  门派套装 = '门派套装',
  试炼精简 = '试炼精简',
  特效武器 = '特效武器',
  橙武 = '橙武',
  PVX = 'PVX',
}

// 装备特效枚举
export enum 装备特效枚举 {
  水特效武器 = '水特效武器',
  门派套装 = '门派套装',
  切糕_普通 = '切糕_普通',
  切糕_英雄 = '切糕_英雄',
  冬至套装 = '冬至套装',
  大橙武特效 = '大橙武特效',
  小橙武特效 = '小橙武特效',
  风特效腰坠 = '风特效腰坠',
  龙门飞剑武器 = '龙门飞剑武器',
  精简鞋子破防 = '精简鞋子破防',
  精简适应之力 = '精简适应之力',
  精简项链破防 = '精简项链破防',
  精简腰坠破防 = '精简腰坠破防',
  精简暗器特效 = '精简暗器特效',
  精简项链会心 = '精简项链会心',
  精简鞋子会心 = '精简鞋子会心',
  精简腰坠会效 = '精简腰坠会效',
}

export enum 试炼之地特效枚举 {
  精简鞋子破防 = '精简鞋子破防',
  精简适应之力 = '精简适应之力',
  精简项链破防 = '精简项链破防',
  精简腰坠破防 = '精简腰坠破防',
  精简暗器特效 = '精简暗器特效',
  精简项链会心 = '精简项链会心',
  精简鞋子会心 = '精简鞋子会心',
  精简腰坠会效 = '精简腰坠会效',
}

export interface 装备增益原始数据类型 {
  [key: string]: {
    数量: number
    等级: number
  }
}

/**
 * @name 装备属性信息模型
 */
export interface 装备属性信息模型 {
  /**
   * @name 装备id
   */
  id?: number
  /**
   * @name 装备iid
   */
  uid?: string | number
  /**
   * @name 装备名称
   */
  装备名称: string
  /**
   * @name 装备品级
   */
  装备品级: number
  /**
   * @name 装备主属性
   */
  装备主属性: '通用' | '力道' | '身法' | '元气' | '根骨'
  /**
   * @name 武器伤害_最小值
   */
  武器伤害_最小值?: number
  /**
   * @name 武器伤害_最大值
   */
  武器伤害_最大值?: number
  /**
   * @name 装备类型
   */
  装备类型: 装备类型枚举
  /**
   * @name 最大精炼等级
   */
  最大精炼等级?: number
  /**
   * @name 当前精炼等级
   */
  当前精炼等级?: number
  /**
   * @name 装备增益
   */
  装备增益: 属性加成[]
  /**
   * @name 镶嵌孔数组
   */
  镶嵌孔数组?: 镶嵌孔数组类型[]
  /**
   * @name 装备特效
   */
  装备特效?: 装备特效枚举
  /**
   * @name 所属门派
   * @default 通用
   */
  所属门派?: '通用' | string
  /**
   * @name 装备部位
   */
  装备部位?: string
}

// 镶嵌孔数据
export interface 镶嵌孔数组类型 {
  /**
   * @name 镶嵌孔名
   */
  镶嵌类型?: 属性类型
  /**
   * @name 镶嵌宝石等级
   */
  镶嵌宝石等级?: number
}

/**
 * 0 代表无增益
 * 1 代表普通品级增益
 * 2 代表英雄品级增益
 */
export type 装备增益表现 = 0 | 1 | 2 | 3 | 4 | 5

export interface 装备增益类型 {
  /**
   * @name 套装技能
   */
  套装技能?: 装备增益表现
  /**
   * @name 套装会心会效
   */
  套装会心会效?: 装备增益表现
  /**
   * @name 水特效武器
   */
  水特效武器?: 装备增益表现
  /**
   * @name 龙门飞剑武器
   */
  龙门飞剑武器?: 装备增益表现
  /**
   * @name 特效腰坠
   */
  风特效腰坠?: 装备增益表现
  /**
   * @name 大橙武特效
   */
  大橙武特效?: 装备增益表现
  /**
   * @name 大橙武特效
   */
  小橙武特效?: 装备增益表现
  /**
   * @name 切糕双会加成数量
   */
  切糕会心?: 装备增益表现
  /**
   * @name 切糕无双加成数量
   */
  切糕无双?: 装备增益表现
  /**
   * @name 切糕双会加成数量-英雄
   */
  切糕会心_英雄?: 装备增益表现
  /**
   * @name 切糕无双加成数量-英雄
   */
  切糕无双_英雄?: 装备增益表现
  /**
   * @NAME 冬至套装全属性加成
   */
  冬至套装?: 装备增益表现
  /**
   * @name 装备大附魔_伤帽
   */
  大附魔_伤帽?: 装备增益表现
  /**
   * @name 装备大附魔_伤衣
   */
  大附魔_伤衣?: 装备增益表现
  /**
   * @name 装备大附魔_伤腰
   */
  大附魔_伤腰?: 装备增益表现
  /**
   * @name 装备大附魔_伤腕
   */
  大附魔_伤腕?: 装备增益表现
  /**
   * @name 装备大附魔_伤鞋
   */
  大附魔_伤鞋?: 装备增益表现
  /**
   * @name 精简鞋子破防
   */
  精简鞋子破防?: 装备增益表现
  /**
   * @name 精简适应之力
   */
  精简适应之力?: 装备增益表现
  /**
   * @name 精简项链破防
   */
  精简项链破防?: 装备增益表现
  /**
   * @name 精简腰坠破防
   */
  精简腰坠破防?: 装备增益表现
  /**
   * @name 精简暗器特效
   */
  精简暗器特效?: 装备增益表现
  /**
   * @name 精简项链会心
   */
  精简项链会心?: 装备增益表现
  /**
   * @name 精简鞋子会心
   */
  精简鞋子会心?: 装备增益表现
  /**
   * @name 精简腰坠会效
   */
  精简腰坠会效?: 装备增益表现
  /**
   * @name 郭氏力道
   * @description 五彩石/装备携带的郭氏值
   */
  郭氏力道?: number
  /**
   * @name 郭氏身法
   * @description 五彩石/装备携带的郭氏值
   */
  郭氏身法?: number
  /**
   * @name 郭氏元气
   * @description 五彩石/装备携带的郭氏值
   */
  郭氏元气?: number
  /**
   * @name 郭氏根骨
   * @description 五彩石/装备携带的郭氏值
   */
  郭氏根骨?: number
}

/**
 * @name 配装器装备信息模型-装备列表
 */
export interface 选择装备数据类型 {
  /**
   * @name 装备id
   */
  id: number
  /**
   * @name 当前精炼等级
   */
  当前精炼等级: number
  /**
   * @name 镶嵌孔数组
   */
  镶嵌孔数组: 镶嵌孔数组类型[]
  /**
   * @name 附魔名
   */
  附魔?: string
  /**
   * @name 装备部位枚举
   */
  装备部位: 装备位置部位枚举
}

/**
 * @name 装备位置部位枚举
 */
export enum 装备位置部位枚举 {
  '_1' = '帽子',
  '_2' = '衣服',
  '_3' = '腰带',
  '_4' = '护腕',
  '_5' = '下装',
  '_6' = '鞋子',
  '_7' = '项链',
  '_8' = '腰坠',
  '_9' = '戒指',
  '_10' = '戒指',
  '_11' = '暗器',
  '_12' = '武器',
}

/**
 * @name 大附魔部位枚举
 */
export enum 大附魔部位枚举 {
  '帽子' = '大附魔_伤帽',
  '衣服' = '大附魔_伤衣',
  '腰带' = '大附魔_伤腰',
  '护腕' = '大附魔_伤腕',
  '鞋子' = '大附魔_伤鞋',
}

export interface 装备信息数据类型 {
  /**
   * @name 装备基础属性
   * 本部分为装备上所有属性的加成和，方便计算
   * 主属性对各个其他属性的加成在这一步还没有进行计算
   */
  装备基础属性: 角色基础属性类型
  /**
   * @name 装备镶嵌附魔属性
   * 本部分为装备上所有属性的加成和，方便计算
   * 主属性对各个其他属性的加成在这一步还没有进行计算
   */
  装备镶嵌附魔属性: 角色基础属性类型
  /**
   * @name 装备列表
   */
  装备列表: 选择装备数据类型[]
  /**
   * @name 五彩石
   */
  五彩石: string
  /**
   * @name 装备增益
   * 在这里提前保存，避免最后计算的时候再获取。提升效率
   */
  装备增益: 装备增益类型
}

export type 装备增益数据类型 = {
  [key in 装备增益可选类型类型]: 属性加成[]
}

export type 装备增益可选类型类型 =
  | '套装会心会效'
  | '切糕会心'
  | '切糕会心_英雄'
  | '切糕无双'
  | '切糕无双_英雄'
  | '冬至套装'
  | '龙门飞剑武器'
  | '大附魔_伤腰'
  | '大附魔_伤腕'
  | '大附魔_伤鞋'
  | '大附魔_伤帽'
  | '大附魔_伤衣'
  | '水特效武器'
  | '风特效腰坠'
  | '大附魔_伤帽_英雄'
  | '大附魔_伤衣_英雄'
  | '水特效武器_英雄'
  | '风特效腰坠_英雄'
  | '精简鞋子破防'
  | '精简鞋子破防_英雄'
  | '精简鞋子会心'
  | '精简鞋子会心_英雄'
  | '精简腰坠会效'
  | '精简腰坠会效_英雄'
  | '精简腰坠破防'
  | '精简腰坠破防_英雄'
  | '精简项链破防'
  | '精简项链会心'
  | '精简适应之力'
  | '精简暗器特效'
  | '神兵无双'

export interface 装备选择范围类型 {
  /**
   * @name 品级范围
   * 品级的范围，小大等于判断
   */
  品级范围?: number[]
  /**
   * @name 词条类型
   * 词条的类型，例：破招、破防
   */
  词条类型?: string[]
  /**
   * @name 过滤类型
   * 过滤的类型，例：无修
   */
  过滤类型?: string[]
}

export interface 加速档位信息 {
  加速档位: number
  加速值: number
  实际时间: number
}
