import type { 循环数据 } from '@/@types/循环'

/**
 * @name 用于计算的循环数据
 * 该数据可以通过JCL分析器进行获取，也可以自己根据实际情况编写
 */

// 循环
import 北漠阵云 from './北漠阵云.json'
import 嗜血阵云 from './嗜血阵云.json'
import 橙武 from './橙武.json'
// import 测试 from './测试.json'
// import 新奇穴1 from './新奇穴1.json'
// import 新奇穴2 from './新奇穴2.json'

const 计算循环: 循环数据[] = [嗜血阵云, 北漠阵云, 橙武] as 循环数据[]

export default 计算循环
