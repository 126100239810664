import { 技能基础数据模型 } from '@/@types/技能'
import { 获取破招实际系数 } from '@/数据/数据工具/获取技能系数'

import 通用增益 from './技能增益/通用增益/通用增益'
import 刀啸风吟增益 from './技能增益/刀啸风吟'
import 醉斩白蛇增益 from './技能增益/醉斩白蛇'
import 坚壁清野增益 from './技能增益/坚壁清野'
import 上将军印增益 from './技能增益/上将军印'
import 擒龙六斩增益 from './技能增益/擒龙六斩'
import 碎江天增益 from './技能增益/碎江天'
import 破釜沉舟增益 from './技能增益/破釜沉舟'
import 闹须弥增益 from './技能增益/闹须弥'
import 雷走风切增益 from './技能增益/雷走风切'
import 真实伤害系数 from '../../统一数据/真实伤害系数'

const 技能基础数据: 技能基础数据模型[] = [
  {
    技能ID: '32823',
    技能名称: '破',
    统计名称: '破',
    技能伤害系数: 获取破招实际系数(-393478.1440000001),
    技能增益列表: 通用增益,
    技能等级数据: {
      1: { 技能伤害系数: 获取破招实际系数(-393478.1440000001) },
      2: { 技能伤害系数: 获取破招实际系数(-945632.0512) },
      3: { 技能伤害系数: 获取破招实际系数(-842688.1024) },
      4: { 技能伤害系数: 获取破招实际系数(47402.71308799996) },
      5: { 技能伤害系数: 获取破招实际系数(-837183.0784) },
    },
  },
  {
    技能ID: '16419',
    技能名称: '霜风刀法·双刀',
    统计名称: '霜风刀法',
    技能伤害系数: 24,
    武器伤害系数: 1,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '16820',
    技能名称: '霜风刀法·大刀',
    统计名称: '霜风刀法',
    技能伤害系数: 24,
    武器伤害系数: 1,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '16822',
    技能名称: '霜风刀法·鞘刀',
    统计名称: '霜风刀法',
    技能伤害系数: 24,
    武器伤害系数: 1,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '11447', // 11447_17058
    技能名称: '闹须弥(DOT)',
    统计名称: '闹须弥(DOT)',
    技能伤害系数: 280,
    DOT生效间隔: 48,
    DOT跳数: 8,
    基础伤害_基础值: 52,
    技能增益列表: 闹须弥增益,
  },
  {
    // 绝期刷新的闹须弥的系数修改在了技能本身，所以不吃奇穴的绝期的系数加成
    // 幽默策划加强闹须弥的时候只加强了17058没加强17060，导致现在绝期了还没原来高
    技能ID: '11447', // 11447_17060
    技能名称: '闹须弥(DOT)·绝期',
    统计名称: '闹须弥(DOT)',
    技能伤害系数: 476,
    DOT生效间隔: 48,
    DOT跳数: 8,
    基础伤害_基础值: 52,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '16610',
    技能名称: '刀啸风吟',
    技能伤害系数: 586.0318464000002,
    基础伤害_基础值: 250,
    基础伤害_浮动值: 20,
    技能增益列表: 刀啸风吟增益,
  },
  {
    技能ID: '16615',
    技能名称: '醉斩白蛇',
    技能伤害系数: 114.16204800000003,
    基础伤害_基础值: 255,
    基础伤害_浮动值: 20,
    技能增益列表: 醉斩白蛇增益,
  },
  {
    技能ID: '16631',
    技能名称: '雷走风切',
    技能伤害系数: 125.44,
    基础伤害_基础值: 175,
    基础伤害_浮动值: 15,
    技能增益列表: 雷走风切增益,
  },
  {
    技能ID: '16787',
    技能名称: '坚壁清野·5尺',
    统计名称: '坚壁清野',
    技能伤害系数: 266.11199999999997,
    基础伤害_基础值: 430,
    基础伤害_浮动值: 20,
    技能增益列表: 坚壁清野增益,
  },
  {
    技能ID: '16794',
    技能名称: '坚壁清野·残留',
    统计名称: '坚壁清野',
    技能伤害系数: 246.39999999999998,
    基础伤害_基础值: 250,
    基础伤害_浮动值: 20,
    技能增益列表: 坚壁清野增益,
  },
  {
    技能ID: '16804',
    技能名称: '上将军印',
    技能伤害系数: 182.95200000000003,
    基础伤害_基础值: 300,
    基础伤害_浮动值: 15,
    武器伤害系数: 1,
    技能增益列表: 上将军印增益,
  },
  {
    技能ID: '16933',
    技能名称: '惊燕式·一',
    统计名称: '惊燕式',
    秘籍依赖技能: '擒龙六斩',
    技能伤害系数: 99.84,
    基础伤害_基础值: 80,
    基础伤害_浮动值: 10,
    武器伤害系数: 1,
    技能增益列表: 擒龙六斩增益,
  },
  {
    技能ID: '16934',
    技能名称: '惊燕式·二',
    统计名称: '惊燕式',
    秘籍依赖技能: '擒龙六斩',
    技能伤害系数: 124.8,
    基础伤害_基础值: 80,
    基础伤害_浮动值: 10,
    武器伤害系数: 1,
    技能增益列表: 擒龙六斩增益,
  },
  {
    技能ID: '16935',
    技能名称: '逐鹰式·一',
    统计名称: '逐鹰式',
    秘籍依赖技能: '擒龙六斩',
    技能伤害系数: 99.84,
    基础伤害_基础值: 80,
    基础伤害_浮动值: 10,
    武器伤害系数: 1,
    技能增益列表: 擒龙六斩增益,
  },
  {
    技能ID: '16936',
    技能名称: '逐鹰式·二',
    统计名称: '逐鹰式',
    秘籍依赖技能: '擒龙六斩',
    技能伤害系数: 149.76000000000002,
    基础伤害_基础值: 80,
    基础伤害_浮动值: 10,
    武器伤害系数: 1,
    技能增益列表: 擒龙六斩增益,
  },
  {
    技能ID: '16937',
    技能名称: '控鹤式·一',
    统计名称: '控鹤式',
    秘籍依赖技能: '擒龙六斩',
    技能伤害系数: 124.8,
    基础伤害_基础值: 80,
    基础伤害_浮动值: 10,
    武器伤害系数: 1,
    技能增益列表: 擒龙六斩增益,
  },
  {
    技能ID: '16938',
    技能名称: '控鹤式·二',
    统计名称: '控鹤式',
    秘籍依赖技能: '擒龙六斩',
    技能伤害系数: 162.24,
    基础伤害_基础值: 80,
    基础伤害_浮动值: 10,
    武器伤害系数: 1,
    技能增益列表: 擒龙六斩增益,
  },
  {
    技能ID: '16939',
    技能名称: '起凤式·一',
    统计名称: '起凤式',
    秘籍依赖技能: '擒龙六斩',
    技能伤害系数: 149.76000000000002,
    基础伤害_基础值: 80,
    基础伤害_浮动值: 10,
    武器伤害系数: 1,
    技能增益列表: 擒龙六斩增益,
  },
  {
    技能ID: '16940',
    技能名称: '起凤式·二',
    统计名称: '起凤式',
    秘籍依赖技能: '擒龙六斩',
    技能伤害系数: 187.2,
    基础伤害_基础值: 80,
    基础伤害_浮动值: 10,
    武器伤害系数: 1,
    技能增益列表: 擒龙六斩增益,
  },
  {
    技能ID: '16941',
    技能名称: '腾蛟式·一',
    统计名称: '腾蛟式',
    秘籍依赖技能: '擒龙六斩',
    技能伤害系数: 174.72,
    基础伤害_基础值: 80,
    基础伤害_浮动值: 10,
    武器伤害系数: 1,
    技能增益列表: 擒龙六斩增益,
  },
  {
    技能ID: '16942',
    技能名称: '腾蛟式·二',
    统计名称: '腾蛟式',
    秘籍依赖技能: '擒龙六斩',
    技能伤害系数: 199.68,
    基础伤害_基础值: 85,
    基础伤害_浮动值: 15,
    武器伤害系数: 1,
    技能增益列表: 擒龙六斩增益,
  },
  {
    技能ID: '16943',
    技能名称: '擒龙式·一',
    统计名称: '擒龙式',
    秘籍依赖技能: '擒龙六斩',
    技能伤害系数: 174.72,
    基础伤害_基础值: 90,
    基础伤害_浮动值: 15,
    武器伤害系数: 1,
    技能增益列表: 擒龙六斩增益,
  },
  {
    技能ID: '16944',
    技能名称: '擒龙式·二',
    统计名称: '擒龙式',
    秘籍依赖技能: '擒龙六斩',
    技能伤害系数: 224.64000000000001,
    基础伤害_基础值: 95,
    基础伤害_浮动值: 15,
    武器伤害系数: 1,
    技能增益列表: 擒龙六斩增益,
  },
  {
    技能ID: '17092',
    技能名称: '碎江天',
    技能伤害系数: 524.4,
    基础伤害_基础值: 300,
    基础伤害_浮动值: 30,
    武器伤害系数: 2,
    技能增益列表: 碎江天增益,
  },
  {
    技能ID: '20991',
    技能名称: '破釜沉舟',
    技能伤害系数: 602.004645,
    基础伤害_基础值: 350,
    基础伤害_浮动值: 20,
    武器伤害系数: 2,
    技能增益列表: 破釜沉舟增益,
  },
  {
    技能ID: '34585',
    技能名称: '绝期',
    技能伤害系数: 120,
    基础伤害_基础值: 55,
    基础伤害_浮动值: 5,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '38533',
    技能名称: '碎影',
    技能伤害系数: 950.4000000000001,
    基础伤害_基础值: 55,
    基础伤害_浮动值: 5,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '25782',
    技能名称: '上将军印·神兵',
    技能伤害系数: 25,
    基础伤害_浮动值: 2,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '39106',
    技能名称: '背水沉舟',
    技能伤害系数: 1140.4800000000002,
    基础伤害_基础值: 55,
    基础伤害_浮动值: 5,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '38537',
    技能名称: '楚歌',
    统计名称: '楚歌',
    技能伤害系数: 320,
    基础伤害_基础值: 55,
    基础伤害_浮动值: 5,
    技能增益列表: 通用增益,
    技能等级数据: {
      1: { 技能伤害系数: 320.0 },
      2: { 技能伤害系数: 384.0 },
      3: { 技能伤害系数: 416.0 },
      4: { 技能伤害系数: 448.0 },
      5: { 技能伤害系数: 480.0 },
      6: { 技能伤害系数: 560.0 },
      7: { 技能伤害系数: 672.0 },
      8: { 技能伤害系数: 728.0 },
    },
  },
  // ====================
  ...真实伤害系数,
]

const 技能增益 = {
  通用增益,
}

export { 技能增益 }

export default 技能基础数据
