import type { 循环数据 } from '@/@types/循环'

/**
 * @name 用于计算的循环数据
 * 该数据可以通过JCL分析器进行获取，也可以自己根据实际情况编写
 */

// 循环
// import 测试 from './测试.json'
import 武学助手 from './武学助手.json'
import 紫武常规 from './紫武常规.json'
import 紫武优解手打 from './紫武优解手打.json'
import 橙武常规 from './橙武常规.json'
import 橙武极限处理 from './橙武极限处理.json'

const 计算循环: 循环数据[] = [
  武学助手,
  紫武常规,
  紫武优解手打,
  橙武常规,
  橙武极限处理,
] as 循环数据[]

export default 计算循环
