import type { 循环数据 } from '@/@types/循环'

/**
 * @name 用于计算的循环数据
 * 该数据可以通过JCL分析器进行获取，也可以自己根据实际情况编写
 */

// 循环
// import 测试循环 from './测试循环.json'
import 临助手 from './临助手.json'
import CW斗 from './CW斗.json'
import CW临 from './CW临.json'

const 计算循环: 循环数据[] = [
  // 测试循环,
  临助手,
  CW斗,
  CW临,
] as 循环数据[]

export default 计算循环
