// 根据账号角色导入
// import { getEquipDataByName } from '@/api'
import { Alert, Button, Image, Input, Modal, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import ServerCascader from '@/组件/ServerCascader'
import { getEquipbyUidOrName, getEquipbyUidOrNameLocal } from '@/api'
import { GLOBAL_CDN_PREFIX } from '@/工具函数/const'
import { 获取页面参数 } from '@/工具函数/help'
// import GeetestCaptcha from '@/组件/GeetestCaptcha'
import { getEquipData } from './util'
import 心法枚举 from '@/数据/静态数据/心法枚举.json'
// import 体型枚举 from '@/数据/静态数据/体型数据.json'
import CryptoJs from 'crypto-js'
import './index.css'

const 教程_1 = `${GLOBAL_CDN_PREFIX}/account_tip_1.png`
const 教程_2 = `${GLOBAL_CDN_PREFIX}/account_tip_2.png`

function AccountImport({ onOk }) {
  const [loading, setLoading] = useState(false)
  const [helpVisible, setHelpVisible] = useState(false)
  const [name, changeName] = useState<string>()
  const [server, changeServer] = useState<string>()
  const [data, setData] = useState<any>(undefined)
  const [errorMsg, setErrorMsg] = useState<string>('')

  const urlName = 获取页面参数('name')

  useEffect(() => {
    if (urlName) {
      changeName(urlName)
    }
  }, [urlName])

  const beforeGetPzData = () => {
    handleGetPzData()
  }

  const getSignature = (data) => {
    const message = Object.entries(data)
      .sort(([a], [b]) => a.localeCompare(b))
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    const expectedSignature = CryptoJs.HmacSHA256(message, 'baishifendakelexuebi').toString(
      CryptoJs.enc.Hex
    )

    return expectedSignature
  }

  /**
   * !有这功夫自己多学习一下不好吗？
   * !有这功夫自己多学习一下不好吗？
   * !有这功夫自己多学习一下不好吗？
   * !有这功夫自己多学习一下不好吗？
   * !有这功夫自己多学习一下不好吗？
   */

  const handleGetPzData = async (propsName?, propsServer?) => {
    const apiName = propsName || name
    const apiServer = propsServer || server

    if (!apiName || !apiServer?.length) {
      return
    }
    setLoading(true)
    setData(undefined)
    setErrorMsg('')
    let userInfo: any = {}
    let errorMessage = ''
    let equipList: any = null
    try {
      const params = {
        zone: apiServer?.[0],
        server: apiServer?.[1],
        name: apiName,
        timestamp: new Date().getTime(),
      }
      const signature = getSignature(params)
      const request = window?.location?.href?.includes('localhost')
        ? getEquipbyUidOrNameLocal
        : getEquipbyUidOrName
      const res = await request({
        ...params,
        signature,
      })?.then((res) => res?.data?.data)

      equipList = res?.Equips
      userInfo = { ...userInfo, ...res, name: apiName }
      if (res?.data?.message) {
        errorMessage = res?.data?.message
      } else if (!equipList?.length) {
        errorMessage = '没有查询到角色信息，请稍后再试'
      }
    } catch (e) {
      console.log('e', e)
      errorMessage = '服务异常，可能为不可抗因素导致不可用'
    }
    setLoading(false)
    if (!errorMessage) {
      const equipData = getPzData(equipList)
      if (equipData) {
        setData({
          userInfo,
          equipData,
        })
      } else {
        setErrorMsg('没有查询到角色信息，请稍后再试')
      }
    } else {
      setErrorMsg(errorMessage)
    }
    return
  }

  const handleClickImport = () => {
    if (data?.equipData?.equip) {
      onOk(data?.equipData?.equip)
    }
  }

  // const captchaHandler = (captchaObj: any) => {
  //   // eslint-disable-next-line @typescript-eslint/no-extra-semi
  //   ;(window as any).captchaObj = captchaObj
  //   captchaObj
  //     .appendTo('#captcha')
  //     .onReady(function () {
  //       console.info('ready')
  //     })
  //     .onBoxShow(function () {
  //       console.info('boxShow')
  //     })
  //     .onError(function (e: any) {
  //       console.info('error', e)
  //     })
  //     .onSuccess(() => {
  //       handleGetPzData()
  //     })
  // }

  return (
    <div>
      {/* <Alert
        type='error'
        style={{ marginBottom: 16 }}
        message={
          <div>
            <p>因不可抗力因素，本功能暂时无法使用</p>
          </div>
        }
      /> */}
      <Alert
        type='warning'
        style={{ marginBottom: 16 }}
        message={
          <div>
            <p>本功能仅作为查询使用者自身账号属性，用作辅助选择配装。</p>
            <p>严禁使用本功能对他人进行出警、拉踩、诋毁等恶意行为。</p>
            <p>若使用本功能出现纠纷，本人概不负责。</p>
            <p>
              <a onClick={() => setHelpVisible(true)}>如何获取角色UID</a>
            </p>
          </div>
        }
      />
      <div className='account-daoru-input-wrap no-padding'>
        <ServerCascader
          className={'account-daoru-form-server'}
          value={server}
          onChange={(e) => changeServer(e)}
          callback={beforeGetPzData}
        />
        <Input.Search
          className='account-daoru-form-content'
          value={name}
          style={{ width: 200 }}
          loading={loading}
          onChange={(e) => changeName(e.target.value.trim())}
          placeholder={'请输入角色名称或UID'}
          onPressEnter={() => {
            if (server?.length) {
              beforeGetPzData()
            }
          }}
        />
        {/* <div id='captcha' style={{ width: 0, height: 0 }}>
            <GeetestCaptcha handler={captchaHandler} />
          </div> */}
        <Button
          type='primary'
          disabled={loading || !name || !server?.length}
          onClick={() => beforeGetPzData()}
        >
          查询角色
        </Button>
      </div>
      {loading ? (
        <div className='account-daoru-text-wrap'>
          正在获取配装方案
          <Spin style={{ marginLeft: 12 }} spinning={true} />
        </div>
      ) : (
        <>
          {data ? (
            <div className={'account-daoru-success'}>
              <p className='account-daoru-success-tip'>成功获取配装方案</p>
              <div className='account-daoru-success-content'>
                <div className='account-daoru-success-info'>
                  {data?.userInfo?.personAvatar ? (
                    <img
                      className='account-daoru-success-avatar'
                      src={data?.userInfo?.personAvatar}
                      alt=''
                    />
                  ) : 心法枚举?.[data?.userInfo?.Kungfu?.KungfuID]?.icon ? (
                    <img
                      className='account-daoru-success-avatar'
                      src={心法枚举?.[data?.userInfo?.Kungfu?.KungfuID]?.icon}
                      alt=''
                    />
                  ) : null}
                  <div className='account-daoru-text-content'>
                    <div className='account-daoru-success-title' title={data?.showData?.title}>
                      {data?.userInfo?.isUidSearch
                        ? data?.userInfo?.roleName
                        : data?.userInfo?.name}
                    </div>
                    <span
                      className={
                        !心法枚举?.[data?.userInfo?.Kungfu?.KungfuID]?.name
                          ? 'account-daoru-success-name-error'
                          : ''
                      }
                    >
                      {心法枚举?.[data?.userInfo?.Kungfu?.KungfuID]?.name || '门派未识别'}
                    </span>
                    {/* {` | `} */}
                    {/* <span
                      className={
                        !体型枚举?.[data?.userInfo?.Person?.body]
                          ? 'account-daoru-success-name-error'
                          : ''
                      }
                    >
                      {体型枚举?.[data?.userInfo?.Person?.body] || '体型未识别'}
                    </span> */}
                    {` | `}
                    <span
                      className={
                        !data?.userInfo?.TotalEquipsScore ? 'account-daoru-success-name-error' : ''
                      }
                    >
                      {data?.userInfo?.TotalEquipsScore
                        ? `${data?.userInfo?.TotalEquipsScore}`
                        : '装分未识别'}
                    </span>
                  </div>
                  <Button type='primary' onClick={handleClickImport}>
                    导入
                  </Button>
                </div>
              </div>
              {data?.equipData?.未识别装备列表?.length ||
              data?.equipData?.未内置附魔列表?.length ? (
                <div className={'account-daoru-res-tips'}>
                  <h1 className={'account-daoru-res-tips-title'}>
                    存在以下信息未识别，会跳过导入，导入后请手动修改
                  </h1>
                  {data?.equipData?.未识别装备列表?.length ? (
                    <div className={'account-daoru-res-tips-text'}>
                      <p className={'account-daoru-res-tips-name'}>未识别装备：</p>
                      {data?.equipData?.未识别装备列表?.join('、')}
                    </div>
                  ) : null}
                  {data?.equipData?.未内置附魔列表?.length ? (
                    <div className={'account-daoru-res-tips-text'}>
                      <p className={'account-daoru-res-tips-name'}>未支持附魔：</p>
                      {data?.equipData?.未内置附魔列表?.join('、')}
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          ) : null}
          {errorMsg ? (
            <div className='account-daoru-text-wrap account-daoru-text-error'>{errorMsg}</div>
          ) : null}
        </>
      )}
      <Modal
        footer={null}
        title='如何获取角色UID'
        open={helpVisible}
        onCancel={() => setHelpVisible(false)}
      >
        <div>
          1、发送你的角色至任意聊天频道。
          <Image className='account-daoru-help-img' src={教程_1} />
        </div>
        <div>
          2、按住「Ctrl」鼠标移动到名称上，复制玩家ID。
          <Image className='account-daoru-help-img' src={教程_2} />
        </div>
      </Modal>
    </div>
  )
}

export default AccountImport

// 获取配装数据
const getPzData = (data) => {
  // 获取展示信息
  if (data) {
    const res = getEquipData(data)
    console.info('res', res)
    if (Object.keys(res?.equip)?.length !== 0) {
      return res
    } else return null
  }
  return null
}

// const 校验门派 = (校验名称) => {
//   const { 简写 } = 获取当前数据()
//   if (['shxj', 'w_shxj']?.includes(简写)) {
//     return 校验名称 === '万灵山庄'
//   } else if (简写 === 'lhj') {
//     return 校验名称 === '蓬莱'
//   } else if (简写 === 'txj') {
//     return 校验名称 === '衍天宗'
//   } else if (['gfj', 'w_gfj']?.includes(简写)) {
//     return 校验名称 === '刀宗'
//   } else if (['wf', 'w_wf']?.includes(简写)) {
//     return 校验名称 === '北天药宗'
//   } else if (简写 === 'hjy') {
//     return 校验名称 === '万花'
//   }
//   return false
// }
