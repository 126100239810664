/**
 * @name 心法模块-花间游
 * 模块写入者：唐宋
 */
import type { 心法配置类型 } from '@/心法模块/interface'

import 默认数据 from './默认数据'
import 技能系数, { 技能增益 } from './技能系数'
import 奇穴数据 from './奇穴'
import 计算循环 from './计算循环'
import 端游花间游数据 from '../花间游/index'

const 心法配置: 心法配置类型 = {
  ...端游花间游数据,
  名称: '花间游_悟',
  // 内部测试: true,
  数据提供: '数据：考拉 唐宋；测试：蔓海赋尘',
  所属门派: '万花',
  简写: 'w_hjy',
  心法所属端: '无界',
  奇穴数据: 奇穴数据,
  技能系数: 技能系数,
  技能增益: 技能增益,
  计算循环: 计算循环,
  默认数据: 默认数据,
  基础GCD: 1,
  覆盖率: { 套装会心会效: 0.53 },
  系统配置: {
    ...(端游花间游数据.系统配置 || {}),
    心法图标: 'https://icon.jx3box.com/icon/100408.png',
  },
}

export default 心法配置
