import { 团队增益数据类型 } from '@/@types/团队增益'
import { 属性类型 } from '@/@types/属性'
import { 按数字生成数组 } from '@/工具函数/help'
import { 获取心法数据 } from '@/心法模块'

const 主属性 = 获取心法数据()?.主属性
const 郭氏主属性加成类型 =
  主属性 === '力道'
    ? 属性类型.郭氏力道
    : 主属性 === '身法'
    ? 属性类型.郭氏身法
    : 主属性 === '元气'
    ? 属性类型.郭氏元气
    : 属性类型.郭氏根骨

const 通用团队增益数据: 团队增益数据类型[] = [
  {
    增益名称: '袖气',
    层数: 1,
    覆盖率: 100,
    增益图片: 'https://icon.jx3box.com/icon/907.png',
    增益描述: '全属性提升【317】点',
    增益来源: '七秀',
    团队增益类型: '常用增益',
    增益集合: [{ 属性: 属性类型.全属性, 值: 317 }],
  },
  {
    增益名称: '清心',
    层数: 1,
    覆盖率: 100,
    增益图片: 'https://icon.jx3box.com/icon/414.png',
    增益描述: '基础气血提升【5%】',
    增益来源: '万花',
    团队增益类型: '常用增益',
    增益集合: [{ 属性: 属性类型.郭氏基础气血上限, 值: 52 }],
  },
  {
    增益名称: '共战江湖',
    层数: 1,
    覆盖率: 100,
    增益图片: 'https://icon.jx3box.com/icon/2148.png',
    增益描述: '每层提升【5%】最终伤害和治疗效果,【5%】最终气血值',
    增益来源: '共战江湖',
    团队增益类型: '常用增益',
    层数选项数组: 按数字生成数组(5),
    增益集合: [
      { 属性: 属性类型.通用增伤, 值: 51 / 1024 },
      { 属性: 属性类型.郭氏最终气血上限, 值: 51 },
    ],
  },

  {
    增益名称: '蒸鱼菜盘',
    层数: 1,
    覆盖率: 100,
    增益图片: 'https://icon.jx3box.com/icon/552.png',
    增益描述: '无双提升【1334】点',
    增益来源: '帮会宴席',
    团队增益类型: '常用增益',
    冲突增益: ['肘子菜盘'],
    增益集合: [{ 属性: 属性类型.无双等级, 值: 1334 }],
  },
  {
    增益名称: '肘子菜盘',
    层数: 1,
    覆盖率: 100,
    增益图片: 'https://icon.jx3box.com/icon/546.png',
    增益描述: '气血上限提高【2%】，内力上限提高【2%】',
    增益来源: '帮会宴席',
    团队增益类型: '常用增益',
    冲突增益: ['蒸鱼菜盘'],
    增益集合: [{ 属性: 属性类型.郭氏基础气血上限, 值: 20 }],
  },
  {
    增益名称: '同泽宴',
    层数: 1,
    覆盖率: 100,
    增益图片: 'https://icon.jx3box.com/icon/9025.png',
    增益描述: '无双提升【553】点，破招值提升【553】点',
    增益来源: '团队宴席',
    团队增益类型: '常用增益',
    增益集合: [
      { 属性: 属性类型.无双等级, 值: 553 },
      { 属性: 属性类型.破招值, 值: 553 },
    ],
  },
  {
    增益名称: '炼狱水煮鱼',
    层数: 1,
    覆盖率: 100,
    增益图片: 'https://icon.jx3box.com/icon/7667.png',
    增益描述: '无双提升【100】点，破招值提升【100】点',
    增益来源: '炼狱水煮鱼_奇遇_制作食物',
    团队增益类型: '食物增益',
    冲突增益: ['百炼水煮鱼'],
    增益集合: [
      { 属性: 属性类型.无双等级, 值: 100 },
      { 属性: 属性类型.破招值, 值: 100 },
    ],
  },
  {
    增益名称: '百炼水煮鱼',
    层数: 1,
    覆盖率: 100,
    增益图片: 'https://icon.jx3box.com/icon/7667.png',
    增益描述: '无双提升【450】点，破招值提升【450】点',
    增益来源: '百炼水煮鱼_制作食物',
    团队增益类型: '食物增益',
    冲突增益: ['炼狱水煮鱼'],
    增益集合: [
      { 属性: 属性类型.无双等级, 值: 450 },
      { 属性: 属性类型.破招值, 值: 450 },
    ],
  },
  {
    增益名称: '飘黄',
    层数: 90,
    覆盖率: 100, // 10秒 60秒CD
    增益图片: 'https://icon.jx3box.com/icon/15692.png',
    增益描述: '施展伤害招式附带一段额外伤害，最多每1.5秒触发一次',
    增益来源: '灵素',
    团队增益类型: '治疗Buff增益',
    层数选项数组: 按数字生成数组(100),
    增益集合: [
      // 特殊处理
    ],
  },
  {
    增益名称: '仙王蛊鼎',
    层数: 90,
    覆盖率: 25,
    增益图片: 'https://icon.jx3box.com/icon/2747.png',
    增益描述: '每层使无双值提升【360】点',
    增益来源: '补天诀_仙王蛊鼎',
    层数选项数组: 按数字生成数组(100),
    团队增益类型: '治疗Buff增益',
    增益集合: [{ 属性: 属性类型.无双等级, 值: 360 }],
    支持快照增益轴: true,
    覆盖率支持手动录入: true,
  },
  {
    增益名称: '左旋右转',
    层数: 90,
    覆盖率: 100,
    增益图片: 'https://icon.jx3box.com/icon/904.png',
    增益描述: '每层使无双值提升【100】点',
    团队增益类型: '治疗Buff增益',
    增益来源: '云裳心经',
    层数选项数组: 按数字生成数组(100),
    覆盖率支持手动录入: true,
    增益集合: [{ 属性: 属性类型.无双等级, 值: 100 }],
  },
  {
    增益名称: '秋肃',
    层数: 90,
    覆盖率: 100,
    增益图片: 'https://icon.jx3box.com/icon/403.png',
    增益描述: '每层使无双值提升【100】点',
    增益来源: '离经易道',
    层数选项数组: 按数字生成数组(100),
    覆盖率支持手动录入: true,
    团队增益类型: '治疗Buff增益',
    增益集合: [{ 属性: 属性类型.无双等级, 值: 100 }],
  },
  {
    增益名称: '庄周梦',
    层数: 90,
    覆盖率: 100,
    增益图片: 'https://icon.jx3box.com/icon/9555.png',
    增益描述: '每层使目标的无双等级提高【100】点',
    增益来源: '相知',
    团队增益类型: '治疗Buff增益',
    层数选项数组: 按数字生成数组(100),
    覆盖率支持手动录入: true,
    增益集合: [{ 属性: 属性类型.无双等级, 值: 100 }],
  },
  {
    增益名称: '皎素',
    层数: 1,
    覆盖率: 14,
    增益图片: 'https://icon.jx3box.com/icon/17706.png',
    增益描述: '会心效果提高【5%】',
    增益来源: '离经易道',
    团队增益类型: '治疗Buff增益',
    覆盖率支持手动录入: true,
    增益集合: [{ 属性: 属性类型.郭氏全会心效果等级, 值: 51 }],
  },
  {
    增益名称: '配伍',
    层数: 5,
    覆盖率: 100,
    增益图片: 'https://icon.jx3box.com/icon/15702.png',
    增益描述: '命中目标使其提高【1%】的力道、身法、元气和根骨，最多叠加5层，持续10秒',
    增益来源: '灵素_中和治疗_听说全团满血默认加一队第一个_所以懂了吗？',
    团队增益类型: '治疗Buff增益',
    层数选项数组: 按数字生成数组(5),
    覆盖率支持手动录入: true,
    增益集合: [{ 属性: 郭氏主属性加成类型, 值: 10 }],
  },
  {
    增益名称: '左旋右转·悟',
    层数: 90,
    增益心法端: '无界',
    覆盖率: 100,
    增益图片: 'https://icon.jx3box.com/icon/904.png',
    增益描述: '每层使无双值提升【90】点',
    团队增益类型: '治疗Buff增益',
    增益来源: '云裳·悟',
    层数选项数组: 按数字生成数组(100),
    覆盖率支持手动录入: true,
    增益集合: [{ 属性: 属性类型.无双等级, 值: 90 }],
  },
  {
    增益名称: '九微飞花·悟',
    层数: 1,
    覆盖率: 100,
    增益心法端: '无界',
    增益图片: 'https://icon.jx3box.com/icon/13417.png',
    增益描述: '造成伤害提高【5%】',
    增益来源: '云裳·悟',
    团队增益类型: '治疗Buff增益',
    覆盖率支持手动录入: true,
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 52 / 1024 }],
  },
  {
    增益名称: '泠风解怀·悟',
    增益心法端: '无界',
    层数: 1,
    覆盖率: 30,
    增益图片: 'https://icon.jx3box.com/icon/17172.png',
    增益描述: '造成伤害提高【5%】',
    增益来源: '云裳·悟',
    团队增益类型: '治疗Buff增益',
    覆盖率支持手动录入: true,
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 52 / 1024 }],
  },
  {
    增益名称: '仙王蛊鼎·悟',
    增益心法端: '无界',
    层数: 90,
    覆盖率: 40,
    增益图片: 'https://icon.jx3box.com/icon/2747.png',
    增益描述: '每层使无双值提升【220】点',
    增益来源: '补天·悟 秘籍',
    层数选项数组: 按数字生成数组(100),
    团队增益类型: '治疗Buff增益',
    增益集合: [{ 属性: 属性类型.无双等级, 值: 220 }],
  },
  {
    增益名称: '梅花三弄·悟',
    增益心法端: '无界',
    层数: 90,
    覆盖率: 30,
    增益图片: 'https://icon.jx3box.com/icon/9555.png',
    增益描述: '每层使目标的无双等级提高【90】点',
    增益来源: '相知·悟',
    团队增益类型: '治疗Buff增益',
    层数选项数组: 按数字生成数组(100),
    覆盖率支持手动录入: true,
    增益集合: [{ 属性: 属性类型.无双等级, 值: 90 }],
  },
  {
    增益名称: '秋素·悟',
    增益心法端: '无界',
    层数: 90,
    覆盖率: 66,
    增益图片: 'https://icon.jx3box.com/icon/403.png',
    增益描述: '每层使目标的无双等级提高【100】点',
    增益来源: '离经·悟',
    团队增益类型: '治疗Buff增益',
    层数选项数组: 按数字生成数组(100),
    覆盖率支持手动录入: true,
    增益集合: [{ 属性: 属性类型.无双等级, 值: 100 }],
  },
  {
    增益名称: '中和·悟',
    增益心法端: '无界',
    层数: 90,
    覆盖率: 80,
    增益图片: 'https://icon.jx3box.com/icon/16025.png',
    增益描述: '每层使目标的无双等级提高【66】点',
    增益来源: '灵素·悟_听说全团满血默认加一队第一个。覆盖率能到80%。',
    团队增益类型: '治疗Buff增益',
    层数选项数组: 按数字生成数组(100),
    覆盖率支持手动录入: true,
    增益集合: [{ 属性: 属性类型.无双等级, 值: 66 }],
  },
  {
    增益名称: '逐云寒蕊·悟',
    增益心法端: '无界',
    层数: 90,
    覆盖率: 50,
    增益图片: 'https://icon.jx3box.com/icon/15692.png',
    增益描述: '每层使目标的无双等级提高【200】点',
    团队增益类型: '治疗Buff增益',
    层数选项数组: 按数字生成数组(100),
    覆盖率支持手动录入: true,
    增益集合: [{ 属性: 属性类型.无双等级, 值: 200 }],
  },
  {
    增益名称: '戒火',
    层数: 1,
    覆盖率: 100,
    增益图片: 'https://icon.jx3box.com/icon/3798.png',
    增益描述: '受到的伤害提高【2%】',
    增益来源: '明尊琉璃体',
    团队增益类型: '目标减益',
    增益集合: [{ 属性: 属性类型.易伤增伤, 值: 21 / 1024 }],
  },
  {
    增益名称: '戒火·悟',
    增益心法端: '无界',
    层数: 1,
    覆盖率: 100,
    增益图片: 'https://icon.jx3box.com/icon/3798.png',
    增益描述: '受到的伤害提高【5%】',
    增益来源: '明尊琉璃体·悟',
    团队增益类型: '目标减益',
    增益集合: [{ 属性: 属性类型.易伤增伤, 值: 21 / 1024 }],
  },
  {
    增益名称: '寒啸千军',
    层数: 1,
    覆盖率: 50,
    覆盖率支持手动录入: true,
    增益图片: 'https://icon.jx3box.com/icon/7514.png',
    增益描述: '无双提高【5%】',
    增益来源: '铁骨衣',
    团队增益类型: '坦克Buff增益',
    增益集合: [{ 属性: 属性类型.郭氏无双, 值: 51 }],
    支持快照增益轴: true,
  },
  {
    增益名称: '振奋',
    层数: 90,
    覆盖率: 100,
    增益图片: 'https://icon.jx3box.com/icon/6436.png',
    增益描述: '每层使无双等级提高【80】点',
    增益来源: '铁骨衣',
    团队增益类型: '坦克Buff增益',
    层数选项数组: 按数字生成数组(100),
    增益集合: [{ 属性: 属性类型.无双等级, 值: 80 }],
  },
  {
    增益名称: '号令三军',
    层数: 90,
    覆盖率: 20,
    增益图片: 'https://icon.jx3box.com/icon/7513.png',
    增益描述: '受到“号令三军”鼓舞，每层提高无双值【594】点',
    增益来源: '铁牢律',
    团队增益类型: '坦克Buff增益',
    层数选项数组: 按数字生成数组(100),
    覆盖率支持手动录入: true,
    增益集合: [{ 属性: 属性类型.无双等级, 值: (594 + 297) / 2 }],
    支持快照增益轴: true,
  },
  {
    增益名称: '弘法',
    层数: 90,
    覆盖率: 50,
    覆盖率支持手动录入: true,
    增益图片: 'https://icon.jx3box.com/icon/7509.png',
    增益描述: '无双等级提高【660】点、受到的所有伤害和产生的威胁值全部转移给释放者',
    增益来源: '洗髓经_奇穴',
    团队增益类型: '坦克Buff增益',
    层数选项数组: 按数字生成数组(100),
    增益集合: [{ 属性: 属性类型.无双等级, 值: 660 }],
    支持快照增益轴: true,
  },
  {
    增益名称: '朝圣',
    层数: 90,
    覆盖率: 7, // 持续8秒，CD5分钟，一般一直刷2分钟一次
    增益图片: 'https://icon.jx3box.com/icon/3791.png',
    增益描述: '无双等级提高【440】点，每秒回复5%最大气血值',
    增益来源: '明尊琉璃体_朝圣言',
    团队增益类型: '坦克Buff增益',
    冲突增益: ['圣浴明心'],
    层数选项数组: 按数字生成数组(100),
    覆盖率支持手动录入: true,
    增益集合: [{ 属性: 属性类型.无双等级, 值: 440 }],
    支持快照增益轴: true,
  },
  {
    增益名称: '圣浴明心',
    层数: 90,
    覆盖率: 7, // 持续8秒，CD5分钟，一般一直刷2分钟一次
    增益图片: 'https://icon.jx3box.com/icon/7483.png',
    增益描述: '无双等级提高【660】点，每秒回复5%最大气血值',
    增益来源: '明尊琉璃体_奇穴增伤朝圣言',
    团队增益类型: '坦克Buff增益',
    冲突增益: ['朝圣'],
    层数选项数组: 按数字生成数组(100),
    覆盖率支持手动录入: true,
    增益集合: [{ 属性: 属性类型.无双等级, 值: 660 }],
    支持快照增益轴: true,
  },
  {
    增益名称: '盾挡·悟',
    增益心法端: '无界',
    层数: 90,
    覆盖率: 66,
    层数选项数组: 按数字生成数组(100),
    覆盖率支持手动录入: true,
    增益图片: 'https://icon.jx3box.com/icon/101339.png',
    增益描述: '无双等级提高【80】',
    增益来源: '铁骨衣·悟',
    团队增益类型: '坦克Buff增益',
    增益集合: [{ 属性: 属性类型.全破防等级, 值: 80 }],
  },
  {
    增益名称: '寒啸千军·悟',
    增益心法端: '无界',
    层数: 1,
    覆盖率: 30,
    覆盖率支持手动录入: true,
    增益图片: 'https://icon.jx3box.com/icon/7514.png',
    增益描述: '鼓舞友方士气，使团队成员无双提高【5%】',
    增益来源: '铁骨衣·悟',
    团队增益类型: '坦克Buff增益',
    增益集合: [{ 属性: 属性类型.郭氏无双, 值: 51 }],
  },
  {
    增益名称: '号令三军·悟',
    增益心法端: '无界',
    层数: 90,
    覆盖率: 50,
    增益图片: 'https://icon.jx3box.com/icon/7513.png',
    增益描述: '每层提高无双【500】',
    增益来源: '铁牢律·悟',
    团队增益类型: '坦克Buff增益',
    层数选项数组: 按数字生成数组(100),
    覆盖率支持手动录入: true,
    增益集合: [{ 属性: 属性类型.无双等级, 值: 500 }],
  },
  {
    增益名称: '弘法·悟',
    增益心法端: '无界',
    层数: 90,
    覆盖率: 30,
    覆盖率支持手动录入: true,
    层数选项数组: 按数字生成数组(100),
    增益图片: 'https://icon.jx3box.com/icon/7509.png',
    增益描述: '每层无双提高【660】',
    增益来源: '洗髓经·悟',
    团队增益类型: '坦克Buff增益',
    增益集合: [{ 属性: 属性类型.无双等级, 值: 660 }],
  },
  {
    增益名称: '禅语',
    层数: 90,
    覆盖率: 100,
    增益图片: 'https://icon.jx3box.com/icon/4526.png',
    增益描述:
      '“大狮子吼”施展后，自身每【2100】点基础体质使团队成员气血上限提高【1760】点，最多100层。持续15秒。',
    增益来源: '洗髓经_奇穴',
    团队增益类型: '坦克Buff增益',
    层数选项数组: 按数字生成数组(100),
    增益集合: [{ 属性: 属性类型.额外气血上限, 值: 1760 }],
  },
  {
    增益名称: '瘦肉粽',
    层数: 1,
    覆盖率: 100,
    增益图片: 'https://icon.jx3box.com/icon/2584.png',
    增益描述: '增加【6%】会心',
    增益来源: '端午节日活动',
    团队增益类型: '节日增益',
    冲突增益: ['瓜仁粽', '瑰栗粽', '蛋黄粽', '芦兜粽'],
    增益集合: [{ 属性: 属性类型.全会心百分比, 值: 0.06 }],
  },
  {
    增益名称: '瓜仁粽',
    层数: 1,
    覆盖率: 100,
    增益图片: 'https://icon.jx3box.com/icon/2582.png',
    增益描述: '增加【6%】无双',
    增益来源: '端午节日活动',
    团队增益类型: '节日增益',
    冲突增益: ['瘦肉粽', '瑰栗粽', '蛋黄粽', '芦兜粽'],
    增益集合: [{ 属性: 属性类型.郭氏额外无双, 值: 61 }],
  },
  {
    增益名称: '瑰栗粽',
    层数: 1,
    覆盖率: 100,
    增益图片: 'https://icon.jx3box.com/icon/2583.png',
    增益描述: '增加【1360】武器伤害',
    增益来源: '端午节日活动',
    团队增益类型: '节日增益',
    冲突增益: ['瘦肉粽', '瓜仁粽', '蛋黄粽', '芦兜粽'],
    增益集合: [{ 属性: 属性类型.武器伤害, 值: 1360 }],
  },
  {
    增益名称: '百锻',
    层数: 1,
    覆盖率: 25,
    增益图片: 'https://icon.jx3box.com/icon/13376.png',
    增益描述: '武器伤害提升【100%】且造成伤害会追加一次伤害（追加伤害计算器未内置）',
    增益来源: '藏剑山庄',
    团队增益类型: '稀缺增益',
    增益集合: [{ 属性: 属性类型.郭氏武器伤害, 值: 1024 }],
  },
  {
    增益名称: '疏狂',
    层数: 1,
    覆盖率: 20,
    增益图片: 'https://icon.jx3box.com/icon/8638.png',
    增益描述: '基础攻击力提高【30%】',
    覆盖率支持手动录入: true,
    增益来源: '北傲诀_奇穴',
    团队增益类型: '稀缺增益',
    增益集合: [{ 属性: 属性类型.郭氏全基础攻击, 值: 307 }],
  },
  {
    增益名称: '吟冬卧雪',
    层数: 8,
    覆盖率: 100,
    增益图片: 'https://icon.jx3box.com/icon/138.png',
    增益描述: '全会心提高151点，全破防提高151点',
    增益来源: '节日冬至吟冬卧雪食盒效果',
    团队增益类型: '食物增益',
    层数选项数组: 按数字生成数组(8),
    增益集合: [
      { 属性: 属性类型.全破防等级, 值: 151 },
      { 属性: 属性类型.全会心等级, 值: 151 },
    ],
  },
  {
    增益名称: '列雷',
    层数: 1,
    覆盖率: 8,
    覆盖率支持手动录入: true,
    增益图片: 'https://icon.jx3box.com/icon/7113.png',
    增益描述: '鸣雷阵阵，琴剑出。内外功基础攻击力提升【700】点',
    增益来源: '莫问特效武器',
    团队增益类型: '稀缺增益',
    增益集合: [{ 属性: 属性类型.全基础攻击, 值: 700 }],
  },
  {
    增益名称: '弄梅',
    层数: 1,
    覆盖率: 75,
    覆盖率支持手动录入: true,
    增益图片: 'https://icon.jx3box.com/icon/7059.png',
    增益描述: '使自身伤害招式无视目标【20】%内外功防御等级并提升自身【700】点破防等级',
    增益来源: '相知特效武器',
    团队增益类型: '稀缺增益',
    增益集合: [
      { 属性: 属性类型.全破防等级, 值: 700 },
      { 属性: 属性类型.郭氏全无视防御, 值: 205 },
    ],
  },
]

export default 通用团队增益数据
