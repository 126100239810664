export default [
  {
    version: '[丝路风语] 2.1.16',
    date: '2024-12-12 11:30:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='2.1.16.0'>
        <p>同步12月12日更新</p>
        <p>修复大附魔手鞋伤害数值</p>
        <p>修复部分情况下大附魔装备分数计算异常的问题，当前大附魔固定按所在部位装备品级计算</p>
      </p>,
      '易筋经',
      <p style={{ paddingLeft: 12 }} key='2.1.16.1'>
        <p>修改降魔系数</p>
        <p>韦陀降魔 87.81045569999999 {'=>'} 111.56318396685</p>
        <p>拿云降魔 100.91250000000001 {'=>'} 132.69665784375002</p>
      </p>,
    ],
  },
  {
    version: '[丝路风语] 2.1.15',
    date: '2024-12-05 11:30:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='2.1.15.0'>
        <p>更新冬至装备</p>
      </p>,
      '太玄经·悟',
      <p style={{ paddingLeft: 12 }} key='2.1.15.1'>
        <p>更新循环</p>
      </p>,
    ],
  },
  {
    version: '[丝路风语] 2.1.14',
    date: '2024-12-02 10:30:00',
    content: [
      '凌海诀',
      <p style={{ paddingLeft: 12 }} key='2.1.14.0'>
        <p>偷偷搞了一个模拟器，还在测试中</p>
      </p>,
      '太玄经·悟',
      <p style={{ paddingLeft: 12 }} key='2.1.14.1'>
        <p>更新斗循环，新循环还在调试中</p>
      </p>,
      '无方·悟',
      <p style={{ paddingLeft: 12 }} key='2.1.14.2'>
        <p>更新循环，新循环不计算鬼门</p>
      </p>,
      '花间游·悟',
      <p style={{ paddingLeft: 12 }} key='2.1.14.3'>
        <p>新增花间游·悟</p>
        <p>循环默认未计算渡泉·悟斩杀，手动选择渡泉后按50%均摊计算</p>
      </p>,
      '凌海诀·悟',
      <p style={{ paddingLeft: 12 }} key='2.1.14.4'>
        <p>加了一个内置的三段加速循环，但是不确定是否有buff异常叠加导致偏高的情况</p>
      </p>,
    ],
  },
  {
    version: '[丝路风语] 2.1.13',
    date: '2024-12-02 10:30:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='2.1.13.1'>
        <p>更新各门派无界技改</p>
      </p>,
    ],
  },
  {
    version: '[丝路风语] 2.1.12',
    date: '2024-11-30 22:30:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='2.1.12.1'>
        <p>优化装备助手·无修助手</p>
      </p>,
      '凌海诀',
      <p style={{ paddingLeft: 12 }} key='2.1.12.2'>
        <p>更换CW循环</p>
      </p>,
      '分山劲',
      <p style={{ paddingLeft: 12 }} key='2.1.11.2'>
        <p>新增橙武二段加速循环</p>
        <p>更新部分奇穴支持，配合Formulator导入使用</p>
      </p>,
      '周天功',
      <p style={{ paddingLeft: 12 }} key='2.1.12.3'>
        <p>合并紫武206、3488档位循环，自动根据加速切换</p>
        <p>新增橙武循环，模拟5分钟触发7次，节点平均为理想节点和较差节点</p>
        <p>具体循环见模拟器</p>
      </p>,
    ],
  },
  {
    version: '[丝路风语] 2.1.11',
    date: '2024-11-26 14:00:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='2.1.11.1'>
        <p>部分代码优化，优化加速规划算法</p>
      </p>,
      '分山劲',
      <p style={{ paddingLeft: 12 }} key='2.1.11.2'>
        <p>修复虚弱增益覆盖</p>
      </p>,
      '孤锋诀',
      <p style={{ paddingLeft: 12 }} key='2.1.11.3'>
        <p>新增威声4段加速超低延迟新循环</p>
      </p>,
      '凌海诀·悟',
      <p style={{ paddingLeft: 12 }} key='2.1.11.4'>
        <p>修改紫武循环</p>
      </p>,
    ],
  },
  {
    version: '[丝路风语] 2.1.10',
    date: '2024-11-22 11:00:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='2.1.10.1'>
        <p>新增加速规划功能</p>
        <p>增加了气血附魔</p>
        <p>修复部分情况下循环加速识别异常的问题</p>
        <p>修改快捷设置和默认T奶团队增益层数 100 {`=>`} 90</p>
        <p>修改部分模拟器网络延迟和无GCD技能的作用关系</p>
      </p>,
      '分山劲',
      <p style={{ paddingLeft: 12 }} key='2.1.10.2'>
        <p>更新橙武循环</p>
      </p>,
      '山海心诀·悟',
      <p style={{ paddingLeft: 12 }} key='2.1.10.3'>
        <p>更新紫武循环</p>
        <p style={{ color: 'red' }}>
          因JCL读取增加伤害Buff消失逻辑异常可能导致伤害偏高，但是不影响计算相对配装
        </p>
      </p>,
    ],
  },
  {
    version: '[丝路风语] 2.1.9',
    date: '2024-11-21 11:00:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='2.1.9.1'>
        <p>更新各职业技改</p>
      </p>,
      '毒经',
      <p style={{ paddingLeft: 12 }} key='2.1.8.2'>
        <p>修改宠物非侠</p>
        <p>循环暂未更新</p>
      </p>,
      '太玄经',
      <p style={{ paddingLeft: 12 }} key='2.1.8.3'>
        <p>新增了假设的紫武度冥循环，套装覆盖率均摊比天网低10%</p>
      </p>,
      '北傲诀',
      <p style={{ paddingLeft: 12 }} key='2.1.8.4'>
        <p>修改紫武循环团队增益计算方式，支持快照计算</p>
      </p>,
      '周天功',
      <p style={{ paddingLeft: 12 }} key='2.1.8.5'>
        <p>修改模拟器破穴CD</p>
      </p>,
    ],
  },
  {
    version: '[丝路风语] 2.1.8',
    date: '2024-11-19 18:20:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='2.1.8.1'>
        <p>更新各职业技改</p>
      </p>,
      '毒经',
      <p style={{ paddingLeft: 12 }} key='2.1.8.2'>
        <p>策划忘了改宠物非侠，还是72/1024</p>
        <p>更新循环</p>
      </p>,
      '太玄经',
      <p style={{ paddingLeft: 12 }} key='2.1.8.3'>
        <p>更新循环</p>
      </p>,
    ],
  },
  {
    version: '[丝路风语] 2.1.7',
    date: '2024-11-14 11:00:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='2.1.7.1'>
        <p>更新各职业技改</p>
      </p>,
      '周天功',
      <p style={{ paddingLeft: 12 }} key='2.1.7.2'>
        <p>更新模拟器</p>
      </p>,
    ],
  },
  {
    version: '[丝路风语] 2.1.6',
    date: '2024-11-12 14:00:00',
    content: [
      '太玄经悟、凌海诀悟',
      <p style={{ paddingLeft: 12 }} key='2.1.6.1'>
        <p>新增心法</p>
      </p>,
      '山海心诀',
      <p style={{ paddingLeft: 12 }} key='2.1.6.2'>
        <p>根据朝仪加速阈值，细化了各循环的加速档位</p>
      </p>,
      '易筋经',
      <p style={{ paddingLeft: 12 }} key='2.1.6.3'>
        <p>
          辛苦秃不得打了一个20分钟的CW循环。20分钟为了减少特效的波动影响，20分钟较5分钟dps会偏低
        </p>
      </p>,
    ],
  },
  {
    version: '[丝路风语] 2.1.5',
    date: '2024-11-07 15:00:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='2.1.5.1'>
        <p>新增团队增益轴设置功能，可以控制部分团队增益的计算方式“快照”｜“均摊”</p>
        <p>理论上快照计算对团队增益的计算更加精确，但是有更高的计算性能要求，计算耗时更长</p>
        <p>目前需重新录入循环才能支持快照计算，当前已支持部分。其他循环待逐步更新</p>
        <p>上述循环若更新后出现明显卡顿，请联系我重置循环</p>
        <p>自带模拟器心法 孤锋诀、山海心诀、周天功后续开发该功能</p>
        <p>其余增益轴说明见弹窗内</p>
      </p>,
      '北傲诀、凌海诀、分山劲',
      <p style={{ paddingLeft: 12 }} key='2.1.5.2'>
        <p>新增橙武循环</p>
      </p>,
      '无方',
      <p style={{ paddingLeft: 12 }} key='2.1.5.3'>
        <p>新CW循环，重新设定了无方紫武、橙武循环。由长倦提供</p>
      </p>,
    ],
  },
  {
    version: '[丝路风语] 2.1.4',
    date: '2024-11-06 17:00:00',
    content: [
      '孤锋诀',
      <p style={{ paddingLeft: 12 }} key='2.1.4.1'>
        <p>
          循环变更名称 六破 {`=>`} 电逝 无影刀 {`=>`} 击懈
        </p>
        <p>新增威声循环，和电逝各有优劣，具体选择建议及宏见魔盒帖子说明</p>
      </p>,
    ],
  },
  {
    version: '[丝路风语] 2.1.3',
    date: '2024-11-05 16:00:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='2.1.3.0'>
        <p>修改缓存读取方式，避免错误的计算未携带增益</p>
        <p>配装器新增「过滤无修」功能</p>
        <p>配装器新增加速不足提示文案</p>
        <p>目标新增试炼之地雷神和133级boss选择</p>
        <p>装备库更新</p>
      </p>,
      '周天功',
      <p style={{ paddingLeft: 12 }} key='2.1.3.1'>
        <p>新增橙武JCL辅助配装，并非最优打法</p>
        <p>非侠系数 52 {`=>`} 72</p>
        <p>引窍涌泉 0.05 {`=>`} 0.04</p>
        <p>引窍涌泉新增非侠，系数 175</p>
        <p>横驱风靡系数 524.4 {`=>`} 445.73999999999995</p>
        <p>封府增强越0.47%，清激增强约0.36%。依然清激略高。</p>
        <p>
          考虑配装问题，本次系数调整最终结果
          <span style={{ color: 'red' }}>增强约0.3% ～ 0.5%</span>
        </p>
      </p>,
      '易筋经',
      <p style={{ paddingLeft: 12 }} key='2.1.3.2'>
        <p>更新橙武循环</p>
      </p>,
      '毒经',
      <p style={{ paddingLeft: 12 }} key='2.1.3.2'>
        <p>更新橙武循环</p>
      </p>,
      '凌海诀',
      <p style={{ paddingLeft: 12 }} key='2.1.3.3'>
        <p>更新紫武循环</p>
      </p>,
    ],
  },
  {
    version: '[丝路风语] 2.1.2',
    date: '2024-11-03 16:00:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='2.1.2.0'>
        <p>修复内功加速五彩石遗漏的问题</p>
        <p>修复全能加成的破招和无双展示在面板上</p>
        <p>修复大附魔装备分数问题</p>
        <p>新增五彩石</p>
        <p style={{ color: 'red' }}>
          注意：内功五彩石名称为虚拟，请按属性搜索具体对因伤害类型的五彩石
        </p>
      </p>,
      '周天功',
      <p style={{ paddingLeft: 12 }} key='2.1.2.1'>
        <p>修复15000品小CW属性异常问题</p>
        <p>新增清激1.1循环(206档位)，2.0循环(劈风令3488加速档位)</p>
        <p>周天功心法的段氏阵法6%增伤改为快照计算，方便模拟器哪模拟阵眼4重的影响</p>
      </p>,
      '无方',
      <p style={{ paddingLeft: 12 }} key='2.1.2.2'>
        <p>新增橙武循环</p>
      </p>,
      '花间游',
      <p style={{ paddingLeft: 12 }} key='2.1.2.3'>
        <p>新增CW循环</p>
      </p>,
      '分山劲',
      <p style={{ paddingLeft: 12 }} key='2.1.2.4'>
        <p>新增T队开阵选项，按80%覆盖率计算</p>
      </p>,
    ],
  },
  {
    version: '[丝路风语] 2.1.1',
    date: '2024-11-01 10:00:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='2.1.1.0'>
        <p>修复内功加速五彩石遗漏的问题</p>
        <p style={{ color: 'red' }}>
          注意：内功五彩石名称为虚拟，请按属性搜索具体对因伤害类型的五彩石
        </p>
      </p>,
      '周天功',
      <p style={{ paddingLeft: 12 }} key='2.1.1.1'>
        <p>新增相息一键宏循环，用于配装参考</p>
        <p>模拟器加速从加速档位修改为加速等级</p>
      </p>,
      '太玄经',
      <p style={{ paddingLeft: 12 }} key='2.1.1.2'>
        <p>
          新增橙武循环，强制要求加速等级
          <span style={{ color: 'red' }}>9848</span>
        </p>
      </p>,
    ],
  },
  {
    version: '[丝路风语] 2.1.0',
    date: '2024-10-31 16:30:00',
    content: [
      <p key='2.1.0.0'>
        <a
          href='https://jx3.xoyo.com/index/#/article-details?kid=1334144'
          target='_blank'
          rel='noreferrer'
        >
          技改链接
        </a>
      </p>,
      '综合',
      <p style={{ paddingLeft: 12 }} key='2.1.0.1'>
        <p>130级数值、装备库、增益等技改。各心法技改</p>
      </p>,
      <p style={{ paddingLeft: 12 }} key='2.1.0.2'>
        <p>新增周天功、毒经、易筋经、北傲诀、分山劲心法</p>
        <p>新增周天功模拟器</p>
      </p>,
      <p style={{ paddingLeft: 12 }} key='2.1.0.4'>
        「无方·悟」「山海心诀·悟」计算循环暂未更新
      </p>,
      <p style={{ paddingLeft: 12, color: 'red' }} key='2.1.0.3'>
        在正式开始使用前，请在页面右下角点击“清除缓存”后再进行使用。
      </p>,
    ],
  },
  {
    version: '[丝路风语-体服] 2.0.14',
    date: '2024-10-24 16:30:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='2.0.14.1'>
        <p>更新CW、15500橙戒装备模型</p>
        <p>更新刀宗、万灵CW模拟器</p>
        <p>修改蓬莱阵眼覆盖率</p>
      </p>,
    ],
  },
  {
    version: '[丝路风语-体服] 2.0.13',
    date: '2024-10-24 12:00:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='2.0.13.1'>
        <p>修改各职业非侠，修改部分职业循环</p>
      </p>,
      '北傲诀',
      <p style={{ paddingLeft: 12 }} key='2.0.13.1'>
        <p>新增北傲诀心法</p>
      </p>,
    ],
  },
  {
    version: '[丝路风语-体服] 2.0.12',
    date: '2024-10-22 10:00:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='2.0.12.1'>
        <p>修改各职业非侠，修改部分职业循环</p>
      </p>,
      '孤锋诀·悟',
      <p style={{ paddingLeft: 12 }} key='2.0.12.2'>
        <p>更新模拟器</p>
        <p>修复留减少绝技技能CDbug</p>
      </p>,
      '毒经',
      <p style={{ paddingLeft: 12 }} key='2.0.12.3'>
        <p>根据快照更新循环</p>
      </p>,
    ],
  },
  {
    version: '[丝路风语-体服] 2.0.11',
    date: '2024-10-18 11:30:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='2.0.11.x'>
        <p>部分职业增益数值修改</p>
        <p>装备库更新</p>
        <p>五彩石更新</p>
        <p>逐云寒蕊具体伤害修改</p>
        <p>新增部分无封装备算法</p>
      </p>,
      '山海心诀',
      <p style={{ paddingLeft: 12 }} key='2.0.11.1'>
        <p>11层桑柘奇穴</p>
        <p>猜测朝能吃第一层劲风奇穴加成</p>
        <p>具体表现待实测后决定</p>
      </p>,
      '孤锋诀·悟',
      <p style={{ paddingLeft: 12 }} key='2.0.11.2'>
        <p>脑策了行云二、三段不吃GCD逻辑后重写模拟器</p>
        <p>具体表现待实测后决定</p>
      </p>,
      '已更新循环门派',
      <p style={{ paddingLeft: 12 }} key='2.0.11.3'>
        <p>无方、易筋经、花间游已更新循环</p>
      </p>,
      '待定未更新事项',
      <p style={{ paddingLeft: 12 }} key='2.0.10.4'>
        <p>太玄经、分山劲、山海心诀·悟。具体循环待新JCL后更新</p>
        <p>大附魔</p>
      </p>,
    ],
  },
  {
    version: '[丝路风语-体服] 2.0.10',
    date: '2024-10-15 12:00:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='2.0.10.x'>
        <p>修改各内功远程职业循环，删除普通攻击</p>
      </p>,
      '易筋经',
      <p style={{ paddingLeft: 12 }} key='2.0.10.1'>
        <p>更新带套装循环</p>
      </p>,
    ],
  },
  {
    version: '[丝路风语-体服] 2.0.10',
    date: '2024-10-15 12:00:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='2.0.10.x'>
        <p>修改各内功远程职业循环，删除普通攻击</p>
      </p>,
      '易筋经',
      <p style={{ paddingLeft: 12 }} key='2.0.10.1'>
        <p>更新带套装循环</p>
      </p>,
    ],
  },
  {
    version: '[丝路风语-体服] 2.0.9',
    date: '2024-10-12 11:45:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='2.0.9.x'>
        <p>修改飘黄数据</p>
        <p>修改优化算法的计算方式，剔除附魔和镶嵌的容量后计算，使用前需要重新进入配装器保存一次</p>
        <p>优化算法仅代表趋势，且收益接近时存在上下浮动，具体说明详见功能Tip</p>
      </p>,
      '花间',
      <p style={{ paddingLeft: 12 }} key='2.0.9.1'>
        <p>新增无脑芙蓉循环</p>
      </p>,
      '周天功',
      <p style={{ paddingLeft: 12 }} key='2.0.9.2'>
        <p>模拟器逻辑修改</p>
      </p>,
    ],
  },
  {
    version: '[丝路风语-体服] 2.0.8',
    date: '2024-10-11 14:00:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='2.0.8.x'>
        <p>修改附魔分数</p>
      </p>,
      '分山劲',
      <p style={{ paddingLeft: 12 }} key='2.0.8.1'>
        <p>新增分山劲心法</p>
      </p>,
      '无方',
      <p style={{ paddingLeft: 12 }} key='2.0.8.2'>
        <p>且待时休·苍棘缚地+58.7%</p>
        <p>银光照雪·结草+10%</p>
        <p>疑似为之前公告的改动未实装，非额外加强</p>
      </p>,
    ],
  },
  {
    version: '[丝路风语-体服] 2.0.7',
    date: '2024-10-10 17:00:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='2.0.7.x'>
        <p>更新装备库</p>
      </p>,
      '花间游',
      <p style={{ paddingLeft: 12 }} key='2.0.7.1'>
        <p>修改青冠加成为无视50%防御</p>
        <p>快雪+20%</p>
        <p>折花+15%</p>
      </p>,
      '易筋经',
      <p style={{ paddingLeft: 12 }} key='2.0.7.2'>
        <p>守缺+10%</p>
        <p>横扫+10%</p>
        <p>疑似为之前公告的改动未实装，非额外加强</p>
      </p>,
    ],
  },
  {
    version: '[丝路风语-体服] 2.0.6',
    date: '2024-10-08 10:00:00',
    content: [
      '花间游',
      <p style={{ paddingLeft: 12 }} key='2.0.6.1'>
        <p>修正JCL涓流偏差导致的循环错位</p>
      </p>,
      '易筋经',
      <p style={{ paddingLeft: 12 }} key='2.0.6.2'>
        <p>新增小飞棍循环</p>
      </p>,
      '凌海诀',
      <p style={{ paddingLeft: 12 }} key='2.0.6.3'>
        <p>更新循环，修复跃潮无破招bug</p>
      </p>,
      '山海心诀',
      <p style={{ paddingLeft: 12 }} key='2.0.6.4'>
        <p>删除紫箭循环</p>
      </p>,
    ],
  },
  {
    version: '[丝路风语-体服] 2.0.5',
    date: '2024-10-01 23:30:00',
    content: [
      '毒经',
      <p style={{ paddingLeft: 12 }} key='2.0.5.1'>
        <p>更新循环</p>
      </p>,
      '易筋经',
      <p style={{ paddingLeft: 12 }} key='2.0.5.2'>
        <p>更新循环（修复三生覆盖率不准确bug）</p>
      </p>,
      '花间游',
      <p style={{ paddingLeft: 12 }} key='2.0.5.3'>
        <p>新增循环</p>
      </p>,
    ],
  },
  {
    version: '[丝路风语-体服] 2.0.4',
    date: '2024-09-29 18:30:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='2.0.4.1'>
        <p>9月29日技改</p>
        <p>更新各门派系数</p>
      </p>,
      '孤锋诀',
      <p style={{ paddingLeft: 12 }} key='2.0.4.2'>
        <p>更新模拟器，更新循环</p>
      </p>,
      '山海心诀',
      <p style={{ paddingLeft: 12 }} key='2.0.4.3'>
        <p>更新模拟器，更新循环</p>
      </p>,
      '无方',
      <p style={{ paddingLeft: 12 }} key='2.0.4.4'>
        <p>更新循环</p>
      </p>,
      '太玄经',
      <p style={{ paddingLeft: 12 }} key='2.0.4.5'>
        <p>更新重山系数，更新循环</p>
      </p>,
      '毒经',
      <p style={{ paddingLeft: 12 }} key='2.0.4.6'>
        <p>更新循环</p>
      </p>,
      '凌海诀',
      <p style={{ paddingLeft: 12 }} key='2.0.4.7'>
        <p>更新循环（猴王）</p>
      </p>,
      '易筋经',
      <p style={{ paddingLeft: 12 }} key='2.0.4.8'>
        <p>应「秃不得」大佬的支持，新增易筋经心法</p>
      </p>,
    ],
  },
  {
    version: '[丝路风语-体服] 2.0.3',
    date: '2024-09-27 11:00:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='2.0.3.1'>
        <p>增加循环设置秘籍功能</p>
        <p>增加循环导入导出功能</p>
      </p>,
      '毒经',
      <p style={{ paddingLeft: 12 }} key='2.0.3.2'>
        <p>新增毒经心法</p>
      </p>,
    ],
  },
  {
    version: '[丝路风语-体服] 2.0.2',
    date: '2024-09-25 14:00:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='2.0.2.1'>
        <p>更新9月20日技改</p>
        <p>更新无界增益</p>
        <p>更新涌泉系数</p>
      </p>,
    ],
  },
  {
    version: '[丝路风语-体服] 2.0.1',
    date: '2024-09-23 11:30:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='2.0.1.1'>
        <p>更新9月19日技改</p>
        <p>更新套装激活逻辑</p>
        <p>更新新飘黄伤害计算</p>
        <p>修改优化算饭逻辑，剔除无双计算</p>
      </p>,
    ],
  },
  {
    version: '[丝路风语-体服] 2.0.0',
    date: '2024-08-27 10:30:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='2.0.0.1'>
        <p>
          各心法等级「13」 {`=>`} 「14」气血加成「235」 {`=>`} 「245」
        </p>
        <p>属性基础系数修改</p>
        <p>装备库、同泽宴更新</p>
        <p>开放开发者选项，可自行导入JCL解析循环用作计算</p>
      </p>,
      '周天功',
      <p style={{ paddingLeft: 12 }} key='2.0.0_ztg'>
        <p>新增周天功心法，附带模拟器</p>
      </p>,
    ],
  },
  {
    version: '[雾海寻龙] 1.1.27',
    date: '2024-09-12 14:30:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='1.1.27.1'>
        <p>中秋节暗器更新</p>
        <p style={{ color: 'red' }}>
          由于某不要脸机器人作者的恶意使用，角色名称查询功能下架。只支持使用UID查询
        </p>
      </p>,
    ],
  },
  {
    version: '[雾海寻龙] 1.1.26',
    date: '2024-08-27 10:30:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='1.1.26.1'>
        <p>代码优化，性能优化，部分组件懒加载</p>
      </p>,
    ],
  },
  {
    version: '[雾海寻龙] 1.1.25',
    date: '2024-08-19 10:00:00',
    content: [
      '山海心诀·悟',
      <p style={{ paddingLeft: 12 }} key='1.1.25.1'>
        <p>更新计算循环-助手循环，助手来自魔盒「渊凡」，JCL来自「十九停」</p>
      </p>,
    ],
  },
  {
    version: '[雾海寻龙] 1.1.24',
    date: '2024-08-16 14:00:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='1.1.24.1'>
        <p>新增15000破无项链</p>
      </p>,
      '山海心诀',
      <p style={{ paddingLeft: 12 }} key='1.1.24.2'>
        <p>修正山海心诀各技能2%伤害秘籍的系数「21」{`=>`}「20」</p>
      </p>,
      '无方',
      <p style={{ paddingLeft: 12 }} key='1.1.24.3'>
        <p>修正无方各技能2%伤害秘籍的系数「21」{`=>`}「20」</p>
      </p>,
    ],
  },
  {
    version: '[雾海寻龙] 1.1.23',
    date: '2024-08-14 14:00:00',
    content: [
      '太玄经',
      <p style={{ paddingLeft: 12 }} key='1.1.23.1'>
        <p>添加橙武「鬼枭」循环，循环由某不愿意透漏名字的昆吾衍天提供</p>
        <p style={{ color: 'red' }}>
          「鬼枭」循环默认按「枭神」40%覆盖计算，如不计算枭神可手动修改奇穴
        </p>
      </p>,
    ],
  },
  {
    version: '[雾海寻龙] 1.1.22',
    date: '2024-08-13 14:00:00',
    content: [
      '综合-Bug',
      <p style={{ paddingLeft: 12 }} key='1.1.22.1'>
        <p>修复无封对比中对比组合计算的Bug</p>
      </p>,
      '无方',
      <p style={{ paddingLeft: 12 }} key='1.1.22.1'>
        <p>将原「避奚养荣橙武循环」改名为“声控”，增加一份常规数据，由鹿行川提供</p>
        <p>增加循环是为了回应某些使用者对所谓刷特效所以才不要破招的反馈</p>
      </p>,
    ],
  },
  {
    version: '[雾海寻龙] 1.1.21',
    date: '2024-08-09 17:30:00',
    content: [
      '综合-Bug',
      <p style={{ paddingLeft: 12 }} key='1.1.21.1'>
        <p>修复无封对比中对比时未计算附魔的Bug</p>
      </p>,
    ],
  },
  {
    version: '[雾海寻龙] 1.1.20',
    date: '2024-08-06 17:30:00',
    content: [
      '综合-功能',
      <p style={{ paddingLeft: 12 }} key='1.1.20.1'>
        <p>优化配装器最佳附魔计算的显示，显示多个五彩石，方便对比</p>
        <p>新增百分比主属性五彩石（实际收益很低）</p>
      </p>,
    ],
  },
  {
    version: '[雾海寻龙] 1.1.19',
    date: '2024-08-05 17:00:00',
    content: [
      '综合-功能',
      <p style={{ paddingLeft: 12 }} key='1.1.19.1'>
        <p>新增循环的数据提供者的备注，展示提供用于计算JCL文件的玩家</p>
        <p>删除无界统一背景图</p>
      </p>,
      '太玄经',
      <p style={{ paddingLeft: 12 }} key='1.1.19.2'>
        <p>应评论需求，重新添加「鬼追」循环</p>
      </p>,
    ],
  },
  {
    version: '[雾海寻龙] 1.1.18',
    date: '2024-08-01 20:00:00',
    content: [
      '综合-功能',
      <p style={{ paddingLeft: 12 }} key='1.1.18.1'>
        <p>新增功能「收益图表-属性置换」，详情请鼠标移至属性置换查看</p>
        <p>新增功能「装备助手-无封助手」，用于快速跨部位实现「智能对比」。使用前请阅读须知。</p>
      </p>,
      '综合-Bug',
      <p style={{ paddingLeft: 12 }} key='1.1.18.1'>
        <p>修复雷计算时错误计算了对内功攻击力加成的Bug</p>
      </p>,
    ],
  },
  {
    version: '[雾海寻龙] 1.1.17',
    date: '2024-07-26 10:30:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='1.1.17.1'>
        <p>修复清心的文本描述错误</p>
      </p>,
      '太玄经',
      <p style={{ paddingLeft: 12 }} key='1.1.17.1'>
        <p>删除「鬼追」循环</p>
        <p>新增两个「鬼枭」循环，一个为欧皇版</p>
        <p style={{ color: 'red' }}>
          「鬼枭」循环默认按「枭神」40%覆盖计算，如不计算枭神可手动修改奇穴
        </p>
      </p>,
    ],
  },
  {
    version: '[雾海寻龙] 1.1.16',
    date: '2024-07-26 10:30:00',
    content: [
      '无方',
      <p style={{ paddingLeft: 12 }} key='1.1.16.1'>
        <p>新增紫武养荣武学助手流派</p>
      </p>,
    ],
  },
  {
    version: '[雾海寻龙] 1.1.15',
    date: '2024-07-25 14:30:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='1.1.15.1'>
        <p>新增「装分」「气血」属性显示</p>
        <p>新增「气血」相关增益，新增「共战」增益</p>
        <p>优化配装器UI布局</p>
        <p>配装器智能对比功能现在可以对比附魔了</p>
        <p style={{ color: 'red' }}>存量数据的装分和气血需要重新进入配装器操作一下才会更新。</p>
      </p>,
      '花间游',
      <p style={{ paddingLeft: 12 }} key='1.1.15.2'>
        <p>删除了循环计算时对加速的强制校验</p>
        <p>现在配装加速不符合依然会按照1段加速计算，不会返回0了</p>
      </p>,
      '凌海诀',
      <p style={{ paddingLeft: 12 }} key='1.1.15.3'>
        <p>删除了「怅归」循环计算时对加速的强制校验，</p>
        <p>现在配装加速不符合依然会按照1段加速计算，不会返回0了</p>
      </p>,
    ],
  },
  {
    version: '[雾海寻龙] 1.1.14',
    date: '2024-07-22 09:30:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='1.1.14.1'>
        <p>根据长倦提供的循环更新了避奚养荣的橙武循环</p>
        <p>如出现样式问题导致计算器无法使用，请尝试强制刷新页面清除缓存。</p>
        <p>建议使用Chrome浏览器</p>
      </p>,
    ],
  },
  {
    version: '[雾海寻龙] 1.1.13',
    date: '2024-07-19 15:30:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='1.1.13.1'>
        <p>升级了系统的基础UI组件，如出现样式问题请反馈。</p>
        <p>由于组件库升级，不支持IE浏览器</p>
        <p>新增了面向新使用者的漫游式引导，如您已经熟练使用，可以跳过引导</p>
        <p>重新增加蓝色家园酒品</p>
      </p>,
    ],
  },
  {
    version: '[雾海寻龙] 1.1.12',
    date: '2024-07-18 12:00:00',
    content: [
      '综合-功能',
      <p style={{ paddingLeft: 12 }} key='1.1.12.1'>
        <p>团队增益新增「弄梅」稀缺增益 - 相知风雷特效</p>
        <p>团队增益新增「中和·悟」增益 - 灵素·悟</p>
        <p>小药小吃新增八段锦相关小吃，数值为秘境内数值</p>
        <p>小药小吃新增了增益的具体数值显示</p>
        <p>团队增益新增一键展开/收起功能</p>
      </p>,
    ],
  },
  {
    version: '[雾海寻龙] 1.1.11',
    date: '2024-07-17 16:30:00',
    content: [
      '综合-功能',
      <p style={{ paddingLeft: 12 }} key='1.1.11.1'>
        <p>页面右下角新增「数据迁移」功能，方便在不同网址、客户端中同步数据</p>
        <p>新增连接内携带服务和名称自动打开导入弹窗功能，例：</p>
        <p>
          <a
            href='https://jx3.btcsg.top/?xf=lhj&server=梦江南&name=剑网三'
            target='_blank'
            rel='noreferrer'
          >
            jx3.btcsg.top/?xf=lhj&server=梦江南&name=剑网三
          </a>
        </p>
      </p>,
      '综合-重构',
      <p style={{ paddingLeft: 12 }} key='1.1.11.2'>
        <p>重构计算函数对技能增益的计算函数</p>
        <p>
          大幅提升计算效率，在花间、蓬莱等循环较为复杂的情况下以及勾选团队增益情况下性能提升明显，约有50%提升。
        </p>
        <p>放开花间游、凌海诀的优化算法功能。若后续发现依然卡死再关闭</p>
      </p>,
      '综合-Bug',
      <p style={{ paddingLeft: 12 }} key='1.1.11.3'>
        <p>修复了「归去来棍·悟」的数值错误</p>
      </p>,
      <p style={{ color: 'red' }} key='1.1.11.3'>
        本次重构代码较为底层，若有问题请及时反馈
      </p>,
    ],
  },
  {
    version: '[雾海寻龙] 1.1.10',
    date: '2024-07-13 20:30:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='1.1.10.1'>
        <p>角色配装导入额外支持按角色id查询</p>
        <p>仙王蛊鼎增伤修订为「15%」</p>
      </p>,
      '山海心诀·悟',
      <p style={{ paddingLeft: 12 }} key='1.1.10.2'>
        <p>更新了十九停「珠海哥」打的JCL生成的循环</p>
        <p>目前看大部分情况下2动物比3动物更高</p>
      </p>,
    ],
  },
  {
    version: '[雾海寻龙] 1.1.9',
    date: '2024-07-12 15:30:00',
    content: [
      '无方·悟',
      <p style={{ paddingLeft: 12 }} key='1.1.9.2'>
        <p>新增无方·悟心法</p>
        <p>内置四个循环为「长倦」打的手动循环。为1段加速计算</p>
        <p style={{ color: 'red' }}>默认按「鬼门·悟」50%覆盖计算，如不计算鬼门可手动修改奇穴</p>
      </p>,
    ],
  },
  {
    version: '[雾海寻龙] 1.1.8',
    date: '2024-07-10 12:30:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='1.1.8.1'>
        <p>新增无界团队增益</p>
      </p>,
      '山海心诀·悟',
      <p style={{ paddingLeft: 12 }} key='1.1.8.2'>
        <p>增加二动物循环，目前由于JCL解析无界丟buff原因。可能存在波动</p>
        <p>二动物三动物打法对延迟要求不同，实际自己试试哪个高打哪个</p>
        <p>吐槽一下界万灵手动也太吃延迟了</p>
      </p>,
    ],
  },
  {
    version: '[雾海寻龙] 1.1.7',
    date: '2024-07-09 10:30:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='1.1.7.2'>
        <p>新增了内功加速五彩石</p>
        <p>配装器内当装备精炼等级未满时，会有更清晰的提示</p>
      </p>,
      '无方',
      <p style={{ paddingLeft: 12 }} key='1.1.7.1'>
        <p>新增了避奚养荣循环</p>
      </p>,
    ],
  },
  {
    version: '[雾海寻龙] 1.1.6',
    date: '2024-07-08 09:30:00',
    content: [
      '7月8日技改',
      <p style={{ paddingLeft: 12 }} key='1.1.0.2'>
        <a
          href='https://jx3.xoyo.com/index/#/article-details?kid=1333878'
          target='_blank'
          rel='noreferrer'
        >
          技改链接
        </a>
      </p>,
      '太玄经',
      <p style={{ paddingLeft: 12 }} key='1.1.5.1'>
        <p>非侠修改为「14%」（数值144）</p>
      </p>,
      '无方',
      <p style={{ paddingLeft: 12 }} key='1.1.5.1'>
        <p>非侠修改为「10%」（数值102）</p>
        <p>修改了各循环名称，删除了应理的「终极」「初级」循环，删除武学助手循环</p>
        <p>更新了养荣循环，现在为5分钟循环</p>
      </p>,
      '孤锋诀',
      <p style={{ paddingLeft: 12 }} key='1.1.5.1'>
        <p>修复模拟器点出奇穴「周流」时识破诀锐意增加数值不正确的问题</p>
      </p>,
      '山海心诀·悟',
      <p style={{ paddingLeft: 12 }} key='1.1.5.1'>
        <p>循环变动，手动循环暂时只支持一段加速计算。近期可能会随时调整更新</p>
      </p>,
    ],
  },
  {
    version: '[雾海寻龙] 1.1.5',
    date: '2024-07-07 00:30:00',
    content: [
      '山海心诀·悟',
      <p style={{ paddingLeft: 12 }} key='1.1.5.1'>
        <p>- 新增了山海心诀·悟无界心法的计算器。</p>
        <p>- 内置三个循环：【紫武_助手】【紫武_手动】【橙武_手动】</p>
        <p style={{ color: 'red' }}>
          -
          特别说明！！目前有反馈高破招号在副本内dps异常。目前原因未知，经测试无增益情况下无异常。暂不确定是哪个增益导致异常。
          <p>目前请勿根据本计算器拍高破招装备。旗舰账号暂时不建议针对无界进行配装修改。</p>
        </p>
      </p>,
    ],
  },
  {
    version: '[雾海寻龙] 1.1.4',
    date: '2024-07-03 19:50:00',
    content: [
      '7月3日技改',
      <p style={{ paddingLeft: 12 }} key='1.1.0.2'>
        <a href='https://jx3.xoyo.com/announce/gg.html?id=1333872' target='_blank' rel='noreferrer'>
          技改链接
        </a>
      </p>,
      '花间游',
      <p style={{ paddingLeft: 12 }} key='1.1.1.3'>
        <p>- 非侠调整为11%（系数为113）</p>
      </p>,
      '无方',
      <p style={{ paddingLeft: 12 }} key='1.1.1.3'>
        <p>- 养荣额外伤害提高90%</p>
      </p>,
      '孤锋诀',
      <p style={{ paddingLeft: 12 }} key='1.1.1.3'>
        <p>- 非侠调整为18%（系数为184）</p>
        <p>- 「戗风」被动8%，吃影子额外8%（系数82）</p>
        <p>- 「击懈」诀云势增加100%非侠（系数1024）</p>
        <p>- 模拟器同步更新威声、涣衍、流岚等逻辑</p>
        <p>- 根据模拟器生成了新的六破、无影刀、橙武循环</p>
      </p>,
      '孤锋诀·悟',
      <p style={{ paddingLeft: 12 }} key='1.1.1.3'>
        <p>- 非侠调整为45%（系数为461）</p>
        <p>- 「留客雨·悟」命中存在流血buff的目标时刷新目标流血</p>
        <p>- 根据模拟器生成了新的手动、助手、橙武循环</p>
      </p>,
    ],
  },
  {
    version: '[雾海寻龙] 1.1.3',
    date: '2024-07-03 16:50:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='1.1.3.1'>
        <p>- 服务器资源更换，感谢「冰糖雪梨橙」的大力支持</p>
        <p>- 新增角色导入功能，可以直接导入游戏内角色配装。请在配装器内查看</p>
      </p>,
    ],
  },
  {
    version: '[雾海寻龙] 1.1.2',
    date: '2024-07-01 17:00:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='1.1.2.1'>
        <p>- 页面新增了「识别装备对比」工具功能。使用前请阅读使用说明</p>
        <p>- 花里胡哨的功能又增加了</p>
        <p>
          (
          原计划用OCR做图片识别的，后来发现免费的识别精度很低，又发现插件有文字复制功能，就改为使用文字解析
          )
        </p>
      </p>,
    ],
  },
  {
    version: '[雾海寻龙] 1.1.1',
    date: '2024-06-28 12:00:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='1.1.1.1'>
        <p>- 修正了由于引入孤锋诀悟心法导致的其他心法加速阈值计算错误的问题</p>
        <p>- 优化了无界破招伤害的取整函数，和攻击力计算分开走取整链然后合并计算</p>
      </p>,
      '孤锋诀·悟',
      <p style={{ paddingLeft: 12 }} key='1.1.1.3'>
        <p>- 根据风雪的JCL导入了助手循环</p>
        <p>- 修正了流血buff计算未计算加速导致横云势和留客雨伤害异常的问题</p>
      </p>,
    ],
  },
  {
    version: '[雾海寻龙] 1.1.0',
    date: '2024-06-27 09:00:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='1.1.0.1'>
        <p>- 面板属性增加伤帽的破防属性显示（之前没显示是为了对齐之前的魔盒）</p>
      </p>,
      '孤锋诀',
      <p style={{ paddingLeft: 12 }} key='1.1.0.2'>
        <p>- 修复了刀宗非侠按通用增伤计算的bug</p>
      </p>,
      '孤锋诀·悟',
      <p style={{ paddingLeft: 12 }} key='1.1.0.3'>
        <p>- 新增了孤锋诀·悟无界心法的计算器。包含模拟器同步更新</p>
        <p>- 内置三个循环：【紫武_助手】【紫武_手动】【橙武_手动】</p>
        <p style={{ color: 'red' }}>
          - 特别说明，由于目前无界武学助机制尚未研究明白。经过多次测试完全找不到规律。
        </p>
        <p style={{ color: 'red' }}>
          - 当前的紫武助手循环为哪个亮了点哪个，且模拟助手释放留客雨时的卡顿效果生成的循环
        </p>
        <p>- 武学助手机制尚未测试完成，暂不开放模拟器内的武学助手功能。</p>
        <p>
          <a href='https://www.jx3box.com/bps/82408' target='_blank' rel='noreferrer'>
            - 点击查看无界刀宗不完全攻略指南
          </a>
        </p>
      </p>,
    ],
  },
  {
    version: '[雾海寻龙] 1.0.14',
    date: '2024-06-23 14:00:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='1.0.14.1'>
        <p>- 删除装备库数据中未支持心法的装备。以压缩代码体积</p>
      </p>,
      '太玄经',
      <p style={{ paddingLeft: 12 }} key='1.0.14.2'>
        <p>- 新增「鬼追」一键宏新手流派。</p>
        <p>- 该流派JCL来源于「奶茶」在成都打死粉茶茶的完整战斗数据，数据已计算枭神斩杀效果</p>
      </p>,
    ],
  },
  {
    version: '[雾海寻龙] 1.0.13',
    date: '2024-06-18 09:00:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='1.0.13.1'>
        <p>- 新增全局伤害因子独立乘区，应用场景例【青冠】【正律】</p>
        <p>- 配装器装备选择区间下降至12100品</p>
        <p>- 配装器装备新增10100赠送橙武</p>
        <p>- 在外部显示了技能数量统计。考虑布局原因。在打开增益面板时会隐藏技能统计</p>
        <p>- 优化了查看模拟战斗技能详情的按钮样式，使其更容易被认为是可以点击按钮</p>
      </p>,
      '花间游',
      <p style={{ paddingLeft: 12 }} key='1.0.13.2'>
        <p>{`- 应「蔓海赋尘」大佬支持。新增花间游心法`}</p>
        <p>{`- 支持循环：`}</p>
        <p>{`- 紫武：1、2段加速「焚玉」；1、2段加速「故幽」`}</p>
        <p>{`- 橙武：2段加速「焚玉」；2段加速「故幽」`}</p>
        <p style={{ color: 'red' }}>注意</p>
        <p>- 橙武循环无视配装加速情况，固定按2段加速计算</p>
        <p>- 紫武循环只计算1、2段加速配装，0段加速面板计算结果直接返回0</p>
      </p>,
    ],
  },
  {
    version: '[雾海寻龙] 1.0.12',
    date: '2024-06-12 18:00:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='1.0.12.1'>
        <p>- 新增会破比、招无比两个收益图表（花里胡哨的功能又增加了）</p>
      </p>,
      '太玄经',
      <p style={{ paddingLeft: 12 }} key='1.0.12.2'>
        <p>{`- 压缩了背景图片的尺寸`}</p>
      </p>,
    ],
  },
  {
    version: '[雾海寻龙] 1.0.11',
    date: '2024-06-11 10:00:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='1.0.11.1'>
        <p>- 新增三个试炼之地目标，方便计算。数据来源魔盒</p>
        <p>- 冷龙峰副本Boss防御数据与124级木桩相同，不额外添加</p>
      </p>,
      '太玄经',
      <p style={{ paddingLeft: 12 }} key='1.0.5.2'>
        <p>{`- 配装器武器选择增加5600品归一`}</p>
      </p>,
    ],
  },
  {
    version: '[雾海寻龙] 1.0.10',
    date: '2024-06-07 18:00:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='1.0.10.1'>
        <p>- 订正负数破招系数的计算函数</p>
        <p>- 优化了计算程序函数，理论计算效率提升一倍</p>
        <p>- 优化了在开启智能对比后，无论是否展开阵眼弹窗都会重复多次计算导致性能损耗的BUG</p>
      </p>,
    ],
  },
  {
    version: '[雾海寻龙] 1.0.9',
    date: '2024-05-30 18:00:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='1.0.9.1'>
        <p>- 增加了端午节日增益粽子和小吃，在团队增益弹窗中可选</p>
      </p>,
      '凌海诀',
      <p style={{ paddingLeft: 12 }} key='1.0.9.2'>
        <p>
          - 新增了凌海诀「怅归」循环，
          <span style={{ color: 'red' }}>默认按一段加速计算。非一段加速配装会返回0</span>
        </p>
      </p>,
    ],
  },
  {
    version: '[雾海寻龙] 1.0.8',
    date: '2024-05-29 11:15:00',
    content: [
      '山海心诀',
      <p style={{ paddingLeft: 12 }} key='1.0.8.2'>
        <p>{`- 生成了新的丛云朝仪循环，时间为第五轮大招打完`}</p>
        <p>{`- 对循环进行重新订正`}</p>
        <p>{`- 模拟器增加了对倒读条技能的延迟补偿逻辑`}</p>
        <p>{`- 订正了模拟器对于同时间Dot伤害和叠加Dot行为先后顺序逻辑`}</p>
      </p>,
    ],
  },
  {
    version: '[雾海寻龙] 1.0.7',
    date: '2024-05-28 12:40:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='1.0.7.1'>
        <p>{`- 修改了全局的DOT系数计算公式，使其更加合理易懂`}</p>
        <p>{`- 新增了新的增益类型，无双百分比。对面板无双乘法计算，例：养荣`}</p>
      </p>,
      '无方',
      <p style={{ paddingLeft: 12 }} key='1.0.7.2'>
        <p>{`- 新增无方心法`}</p>
        <p>{`- 无方循环数据均由长倦大佬提供`}</p>
        <p>养荣循环经长倦确认定为按千枝全程覆盖计算</p>
        <p style={{ color: 'red' }}>注意，无方循环目前均为一段加速固定计算。</p>
      </p>,
      '孤锋诀',
      <p style={{ paddingLeft: 12 }} key='1.0.7.2'>
        <p>{`- 生成了新的二段加速橙武循环`}</p>
      </p>,
      '太玄经',
      <p style={{ paddingLeft: 12 }} key='1.0.5.2'>
        <p>{`- 修复了天斗旋一个伤害秘籍的文案错误`}</p>
      </p>,
    ],
  },
  {
    version: '[雾海寻龙] 1.0.6',
    date: '2024-05-27 09:30:00',
    content: [
      '孤锋诀',
      <p style={{ paddingLeft: 12 }} key='1.0.6.2'>
        <p>{`- 非侠系数修改「6」=> 「13」`}</p>
        <p>{`- 流岚无视防御「40%」=>「25%」`}</p>
        <p>{`- 以上两条数值待更新后测试，133和256`}</p>
        <p>{`- 模拟器溃延减少诀云CD「10」=>「11」`}</p>
        <p>{`- 外部计算循环暂未通过模拟器重新生成，理论上溃延对结果影响较小。`}</p>
        <p style={{ color: 'red' }}>{`- 最终伤害正负1%上下浮动，约等于没变`}</p>
      </p>,
      '凌海诀',
      <p style={{ paddingLeft: 12 }} key='1.0.6.2'>
        <p>{`- 移除非侠3%加成`}</p>
        <p style={{ color: 'red' }}>{`- 最终伤害削弱2%-3%`}</p>
      </p>,
      '太玄经',
      <p style={{ paddingLeft: 12 }} key='1.0.6.2'>
        <p>{`- 由于JCL解析鬼遁丢失问题，暂时将鬼遁视作全局覆盖计算`}</p>
        <p>{`- 更新了新的橙武循环`}</p>
        <p>{`- 修复了紫武鬼列循环鬼遁覆盖计算有问题的BUG`}</p>
      </p>,
    ],
  },
  {
    version: '[雾海寻龙] 1.0.5',
    date: '2024-05-24 12:00:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='1.0.5.1'>
        <p>{`- 修正了全局对于技能系数增伤的计算取整位置，例：涤瑕、素矰等`}</p>
        <p>{`- 新增了技能系数对技能等级的取值逻辑`}</p>
      </p>,
      '孤锋诀',
      <p style={{ paddingLeft: 12 }} key='1.0.5.2'>
        <p>{`- 修正「停云势」和「断云势」的武器伤害系数「2.5」=> 「2」`}</p>
        <p>{`- 精细破招系数的算法`}</p>
        <p>{`- 修复门派套装双会buff的覆盖率时间`}</p>
      </p>,
      '山海心诀',
      <p style={{ paddingLeft: 12 }} key='1.0.5.2'>
        <p>{`- 精细破招系数的算法`}</p>
      </p>,
      '太玄经',
      <p style={{ paddingLeft: 12 }} key='1.0.5.2'>
        <p>{`- 新增太玄经心法`}</p>
        <p>{`- 支持循环「鬼列」「堪炸」「橙武」`}</p>
        <p style={{ color: 'red' }}>注意，太玄经循环目前均为一段加速固定计算。</p>
      </p>,
    ],
  },
  {
    version: '[雾海寻龙] 1.0.4',
    date: '2024-05-21 10:45:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='1.0.4.1'>
        <p>{`- 修复「关闭背景」功能重新进入页面没有读取缓存的问题`}</p>
        <p>{`- 修复系统中部分错别字 「决」 => 「诀」`}</p>
        <p>{`- 增加「万灵阵(从朱)」选项，覆盖率100%`}</p>
      </p>,
      '孤锋诀',
      <p style={{ paddingLeft: 12 }} key='1.0.4.2'>
        <p>- 修复孤锋诀无法选择力道五彩石的BUG</p>
        <p>
          -
          更新了孤锋诀模拟器内DOT伤害的快照拆分，（后续保存的循环会生效，外面循环目前没改。理论差距不大）
        </p>
      </p>,
    ],
  },
  {
    version: '[雾海寻龙] 1.0.3',
    date: '2024-05-17 10:40:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='1.0.3.1'>
        <p>- 配装器快速一键附魔功能增加选择计算部位功能，大大提高计算效率</p>
        <p>- 修复切换循环没有自动切换至对应循环奇穴的BUG</p>
      </p>,
      '凌海诀',
      <p style={{ paddingLeft: 12 }} key='1.0.3.2'>
        <p>- 凌海诀计算卡顿的原因为循环细化的较为详细，计算过程更为复杂</p>
        <p>- 暂时去除了凌海诀的「优化算法」功能</p>
      </p>,
    ],
  },
  {
    version: '[雾海寻龙] 1.0.2',
    date: '2024-05-16 09:40:00',
    content: [
      '综合',
      <p style={{ paddingLeft: 12 }} key='1.0.2.1'>
        <p>- 主JS拆包，优化了首次加载的速度</p>
      </p>,
      '凌海诀',
      <p style={{ paddingLeft: 12 }} key='1.0.2.2'>
        <p>- 根据方小皮提供的橙武JCL生成了新的橙武循环</p>
        <p>- 修复了普通水特效和风特效没有生效的BUG</p>
        <p>- 增加了团队增益快捷设置</p>
      </p>,
    ],
  },
  {
    version: '[雾海寻龙] 1.0.1',
    date: '2024-05-11 16:10:00',
    content: ['支持凌海诀模块，数据提供：考拉。测试：方小皮（还在调试中）'],
  },
  {
    version: '[雾海寻龙] 1.0.0',
    date: '2024-05-11 16:10:00',
    content: [
      '合并万灵/刀宗计算器',
      '项目代码重构',
      '系统右上角可以切换心法',
      '新心法可以通过录入心法模块实现，提高了接入效率',
      '理论计算效率提升50%，1164次附魔计算本地耗时【500ms】 => 【240ms】',
      '后续提供接入文档',
    ],
  },
]
