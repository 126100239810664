import { 循环详情, 循环技能详情 } from '@/@types/循环'

export const 循环合并函数 = (循环集合: Array<{ 覆盖率: number; 详情: 循环详情[] }>): 循环详情[] => {
  const 循环总数 = 循环集合?.length
  const 最终循环详情: 循环详情[] = []
  循环集合?.[0]?.详情?.forEach((循环信息) => {
    let 技能详情: 循环技能详情[] = []
    技能详情 = 技能详情.concat(
      获取技能详情覆盖率计算(循环信息?.技能详情 || [], 循环集合?.[0]?.覆盖率)
    )
    let 战斗时间 = 循环信息?.战斗时间 * 循环集合?.[0]?.覆盖率
    for (let i = 1; i <= 循环总数; i++) {
      const 信息 = 循环集合?.[i]
      if (信息) {
        const 符合加速延迟的信息 = 信息?.详情?.find(
          (a) =>
            a?.循环加速等级 === 循环信息?.循环加速等级 && a?.循环延迟要求 === 循环信息?.循环延迟要求
        )
        if (符合加速延迟的信息) {
          战斗时间 = 战斗时间 + 符合加速延迟的信息?.战斗时间 * 信息?.覆盖率
          技能详情 = 技能详情.concat(
            获取技能详情覆盖率计算(符合加速延迟的信息?.技能详情 || [], 信息?.覆盖率)
          )
        }
      }
    }
    const 合并后循环: 循环详情 = {
      循环加速等级: 循环信息?.循环加速等级,
      循环延迟要求: 循环信息?.循环延迟要求,
      战斗时间: 战斗时间,
      技能详情: 技能详情,
    }
    最终循环详情.push(合并后循环)
  })
  return 最终循环详情
}

const 获取技能详情覆盖率计算 = (技能详情: 循环技能详情[], 覆盖率) => {
  const 最终循环详情: 循环技能详情[] = []
  技能详情?.forEach((循环技能) => {
    const 计算覆盖率后技能: 循环技能详情 = {
      ...循环技能,
      技能数量: 循环技能?.技能数量 * 覆盖率,
      技能增益列表: 循环技能?.技能增益列表?.map((增益信息) => {
        return {
          ...增益信息,
          增益技能数: (增益信息?.增益技能数 || 0) * 覆盖率,
        }
      }),
    }
    最终循环详情.push(计算覆盖率后技能)
  })
  return 最终循环详情
}
