import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 衣服装备数据: 装备属性信息模型[] = [
  {
    id: 103875,
    uid: 103875,
    装备名称: '陶然意·月容衣',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.元气, 值: 1094 },
      { 属性: 属性类型.内功基础攻击, 值: 1983 },
      { 属性: 属性类型.内功破防等级, 值: 9845 },
      { 属性: 属性类型.全能等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103872,
    uid: 103872,
    装备名称: '陶然意·修茂衣',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.根骨, 值: 1094 },
      { 属性: 属性类型.内功基础攻击, 值: 1983 },
      { 属性: 属性类型.内功破防等级, 值: 9845 },
      { 属性: 属性类型.全能等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103871,
    uid: 103871,
    装备名称: '陶然意·理乾衣',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.元气, 值: 1094 },
      { 属性: 属性类型.内功基础攻击, 值: 1983 },
      { 属性: 属性类型.内功破防等级, 值: 9845 },
      { 属性: 属性类型.全能等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103867,
    uid: 103867,
    装备名称: '陶然意·来仪衣',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.根骨, 值: 1094 },
      { 属性: 属性类型.内功基础攻击, 值: 1983 },
      { 属性: 属性类型.内功破防等级, 值: 9845 },
      { 属性: 属性类型.全能等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103865,
    uid: 103865,
    装备名称: '陶然意·凡圣衣',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.元气, 值: 1094 },
      { 属性: 属性类型.内功基础攻击, 值: 1983 },
      { 属性: 属性类型.内功破防等级, 值: 9845 },
      { 属性: 属性类型.全能等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103861,
    uid: 103861,
    装备名称: '陶然意·连筠衣',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.元气, 值: 1094 },
      { 属性: 属性类型.内功基础攻击, 值: 1983 },
      { 属性: 属性类型.内功破防等级, 值: 9845 },
      { 属性: 属性类型.全能等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103860,
    uid: 103860,
    装备名称: '陶然意·蛇言衣',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.根骨, 值: 1094 },
      { 属性: 属性类型.内功基础攻击, 值: 1983 },
      { 属性: 属性类型.内功破防等级, 值: 9845 },
      { 属性: 属性类型.全能等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103859,
    uid: 103859,
    装备名称: '陶然意·寒河衣',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.根骨, 值: 1094 },
      { 属性: 属性类型.内功基础攻击, 值: 1983 },
      { 属性: 属性类型.内功破防等级, 值: 9845 },
      { 属性: 属性类型.全能等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103857,
    uid: 103857,
    装备名称: '陶然意·清静衣',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.根骨, 值: 1094 },
      { 属性: 属性类型.内功基础攻击, 值: 1983 },
      { 属性: 属性类型.内功破防等级, 值: 9845 },
      { 属性: 属性类型.全能等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103855,
    uid: 103855,
    装备名称: '陶然意·簇锦衣',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.元气, 值: 1094 },
      { 属性: 属性类型.内功基础攻击, 值: 1983 },
      { 属性: 属性类型.内功破防等级, 值: 9845 },
      { 属性: 属性类型.全能等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103854,
    uid: 103854,
    装备名称: '陶然意·祇树衣',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 26300,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.元气, 值: 1094 },
      { 属性: 属性类型.内功基础攻击, 值: 1983 },
      { 属性: 属性类型.内功破防等级, 值: 9845 },
      { 属性: 属性类型.全能等级, 值: 3938 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 101621,
    uid: 101621,
    装备名称: '花念衣',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 26300,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.元气, 值: 1178 },
      { 属性: 属性类型.内功基础攻击, 值: 2975 },
      { 属性: 属性类型.内功破防等级, 值: 9845 },
      { 属性: 属性类型.无双等级, 值: 7876 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 101620,
    uid: 101620,
    装备名称: '花深衣',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 26300,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14439 },
      { 属性: 属性类型.根骨, 值: 1178 },
      { 属性: 属性类型.内功基础攻击, 值: 2975 },
      { 属性: 属性类型.内功破防等级, 值: 9845 },
      { 属性: 属性类型.无双等级, 值: 7876 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 101555,
    uid: 101555,
    装备名称: '青桑衣',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.元气, 值: 1160 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.破招值, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 101554,
    uid: 101554,
    装备名称: '季春衣',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.根骨, 值: 1160 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.破招值, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 101519,
    uid: 101519,
    装备名称: '游山衫',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.元气, 值: 1160 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.加速等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101518,
    uid: 101518,
    装备名称: '思玉衫',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.根骨, 值: 1160 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.加速等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101487,
    uid: 101487,
    装备名称: '孤漠·织春衣',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.元气, 值: 1160 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.内功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 101483,
    uid: 101483,
    装备名称: '孤漠·竹烟衣',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.根骨, 值: 1160 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.内功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 101482,
    uid: 101482,
    装备名称: '孤漠·星思衣',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.元气, 值: 1160 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.内功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 101477,
    uid: 101477,
    装备名称: '孤漠·玉寒衣',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.根骨, 值: 1160 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.加速等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101473,
    uid: 101473,
    装备名称: '孤漠·星野衣',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.元气, 值: 1160 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.内功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 101469,
    uid: 101469,
    装备名称: '孤漠·雾山衣',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.元气, 值: 1160 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.外功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101467,
    uid: 101467,
    装备名称: '孤漠·芳丛衣',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.根骨, 值: 1160 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.内功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 101465,
    uid: 101465,
    装备名称: '孤漠·染晴衣',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.根骨, 值: 1160 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.加速等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101463,
    uid: 101463,
    装备名称: '孤漠·云岫衣',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.根骨, 值: 1160 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.内功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 101459,
    uid: 101459,
    装备名称: '孤漠·梅曳衣',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.元气, 值: 1160 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.内功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 101457,
    uid: 101457,
    装备名称: '孤漠·闻闲衣',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.元气, 值: 1160 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.内功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 101268,
    uid: 101268,
    装备名称: '漠晖衣',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.元气, 值: 1160 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.全会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 101267,
    uid: 101267,
    装备名称: '漠安衣',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.根骨, 值: 1160 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.内功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 103713,
    uid: 103713,
    装备名称: '陶然意·月容衣',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12407 },
      { 属性: 属性类型.元气, 值: 940 },
      { 属性: 属性类型.内功基础攻击, 值: 1704 },
      { 属性: 属性类型.内功破防等级, 值: 8460 },
      { 属性: 属性类型.全能等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103710,
    uid: 103710,
    装备名称: '陶然意·修茂衣',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12407 },
      { 属性: 属性类型.根骨, 值: 940 },
      { 属性: 属性类型.内功基础攻击, 值: 1704 },
      { 属性: 属性类型.内功破防等级, 值: 8460 },
      { 属性: 属性类型.全能等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103709,
    uid: 103709,
    装备名称: '陶然意·理乾衣',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12407 },
      { 属性: 属性类型.元气, 值: 940 },
      { 属性: 属性类型.内功基础攻击, 值: 1704 },
      { 属性: 属性类型.内功破防等级, 值: 8460 },
      { 属性: 属性类型.全能等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103705,
    uid: 103705,
    装备名称: '陶然意·来仪衣',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12407 },
      { 属性: 属性类型.根骨, 值: 940 },
      { 属性: 属性类型.内功基础攻击, 值: 1704 },
      { 属性: 属性类型.内功破防等级, 值: 8460 },
      { 属性: 属性类型.全能等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103703,
    uid: 103703,
    装备名称: '陶然意·凡圣衣',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12407 },
      { 属性: 属性类型.元气, 值: 940 },
      { 属性: 属性类型.内功基础攻击, 值: 1704 },
      { 属性: 属性类型.内功破防等级, 值: 8460 },
      { 属性: 属性类型.全能等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103699,
    uid: 103699,
    装备名称: '陶然意·连筠衣',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12407 },
      { 属性: 属性类型.元气, 值: 940 },
      { 属性: 属性类型.内功基础攻击, 值: 1704 },
      { 属性: 属性类型.内功破防等级, 值: 8460 },
      { 属性: 属性类型.全能等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103698,
    uid: 103698,
    装备名称: '陶然意·蛇言衣',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12407 },
      { 属性: 属性类型.根骨, 值: 940 },
      { 属性: 属性类型.内功基础攻击, 值: 1704 },
      { 属性: 属性类型.内功破防等级, 值: 8460 },
      { 属性: 属性类型.全能等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103697,
    uid: 103697,
    装备名称: '陶然意·寒河衣',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12407 },
      { 属性: 属性类型.根骨, 值: 940 },
      { 属性: 属性类型.内功基础攻击, 值: 1704 },
      { 属性: 属性类型.内功破防等级, 值: 8460 },
      { 属性: 属性类型.全能等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103695,
    uid: 103695,
    装备名称: '陶然意·清静衣',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12407 },
      { 属性: 属性类型.根骨, 值: 940 },
      { 属性: 属性类型.内功基础攻击, 值: 1704 },
      { 属性: 属性类型.内功破防等级, 值: 8460 },
      { 属性: 属性类型.全能等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103693,
    uid: 103693,
    装备名称: '陶然意·簇锦衣',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12407 },
      { 属性: 属性类型.元气, 值: 940 },
      { 属性: 属性类型.内功基础攻击, 值: 1704 },
      { 属性: 属性类型.内功破防等级, 值: 8460 },
      { 属性: 属性类型.全能等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103692,
    uid: 103692,
    装备名称: '陶然意·祇树衣',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 22600,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12407 },
      { 属性: 属性类型.元气, 值: 940 },
      { 属性: 属性类型.内功基础攻击, 值: 1704 },
      { 属性: 属性类型.内功破防等级, 值: 8460 },
      { 属性: 属性类型.全能等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 103517,
    uid: 103517,
    装备名称: '孤山寒月·忧生衣',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.元气, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.破招值, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 103516,
    uid: 103516,
    装备名称: '孤山寒月·风吟衣',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.根骨, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.破招值, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 101226,
    uid: 101226,
    装备名称: '映宵衣',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.元气, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.破招值, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 101225,
    uid: 101225,
    装备名称: '云眷衣',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.根骨, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.破招值, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 101190,
    uid: 101190,
    装备名称: '江汜衫',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.元气, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.加速等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101189,
    uid: 101189,
    装备名称: '合苏衫',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.根骨, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.加速等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101158,
    uid: 101158,
    装备名称: '西塞·沉醉衣',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.元气, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.内功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 101154,
    uid: 101154,
    装备名称: '西塞·银筱衣',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.根骨, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.内功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 101153,
    uid: 101153,
    装备名称: '西塞·清浊衣',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.元气, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.内功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 101148,
    uid: 101148,
    装备名称: '西塞·青弦衫',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.根骨, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.加速等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101144,
    uid: 101144,
    装备名称: '西塞·大荒衣',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.元气, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.内功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 101140,
    uid: 101140,
    装备名称: '西塞·寒蛩衣',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.元气, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.外功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101138,
    uid: 101138,
    装备名称: '西塞·朝愁衣',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.根骨, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.内功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 101136,
    uid: 101136,
    装备名称: '西塞·烟蘸衣',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.根骨, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.加速等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101134,
    uid: 101134,
    装备名称: '西塞·雪欹衣',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.根骨, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.内功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 101130,
    uid: 101130,
    装备名称: '西塞·合章衣',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.元气, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.内功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 101128,
    uid: 101128,
    装备名称: '西塞·彼岸衣',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.元气, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.内功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 100999,
    uid: 100999,
    装备名称: '寻踪觅宝·中林衣',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.元气, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.加速等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 100998,
    uid: 100998,
    装备名称: '寻踪觅宝·谷风衣',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.根骨, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.加速等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 104588,
    uid: 104588,
    装备名称: '云风御厨上衣·火候',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 22200,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12188 },
      { 属性: 属性类型.元气, 值: 994 },
      { 属性: 属性类型.内功基础攻击, 值: 2511 },
      { 属性: 属性类型.内功破防等级, 值: 8310 },
      { 属性: 属性类型.无双等级, 值: 6648 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 104584,
    uid: 104584,
    装备名称: '迎新御厨上衣·火候',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 22200,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12188 },
      { 属性: 属性类型.根骨, 值: 994 },
      { 属性: 属性类型.内功基础攻击, 值: 2511 },
      { 属性: 属性类型.内功破防等级, 值: 8310 },
      { 属性: 属性类型.无双等级, 值: 6648 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 104583,
    uid: 104583,
    装备名称: '寻龙御厨上衣·火候',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 22200,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12188 },
      { 属性: 属性类型.元气, 值: 994 },
      { 属性: 属性类型.内功基础攻击, 值: 2511 },
      { 属性: 属性类型.内功破防等级, 值: 8310 },
      { 属性: 属性类型.无双等级, 值: 6648 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 104578,
    uid: 104578,
    装备名称: '古意御厨上衣·火候',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 22200,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12188 },
      { 属性: 属性类型.根骨, 值: 994 },
      { 属性: 属性类型.内功基础攻击, 值: 2511 },
      { 属性: 属性类型.内功破防等级, 值: 8310 },
      { 属性: 属性类型.无双等级, 值: 6648 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 104574,
    uid: 104574,
    装备名称: '妙火御厨上衣·火候',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 22200,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12188 },
      { 属性: 属性类型.元气, 值: 994 },
      { 属性: 属性类型.内功基础攻击, 值: 2511 },
      { 属性: 属性类型.内功破防等级, 值: 8310 },
      { 属性: 属性类型.无双等级, 值: 6648 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 104570,
    uid: 104570,
    装备名称: '蜀月御厨上衣·火候',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22200,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12188 },
      { 属性: 属性类型.元气, 值: 994 },
      { 属性: 属性类型.内功基础攻击, 值: 2511 },
      { 属性: 属性类型.内功破防等级, 值: 8310 },
      { 属性: 属性类型.无双等级, 值: 6648 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 104568,
    uid: 104568,
    装备名称: '瑶星御厨上衣·火候',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 22200,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12188 },
      { 属性: 属性类型.根骨, 值: 994 },
      { 属性: 属性类型.内功基础攻击, 值: 2511 },
      { 属性: 属性类型.内功破防等级, 值: 8310 },
      { 属性: 属性类型.无双等级, 值: 6648 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 104566,
    uid: 104566,
    装备名称: '霓裳御厨上衣·火候',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 22200,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12188 },
      { 属性: 属性类型.根骨, 值: 994 },
      { 属性: 属性类型.内功基础攻击, 值: 2511 },
      { 属性: 属性类型.内功破防等级, 值: 8310 },
      { 属性: 属性类型.无双等级, 值: 6648 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 104564,
    uid: 104564,
    装备名称: '灵虚御厨上衣·火候',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22200,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12188 },
      { 属性: 属性类型.根骨, 值: 994 },
      { 属性: 属性类型.内功基础攻击, 值: 2511 },
      { 属性: 属性类型.内功破防等级, 值: 8310 },
      { 属性: 属性类型.无双等级, 值: 6648 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 104560,
    uid: 104560,
    装备名称: '翡翠御厨上衣·火候',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 22200,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12188 },
      { 属性: 属性类型.元气, 值: 994 },
      { 属性: 属性类型.内功基础攻击, 值: 2511 },
      { 属性: 属性类型.内功破防等级, 值: 8310 },
      { 属性: 属性类型.无双等级, 值: 6648 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 104558,
    uid: 104558,
    装备名称: '菩提御厨上衣·火候',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 22200,
    装备特效: 装备特效枚举.冬至套装,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12188 },
      { 属性: 属性类型.元气, 值: 994 },
      { 属性: 属性类型.内功基础攻击, 值: 2511 },
      { 属性: 属性类型.内功破防等级, 值: 8310 },
      { 属性: 属性类型.无双等级, 值: 6648 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 100969,
    uid: 100969,
    装备名称: '寻踪觅宝·月诸衣',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11529 },
      { 属性: 属性类型.元气, 值: 941 },
      { 属性: 属性类型.内功基础攻击, 值: 2375 },
      { 属性: 属性类型.加速等级, 值: 7861 },
      { 属性: 属性类型.无双等级, 值: 6289 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 100968,
    uid: 100968,
    装备名称: '寻踪觅宝·陟南衣',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11529 },
      { 属性: 属性类型.根骨, 值: 941 },
      { 属性: 属性类型.内功基础攻击, 值: 2375 },
      { 属性: 属性类型.加速等级, 值: 7861 },
      { 属性: 属性类型.无双等级, 值: 6289 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 100939,
    uid: 100939,
    装备名称: '壁镜衫',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11529 },
      { 属性: 属性类型.元气, 值: 941 },
      { 属性: 属性类型.内功基础攻击, 值: 2375 },
      { 属性: 属性类型.破招值, 值: 7861 },
      { 属性: 属性类型.无双等级, 值: 6289 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 100938,
    uid: 100938,
    装备名称: '壁观衫',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11529 },
      { 属性: 属性类型.根骨, 值: 941 },
      { 属性: 属性类型.内功基础攻击, 值: 2375 },
      { 属性: 属性类型.破招值, 值: 7861 },
      { 属性: 属性类型.无双等级, 值: 6289 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 104497,
    uid: 104497,
    装备名称: '玉凉衣',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.元气, 值: 918 },
      { 属性: 属性类型.内功基础攻击, 值: 2319 },
      { 属性: 属性类型.破招值, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 104496,
    uid: 104496,
    装备名称: '樛枝衣',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.根骨, 值: 918 },
      { 属性: 属性类型.内功基础攻击, 值: 2319 },
      { 属性: 属性类型.破招值, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 104470,
    uid: 104470,
    装备名称: '斛尘衣',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.元气, 值: 918 },
      { 属性: 属性类型.内功基础攻击, 值: 2319 },
      { 属性: 属性类型.内功破防等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 104469,
    uid: 104469,
    装备名称: '熙春衣',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.根骨, 值: 918 },
      { 属性: 属性类型.内功基础攻击, 值: 2319 },
      { 属性: 属性类型.内功破防等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 104142,
    uid: 104142,
    装备名称: '昭文衣',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.元气, 值: 918 },
      { 属性: 属性类型.内功基础攻击, 值: 2319 },
      { 属性: 属性类型.加速等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 104141,
    uid: 104141,
    装备名称: '丘墟衣',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.根骨, 值: 918 },
      { 属性: 属性类型.内功基础攻击, 值: 2319 },
      { 属性: 属性类型.加速等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 100909,
    uid: 100909,
    装备名称: '昭文衣',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.元气, 值: 918 },
      { 属性: 属性类型.内功基础攻击, 值: 2319 },
      { 属性: 属性类型.加速等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 100908,
    uid: 100908,
    装备名称: '丘墟衣',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.根骨, 值: 918 },
      { 属性: 属性类型.内功基础攻击, 值: 2319 },
      { 属性: 属性类型.加速等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 100873,
    uid: 100873,
    装备名称: '玉凉衣',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.元气, 值: 918 },
      { 属性: 属性类型.内功基础攻击, 值: 2319 },
      { 属性: 属性类型.破招值, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 100872,
    uid: 100872,
    装备名称: '樛枝衣',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.根骨, 值: 918 },
      { 属性: 属性类型.内功基础攻击, 值: 2319 },
      { 属性: 属性类型.破招值, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 100837,
    uid: 100837,
    装备名称: '斛尘衣',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.元气, 值: 918 },
      { 属性: 属性类型.内功基础攻击, 值: 2319 },
      { 属性: 属性类型.内功破防等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 100836,
    uid: 100836,
    装备名称: '熙春衣',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.根骨, 值: 918 },
      { 属性: 属性类型.内功基础攻击, 值: 2319 },
      { 属性: 属性类型.内功破防等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 100705,
    uid: 100705,
    装备名称: '弥弦衫',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.元气, 值: 853 },
      { 属性: 属性类型.内功基础攻击, 值: 1546 },
      { 属性: 属性类型.加速等级, 值: 7674 },
      { 属性: 属性类型.全能等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 100704,
    uid: 100704,
    装备名称: '珀音衫',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.根骨, 值: 853 },
      { 属性: 属性类型.内功基础攻击, 值: 1546 },
      { 属性: 属性类型.加速等级, 值: 7674 },
      { 属性: 属性类型.全能等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 100669,
    uid: 100669,
    装备名称: '霖瑜衫',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.元气, 值: 853 },
      { 属性: 属性类型.内功基础攻击, 值: 1546 },
      { 属性: 属性类型.全会心等级, 值: 7674 },
      { 属性: 属性类型.全能等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.体质 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 100668,
    uid: 100668,
    装备名称: '瑾辞衫',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.根骨, 值: 853 },
      { 属性: 属性类型.内功基础攻击, 值: 1546 },
      { 属性: 属性类型.内功会心等级, 值: 7674 },
      { 属性: 属性类型.全能等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.体质 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 100633,
    uid: 100633,
    装备名称: '惜晗衣',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 18800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10321 },
      { 属性: 属性类型.元气, 值: 842 },
      { 属性: 属性类型.内功基础攻击, 值: 2126 },
      { 属性: 属性类型.全会心等级, 值: 7037 },
      { 属性: 属性类型.无双等级, 值: 5630 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 100632,
    uid: 100632,
    装备名称: '濯缨衣',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 18800,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10321 },
      { 属性: 属性类型.根骨, 值: 842 },
      { 属性: 属性类型.内功基础攻击, 值: 2126 },
      { 属性: 属性类型.内功会心等级, 值: 7037 },
      { 属性: 属性类型.无双等级, 值: 5630 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 100597,
    uid: 100597,
    装备名称: '绢素衫',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 17600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9662 },
      { 属性: 属性类型.元气, 值: 788 },
      { 属性: 属性类型.内功基础攻击, 值: 1991 },
      { 属性: 属性类型.内功破防等级, 值: 6588 },
      { 属性: 属性类型.无双等级, 值: 5270 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 100596,
    uid: 100596,
    装备名称: '烨霖衫',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 17600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9662 },
      { 属性: 属性类型.根骨, 值: 788 },
      { 属性: 属性类型.内功基础攻击, 值: 1991 },
      { 属性: 属性类型.内功破防等级, 值: 6588 },
      { 属性: 属性类型.无双等级, 值: 5270 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 101861,
    uid: 101861,
    装备名称: '轻桡衫',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 16500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9059 },
      { 属性: 属性类型.元气, 值: 739 },
      { 属性: 属性类型.内功基础攻击, 值: 1866 },
      { 属性: 属性类型.破招值, 值: 6176 },
      { 属性: 属性类型.无双等级, 值: 4941 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 101860,
    uid: 101860,
    装备名称: '临流衫',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 16500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9059 },
      { 属性: 属性类型.根骨, 值: 739 },
      { 属性: 属性类型.内功基础攻击, 值: 1866 },
      { 属性: 属性类型.破招值, 值: 6176 },
      { 属性: 属性类型.无双等级, 值: 4941 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 100561,
    uid: 100561,
    装备名称: '凝玥衣',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 16500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9059 },
      { 属性: 属性类型.元气, 值: 686 },
      { 属性: 属性类型.内功基础攻击, 值: 1244 },
      { 属性: 属性类型.内功破防等级, 值: 6176 },
      { 属性: 属性类型.全能等级, 值: 2471 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
  {
    id: 100560,
    uid: 100560,
    装备名称: '静漪衣',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 16500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9059 },
      { 属性: 属性类型.根骨, 值: 686 },
      { 属性: 属性类型.内功基础攻击, 值: 1244 },
      { 属性: 属性类型.内功破防等级, 值: 6176 },
      { 属性: 属性类型.全能等级, 值: 2471 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.额外气血上限 }],
  },
]

export default 衣服装备数据
