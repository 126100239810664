import { 按数字生成数组 } from '@/工具函数/help'
import { 属性类型 } from '@/@types/属性'
import { 技能增益列表类型 } from '@/@types/技能'

const 风虎函数 = () => {
  const 数组 = 按数字生成数组(5)
  const 数值数组 = [51, 102, 154, 205, 256]
  return 数组.map((item) => {
    return {
      增益名称: `风虎·${item}`,
      增益所在位置: '奇穴',
      依赖奇穴: '风虎',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.通用增伤, 值: 数值数组[item - 1] / 1024 }],
    } as 技能增益列表类型
  })
}

const 风虎增益: 技能增益列表类型[] = [...风虎函数()]

export default 风虎增益
