export default {
  装备基础属性: {
    基础攻击: 47870,
    破防等级: 45028,
    无双等级: 71888,
    会心等级: 29043,
    会心效果等级: 6684,
    全能等级: 0,
    武器伤害_最小值: 6828,
    武器伤害_最大值: 11380,
    破招值: 1668,
    力道: 7969,
    元气: 44,
    身法: 44,
    根骨: 44,
    体质: 134555,
    基础气血上限: 160878,
    额外气血上限: 0,
    加速等级: 42417,
    装分: 507336,
  },
  装备镶嵌附魔属性: {
    基础攻击: 10682,
    破防等级: 5425,
    无双等级: 6260,
    会心等级: 3336,
    会心效果等级: 834,
    全能等级: 0,
    武器伤害_最小值: 0,
    武器伤害_最大值: 0,
    破招值: 1668,
    力道: 362,
    元气: 44,
    身法: 44,
    根骨: 44,
    体质: 45,
    基础气血上限: 160878,
    额外气血上限: 0,
    加速等级: 0,
  },
  装备列表: [
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atStrengthBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atPhysicsOvercomeBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 101430,
      装备部位: '帽子',
      附魔: '攻击+568',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atPhysicsCriticalStrike',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atPhysicsCriticalDamagePowerBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 101461,
      装备部位: '衣服',
      附魔: '无双+624',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atPhysicsCriticalStrike',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atPhysicsAttackPowerBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 101368,
      装备部位: '腰带',
      附魔: '无双+624',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atPhysicsCriticalStrike',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atSurplusValueBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 101581,
      装备部位: '护腕',
      附魔: '无双+2089',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atPhysicsCriticalStrike',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atSurplusValueBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 101588,
      装备部位: '下装',
      附魔: '无双+2089',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atPhysicsAttackPowerBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atPhysicsOvercomeBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 101399,
      装备部位: '鞋子',
      附魔: '攻击+568',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atStrainBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 41315,
      装备部位: '项链',
      附魔: '体质+361',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atPhysicsOvercomeBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 41363,
      装备部位: '腰坠',
      附魔: '体质+361',
    },
    {
      镶嵌孔数组: [],
      当前精炼等级: 6,
      id: 41373,
      装备部位: '戒指',
      附魔: '攻击+568',
    },
    {
      镶嵌孔数组: [],
      当前精炼等级: 6,
      id: 42729,
      装备部位: '戒指',
      附魔: '攻击+568',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atStrengthBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 39248,
      装备部位: '暗器',
      附魔: '破防+2089',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atPhysicsAttackPowerBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atStrengthBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atPhysicsOvercomeBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 39330,
      装备部位: '武器',
      附魔: '攻击+568',
    },
  ],
  五彩石: '彩·无双·锐刃·痛击(陆)',
  装备增益: {
    大附魔_伤帽: 2,
    大附魔_伤衣: 2,
    大附魔_伤腰: 2,
    大附魔_伤腕: 2,
    大附魔_伤鞋: 2,
    套装会心会效: 1,
    套装技能: 1,
    风特效腰坠: 2,
    水特效武器: 2,
  },
}
