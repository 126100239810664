import { 属性类型 } from '@/@types/属性'
import { 技能增益列表类型 } from '@/@types/技能'
import { 按数字生成数组 } from '@/工具函数/help'

import 获取快照增益 from '@/心法模块/统一数据/快照增益'

// 25937
const 祭血关山函数 = () => {
  const 数组 = 按数字生成数组(5)
  const list = [102, 204, 307, 410, 512]
  return 数组.map((item) => {
    return {
      增益名称: `祭血关山·${item}`,
      增益所在位置: '奇穴',
      依赖奇穴: '祭血关山',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.郭氏外功基础攻击, 值: list[item - 1] }],
    } as 技能增益列表类型
  })
}

// 8385
const 血怒函数 = () => {
  const 数组 = 按数字生成数组(5)
  return 数组.map((item) => {
    return {
      增益名称: `血怒·${item}`,
      增益所在位置: '技能',
      依赖奇穴: '血怒',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.郭氏外功基础攻击, 值: 133 * item }],
    } as 技能增益列表类型
  })
}

const 通用增益: 技能增益列表类型[] = [
  ...获取快照增益('外功'),
  ...祭血关山函数(),
  ...血怒函数(),
  {
    增益名称: '非侠',
    增益所在位置: '职业',
    增益启用: true,
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.非侠增伤, 值: 246 / 1024 }],
  },
  // 套装双会特效
  {
    增益名称: '军啸',
    增益所在位置: '装备',
    增益类型: '部分启用',
    快照类型: '套装会心会效',
    依赖装备增益: '套装会心会效',
    增益集合: [
      { 属性: 属性类型.外功会心百分比, 值: 0.04 },
      { 属性: 属性类型.郭氏外功会心效果等级, 值: 41 },
    ],
  },
  {
    // 13126
    增益名称: '恋战',
    增益所在位置: '奇穴',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 205 / 1024 }],
  },
  {
    // 8423
    增益名称: '从容',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [
      { 属性: 属性类型.郭氏外功基础攻击, 值: 102 },
      { 属性: 属性类型.郭氏无双, 值: 154 },
    ],
  },

  {
    // 27161
    增益名称: '血怒·惊涌',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [
      { 属性: 属性类型.郭氏外功基础攻击, 值: 102 },
      { 属性: 属性类型.郭氏无双, 值: 307 },
    ],
  },
  {
    // 17176
    增益名称: '分野',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 51 / 1024 }],
  },
  {
    // 14309
    增益名称: '锋鸣',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.郭氏外功破防等级, 值: 154 }],
  },
  // {
  //   // 8248
  //   增益名称: '虚弱',
  //   增益所在位置: '技能',
  //   增益类型: '部分启用',
  //   增益集合: [{ 属性: 属性类型.郭氏外功基础防御, 值: -51 }],
  // },
  {
    // 8627
    增益名称: '刀魂',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.郭氏外功基础攻击, 值: 154 }],
  },
]

export default 通用增益
